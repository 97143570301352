import { Component, createRef } from 'react';
import { Root } from '../../components/ui';
import { TextSearchField } from '../../components/ui/form';
import { ButtonContainer, SecondaryButton } from '../../ui';
import { TableToolBar } from '../../components/ui/table';
import UITable from '../../components/UITable';
import _, { isEqual, isNil, map } from 'lodash';
import { TableSpec } from './tableSpec';
import { compose, withState } from 'react-recompose';
import { messages } from '../../i18n';
import {
  IVizImport,
  IVizExport,
  IVizReportEditing,
  IDehydratedViz,
} from '../../discovery/interfaces';
import { IDataset } from '../../datasets/interfaces';
import { SearchOptionGroup } from './library.styles';
import Util from '../../common/Util';
import { HelpBlock } from '../../views/VizSaveDialog/ui';
import { FilterWidget } from '../../discovery/filter-widget/filter-widget.component';

import { FilterWidgetContextProvider } from '../../discovery/filter-widget/filter-widget.context';
import { ToastHOC } from '../../components/toast/toast-launcher';
import { EditDiscoveryDialog } from '../edit-discovery-dialog';
import { withErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryHOCOptions } from '../../components/error-boundary';
import { TableSkeleton } from '../../common/loaders';
import { MainSectionHeader } from '../../components/main-section-header';
import {
  ImportReportsDuplicateDialogTyped,
  PromptDialogTyped,
} from './library.interfaces';

interface IProps {
  visualizations: IDehydratedViz;
  reportDownloadObjectUrl: any;
  saveError?: string;
}

interface IState {
  discovery: any;
  showEdit: any;
  showDelete: any;
  showImportDuplicates: any;
  editKey: any;
  tenantName: any;
  newReports: any;
  reportsRequiringInput: any;
  reportsNotRequiringInput: any;
}

class LibraryComponentUnconnected extends Component<IProps, IState> {
  state;
  props;
  hiddenFileInput;

  static defaultProps = {
    content: [],
  };

  constructor(props) {
    super(props);

    this.hiddenFileInput = createRef();

    this.state = {
      discovery: null,
      showEdit: false,
      showDelete: false,
      showImportDuplicates: false,
      editKey: 'name',
      tenantName: props?.currentUser?.tenant || 'tenant',
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (
      prevProps.saveError !== this.props.saveError &&
      !_.isEmpty(this.props.saveError)
    ) {
      this.props.showToast({
        label: messages.calcDialog.nameAlreadyExists,
        onClose: () => {
          this.props.clearSaveError();
        },
        type: 'error',
        children: <span>{this.props.saveError}</span>,
      });
    }
  }

  onSearch(search) {
    this.props.setSearch(search);
  }

  onSetTags(tags: string[]) {
    this.props.setSearchTags(tags);
  }

  onSetSearchChartType(chartTypes: string[]) {
    this.props.setSearchChartTypes(chartTypes);
  }

  onSetDatasets(datasets: string[]) {
    this.props.setSearchDatasets(datasets);
  }

  doAction(eventKey, data) {
    switch (eventKey) {
      case 'name':
      case 'description':
        this.showEditPrompt(data, eventKey);
        break;
      case 'delete':
        this.showDeletePrompt(data);
        break;
      case 'public':
        this.updatePrivacy(data, false);
        break;
      case 'private':
        this.updatePrivacy(data, true);
        break;
    }
  }

  openMonitor(monitor) {
    this.props.openDiscovery(monitor);
  }

  updatePrivacy(data, isPrivate) {
    this.props.updateVisualization(
      { ...data, private: isPrivate },
      'isPrivate',
    );
  }

  showDeletePrompt(discovery) {
    this.setState({ discovery, showDelete: true });
  }

  getDeleteConfirmation() {
    const name = this.state.discovery ? this.state.discovery.name : '';
    let type = this.state.discovery
      ? this.state.discovery.discoveryType.toLowerCase()
      : 'monitor';
    type = type === 'visualization' ? 'report' : type;
    return (
      <div className='promptMessage'>
        <HelpBlock>
          {messages.formatString(messages.library.deleteConfirmMessage, {
            name: <strong>{name}</strong>,
            type,
          })}
        </HelpBlock>
      </div>
    );
  }

  doDeleteYes() {
    if (this.state.discovery.id) {
      if (this.state.discovery.discoveryType === 'MONITOR') {
        this.props.deleteMonitor(this.state.discovery.id);
      } else if (this.state.discovery.discoveryType === 'VISUALIZATION') {
        this.props.deleteVisualization(this.state.discovery.id);
      }
    }
    this.setState({ discovery: null, showDelete: false });
  }

  doDeleteNo() {
    this.setState({ discovery: null, showDelete: false });
  }

  showEditPrompt(discovery, eventKey) {
    this.setState({
      discovery,
      showEdit: true,
      editKey: eventKey,
    });
  }

  doEditYes(value) {
    const { discovery } = this.state;
    const { editKey } = this.state;
    if (discovery.id) {
      if (discovery.discoveryType === 'VISUALIZATION') {
        switch (editKey) {
          case 'name':
            this.props.updateVisualization(
              { ...discovery, name: value },
              editKey,
            );
            break;
          case 'description': {
            const options = [
              ...discovery.options,
              {
                key: 'description',
                value,
              },
            ];
            this.props.updateVisualization({ ...discovery, options }, editKey);
            break;
          }
        }
      } else if (discovery.discoveryType === 'MONITOR') {
        this.props.updateMonitor({ ...discovery, name: value });
      }
    }
    this.setState({ discovery: null, showEdit: false });
  }

  doEditNo() {
    this.setState({ discovery: null, showEdit: false });
  }

  onRowClick({ rowData }) {
    if (this.props.actionsOpen) {
      return;
    }
    this.props.openVisualization(rowData);
  }

  genReportDownloadBlob(filteredContent?) {
    const visualizations = this.getSanitizedContent(filteredContent);

    const jsonData = JSON.stringify(visualizations, undefined, 2);
    const blob = new Blob([jsonData], { type: 'text/json' });

    return window.URL.createObjectURL(blob);
  }

  // thank you stack overflow:
  // https://stackoverflow.com/questions/58830976/how-to-download-the-file-from-server-location-in-react-js
  onReportDownload(filteredContent) {
    const downloadUrl = this.genReportDownloadBlob(filteredContent);
    const a = window.document.createElement('a');
    window.document.body.appendChild(a);
    a.style.display = 'none';
    return function(fileName) {
      a.href = downloadUrl;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
    };
  }

  /**
   * Create a File Input and click it to initiate the file upload process
   */
  callImportReport() {
    this.hiddenFileInput.current.click();
  }

  /**
   * HTML Input File element change handler -- triggers when a file has been uploaded
   *
   * @param inputEl The Input HTML Element
   */
  onFileUploaded(inputEl) {
    const file = inputEl.files[0];
    const reader: any = new FileReader();
    let importNewReports: IVizExport[];

    reader.onload = () => {
      // when the reader loads, parse the results as JSON
      importNewReports = JSON.parse(reader.result);
      this.setState({
        newReports: importNewReports,
      });

      this.compareReports(
        this.getSanitizedContent(),
        importNewReports,
        this.props.datasets,
      );
    };
    reader.onerror = () => {
      console.error(reader.error);
    };
    // load the file into the reader
    reader.readAsText(file);
  }

  /**
   * Get the content which tracks state in a schema
   * that matches props.visualizations that does not
   * track anything
   */
  getSanitizedContent(filteredContent?) {
    const { search, content: allContent } = this.props;
    const contentToClean = filteredContent
      ? _.filter(
          filteredContent,
          v =>
            !search ||
            [v.name, v.description, v.creatorName].some(
              field =>
                !isNil(field) &&
                field.toLowerCase().includes(search?.toLowerCase()),
            ),
        )
      : allContent;

    return _(Util.removeTypeNameRecursively(contentToClean))
      .map(v => ({
        ..._.omit(v, [
          'dataset',
          'discoveryType',
          'isPrivate',
          '__typename',
          'creatorName',
          'creator',
          'createdOn',
          'updatedByName',
          'updatedOn',
          'canDelete',
          'canUpdate',
          'description',
        ]),
        datasetName: v.dataset.name,
        datasetId: v.dataset.id,
        private: v.isPrivate,
        layout: map(v.layout, x => _.omit(x, '__typename')),
        options: map(v.options, x => ({
          ..._.omit(x, '__typename'),
          ...(isEqual(x.key, 'slicerSelections') && { value: '[]' }),
        })),
      }))
      .value();
  }

  /**
   * Do a compare of two reports arrays to find duplicate names
   *
   * @param existingReports
   * @param newReports
   * @param datasets
   */
  compareReports(
    existingReports: any,
    newReports: IVizExport[],
    datasets: IDataset[],
  ) {
    const reportsRequiringInput = [];
    const reportsNotRequiringInput = [];

    _.forEach(newReports, (newRpt: IVizReportEditing) => {
      const existingReport = _.find(existingReports, {
        name: newRpt.name,
      });

      const needsNameReplacement = !_.isNil(existingReport);

      const updatingDataset = _.find(datasets, { name: newRpt.datasetName });

      if (needsNameReplacement) {
        const num = this.findNewReportNum(2, newRpt.name, existingReports);
        // set newName for future use
        newRpt.newName = `${newRpt.name} ${num}`;
        newRpt.needsName = true;
      }

      if (!_.isNil(updatingDataset)) {
        newRpt.datasetId = updatingDataset.id;
      }

      if (needsNameReplacement || _.isNil(updatingDataset)) {
        newRpt.needsName = needsNameReplacement;
        if (_.size(datasets) === 1) {
          // forces dataset and indicates to user that dataset has been set
          newRpt.datasetName = _.head(datasets)?.name;
          newRpt.datasetId = _.head(datasets)?.id;
        }
        newRpt.needsDataset = _.isNil(updatingDataset);
        reportsRequiringInput.push({
          newRpt,
          existingRpt: existingReport,
        });
      } else {
        reportsNotRequiringInput.push(newRpt);
      }
    });

    if (reportsRequiringInput.length) {
      this.setState({
        reportsRequiringInput,
        reportsNotRequiringInput,
        showImportDuplicates: true,
      });
    } else {
      const noDeletedReports: IVizReportEditing[] = [];
      this.doImportReportsSave(newReports, noDeletedReports);
    }
  }

  /**
   * Looks for a report name with a specific number after it,
   * if not found returns the num, otherwise advances num and searches again
   *
   * @param num The number to look for
   * @param rptName The report name
   * @param existingReports The existing reports array
   * @return {Number} The number to add to the report to make it unique
   */
  findNewReportNum(num, rptName, existingReports): number {
    const idx = _.findIndex(existingReports, { name: `${rptName} ${num}` });
    if (idx === -1) {
      // report not found with this name, return num
      return num;
    } else {
      // report name found, advance num and look again
      return this.findNewReportNum(++num, rptName, existingReports);
    }
  }

  /**
   * Handles closing the Import Reports modal
   */
  doImportReportsClose() {
    this.setState({
      reportsRequiringInput: undefined,
      newReports: undefined,
      showImportDuplicates: false,
    });
  }

  /**
   * Handles the save for importing reports
   */
  doImportReportsSave(
    reportsToImport: IVizReportEditing[],
    reportsToDelete: IVizReportEditing[],
  ) {
    const clean = n => {
      return _.pick(n, [
        'id',
        'name',
        'datasetId',
        'private',
        'chartType',
        'options',
        'layout',
      ]);
    };

    const importingReports: IVizImport[] = _.map(reportsToImport, rpt => {
      return clean(rpt);
    });

    _.forEach(importingReports, rpt => {
      this.props.newVisualization(rpt);
    });

    const deletingReports: IVizImport[] = _.map(reportsToDelete, rpt => {
      return clean(rpt);
    });

    _.forEach(deletingReports, rpt => {
      this.props.deleteVisualization(rpt.id);
    });

    this.doImportReportsClose();
  }

  render() {
    const {
      showDelete,
      showEdit,
      showImportDuplicates,
      editKey,
      discovery,
      reportsRequiringInput,
      reportsNotRequiringInput,
    } = this.state;
    const {
      visualizations,
      isAdvanced,
      isAdmin,
      currentUser,
      search,
      tags,
      chartTypes,
      searchDatasets,
      loading,
    } = this.props;
    const columns = isAdvanced
      ? TableSpec.columns
      : _.reject(TableSpec.columns, 'advanced');

    const filteredContent = _.filter(
      visualizations,
      row =>
        (_.isEmpty(tags) || !_.isEmpty(_.intersection(row.tags, tags))) &&
        (_.isEmpty(chartTypes) || _.includes(chartTypes, row.chartType)) &&
        (_.isEmpty(searchDatasets) ||
          _.includes(searchDatasets, row.datasetName)),
    );

    return (
      <Root className='Library'>
        <MainSectionHeader
          headerLabel={messages.nav.home}
          isButtonVisible={!this.props.isReadOnly}
          onClickHandler={() => this.props.showNewViz()}
          advancedActions={
            isAdvanced && (
              <ButtonContainer>
                <SecondaryButton
                  className='report-defs import-report'
                  onClick={() => this.callImportReport()}
                >
                  {messages.library.importReportButtonLabel}
                </SecondaryButton>
                <SecondaryButton
                  className='report-defs export-report'
                  onClick={() =>
                    this.onReportDownload(filteredContent)(
                      `export-${this.state.tenantName}-reports.json`,
                    )
                  }
                >
                  {messages.library.exportReportButtonLabel}
                </SecondaryButton>
              </ButtonContainer>
            )
          }
        />
        <input
          type='file'
          ref={this.hiddenFileInput}
          onChange={event => this.onFileUploaded(event.target)}
          style={{ display: 'none' }}
          className={'importFileUpload'}
        />
        <TableToolBar className='library-table__toolbar'>
          <TextSearchField
            placeholder={messages.library.searchPlaceholder}
            value={search}
            onChange={this.onSearch.bind(this)}
            large
          />
          <FilterWidgetContextProvider>
            <SearchOptionGroup>
              <FilterWidget
                placeholder={messages.tags.searchPlaceholder}
                disableAdd={true}
                disableSelectAll={false}
                onSave={tags => {
                  this.onSetTags(tags);
                }}
                headerSx={{ marginRight: 0 }}
              />
              <FilterWidget
                placeholder={messages.library.searchChartTypePlaceholder}
                disableAdd={true}
                disableSelectAll={false}
                onSave={t => this.onSetSearchChartType(t)}
                widgetType='chartTypes'
                headerSx={{ marginRight: 0 }}
              />
              <FilterWidget
                placeholder={messages.library.searchDatasetsPlaceholder}
                disableAdd={true}
                disableSelectAll={false}
                onSave={t => this.onSetDatasets(t)}
                widgetType='datasets'
                headerSx={{ marginRight: 0 }}
              />
            </SearchOptionGroup>
          </FilterWidgetContextProvider>
        </TableToolBar>
        <UITable
          className='library-table'
          domain='library'
          data={filteredContent}
          search={search}
          searchOptions={TableSpec.searchOptions}
          loading={loading}
        >
          {({ Column, HeaderRenderer, HeaderRendererWithSort }) => {
            return columns.map(
              col =>
                !col.hidden && (
                  <Column
                    style={{
                      height: '100%',
                    }}
                    key={col.key}
                    dataKey={col.key}
                    label={_.get(messages, col.label, col.label)}
                    headerRenderer={
                      col.sortable ? HeaderRendererWithSort : HeaderRenderer
                    }
                    disableSort={!col.sortable}
                    cellRenderer={({ rowData }) => {
                      if (loading) {
                        return <TableSkeleton />;
                      }

                      return col.cellRenderer({
                        rowData,
                        currentUser,
                        isAdmin,
                        setActionsOpen: this.props.setActionsOpen,
                        doAction: this.doAction.bind(this),
                        isReadOnly: this.props.isReadOnly,
                        onClick: this.onRowClick.bind(this),
                      });
                    }}
                    width={col.width}
                    flexGrow={col.flex}
                    maxWidth={col.maxWidth}
                  />
                ),
            );
          }}
        </UITable>
        {showDelete && (
          <PromptDialogTyped
            show={showDelete}
            detail={this.getDeleteConfirmation()}
            doYes={() => this.doDeleteYes()}
            doNo={() => this.doDeleteNo()}
          />
        )}
        {showImportDuplicates && (
          <ImportReportsDuplicateDialogTyped
            show={showImportDuplicates}
            doSave={(newReports, deleteReports) =>
              this.doImportReportsSave(newReports, deleteReports)
            }
            doCancel={() => this.doImportReportsClose()}
            reportsRequiringInput={reportsRequiringInput}
            reportsNotRequiringInput={reportsNotRequiringInput}
            datasets={this.props.datasets}
          />
        )}
        {showEdit && (
          <EditDiscoveryDialog
            show={showEdit}
            detail={editKey}
            value={editKey === 'name' ? discovery.name : discovery.description}
            onOk={value => this.doEditYes(value)}
            onCancel={() => this.doEditNo()}
          />
        )}
      </Root>
    );
  }
}

export default withErrorBoundary(
  compose(
    withState('actionsOpen', 'setActionsOpen', false),
    ToastHOC,
  )(LibraryComponentUnconnected as any),
  ErrorBoundaryHOCOptions,
);
