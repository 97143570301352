import { Component } from 'react';
import { withTheme } from '@emotion/react';
import { connect } from 'react-redux';
import { compose, pure, withPropsOnChange } from 'react-recompose';
import { createSelector } from 'reselect';
import _, { isEmpty } from 'lodash';
import Line from './Line';
import Circles from './Circles';
import { LineChartUtils } from '../../discovery/charts/LineChartUtils';
import Discover from '../redux/actions/DiscoverActions';
import { join as joinChartData } from '../../discovery/charts/ChartUtils';
import ColorManager from '../../common/d3/ColorManager';
import { HANDLE_NULL_AS } from '../Constants';
import { VizLegendUtils } from '../../discovery/charts/viz-legend';
import { withDiscoverRouter } from '../utilities/router.hoc';
import { LabelManagerConsumerHOC } from './label-manager-provider'; // adds registerLabel prop
import { Viz } from '../../discovery/VizUtil';
import {
  ReportLinkMaybeSideDrawerHOC,
  ScrollContext,
} from '../../discovery/charts/base-cartesian-chart';
import {
  focusPointsFromDrillContext,
  getPlotXPos,
} from './utils/lines/lines.util';
import { curveMonotoneX, pointer, select } from 'd3';
import { shortid } from '../utilities/shortid-adapter';

class Lines extends Component {
  static contextType = ScrollContext;

  constructor(props) {
    super(props);

    // Deconstruct data into simple array of lines
    const lineData = _.get(this.props, 'data.LINES', []);
    this.hoverX = 0;
    this.hoverY = 0;
    this.state = {
      lineData,
    };
  }
  labelClass = 'lines';
  labelTextPadding = 5;
  labelRectRadius = 7;
  labelDefaultHeight = 12;

  getPlotXPos(d) {
    const { xScale, getX, offsetX } = this.props;

    return getPlotXPos({
      point: d,
      xScale,
      getX,
      offsetX,
      context: this.context,
    });
  }

  getPlotYPos(d) {
    const { yScale, getY } = this.props;
    const { topPct = 0, offscreenHeight = 0 } = this.context ?? {};
    const heightScrollOffset = topPct * offscreenHeight;

    const yVal = yScale(getY(d));
    return (_.isNaN(yVal) ? 0 : yVal) - heightScrollOffset;
  }

  componentDidUpdate(prevProps) {
    const hasXScaleChanged = this.hasScaleChanged(
      this.props.xScale,
      prevProps.xScale,
    );
    const hasYScaleChanged = this.hasScaleChanged(
      this.props.yScale,
      prevProps.yScale,
    );

    const sizeChanged = !_.isEqual(
      _.pick(this.props, 'width', 'height'),
      _.pick(prevProps, 'width', 'height'),
    );

    const focusedDataChanged = !_.isEqual(
      _.pick(this.props, 'focusedData', 'focusedDataPoints'),
      _.pick(prevProps, 'focusedData', 'focusedDataPoints'),
    );

    const offsetXChanged = !_.isEqual(
      _.pick(this.props, 'offsetX'),
      _.pick(prevProps, 'offsetX'),
    );

    const chartDimensionsChanged =
      sizeChanged || hasXScaleChanged || hasYScaleChanged || offsetXChanged;

    if (
      !this.props.disableTooltips &&
      _.isEmpty(this.props.focusedDataPoints)
    ) {
      if (
        this.props.showGlobalTooltip &&
        prevProps.showGlobalTooltip === false
      ) {
        // Trigger global tooltip when meta key is down without mouse move
        this.globalMouseMove();
      } else if (
        !this.props.showGlobalTooltip &&
        prevProps.showGlobalTooltip === true
      ) {
        // Remove global tooltip when meta key is up without mouse move
        this.showTooltipAt(null, 0, 0, 0, null, true);
      }
    } else if (this.props.selectionMode && focusedDataChanged) {
      this.showToolTipAnchored();
    }

    const lineData = _.get(this.props, 'data.LINES', []);

    if (
      this.state.lineData !== lineData ||
      chartDimensionsChanged ||
      focusedDataChanged
    ) {
      this.setState({
        lineData,
      });
    }
  }

  componentDidMount() {
    this.lineContainer = select(this.container);

    if (
      this.props.drillFocusEnabled &&
      !_.isEqual(this.props.focusedDataPoints, this.props.drillFocusPoints)
    ) {
      this.props.clearFocusedDataPoints();
      this.props.onFocusedDataPointUpdate(this.props.drillFocusPoints);
    }

    if (!this.props.disableTooltips) {
      this.lineContainer
        .on('mousemove', event => {
          if (this.props.useSelectionMode && this.props.selectionMode) {
            this.showToolTipAnchored();
          } else {
            const mousePos = pointer(event, this.container);
            this.hoverX = mousePos[0];
            this.hoverY = mousePos[1];

            if (this.props.showGlobalTooltip) {
              event.stopPropagation();
              this.globalMouseMove(event);
            }
          }
        })
        .on('mouseout', () => {
          if (
            !this.props.showGlobalTooltip ||
            (this.props.useSelectionMode && this.props.selectionMode)
          ) {
            // ignore mouse out events if not in global mode, may have moused over a line or circle
            return;
          }
          // Meta is down but we've moused out of the area, hide tooltip
          this.onMouseOut(null, false);
        });
    }
  }

  selectDrillContextProps(drillContext) {
    const rAttributes = _.get(drillContext, 'attributes', []);
    const attrs = _.map(rAttributes, ({ attribute, value }) => [
      attribute?.name,
      value,
    ]);
    return attrs;
  }

  getUpdatedLinesAndCircles(lineData) {
    const {
      height,
      width,
      xScale,
      yScale,
      getX,
      getY,
      disableLineSmoothing,
      vizId,
      nullHandling,
      focusedData,
    } = this.props;

    const lines = [];
    const circles = [];

    // Populate line and circle collections for render
    _.forEach(lineData, (linePoints, idx) => {
      const dataKey = `data-${idx}`;
      const focused = VizLegendUtils.isFocusItem(
        focusedData,
        linePoints?.pathInfo,
      );

      const colorKey = LineChartUtils.getColorKey(linePoints?.pathInfo);
      const color = ColorManager.getColor(vizId, colorKey, this.props.theme);
      lines.push(
        <Line
          key={shortid.generate()}
          data={linePoints}
          xScale={xScale}
          yScale={yScale}
          getX={getX}
          offsetX={this.props.offsetX}
          getY={getY}
          className={`line-chart-line solid ${dataKey}`}
          color={color}
          curve={disableLineSmoothing ? null : curveMonotoneX}
          onMouseMove={
            !this.props.disableTooltips &&
            (_lineData => this.onLineMouseMove(_lineData))
          }
          width={width}
          height={height}
          focus={focused}
          nullHandling={nullHandling}
          selectionMode={this.props.selectionMode}
          onLineClick={_lineData => this.onLineClick(_lineData)}
        />,
      );
      if (!this.props.hidePoints) {
        // show highlighted point if side drawer drill context exists

        circles.push(
          <Circles
            key={shortid.generate()}
            dataKey={dataKey}
            focusedPoint={this.props.hoverData?.point}
            data={linePoints}
            xScale={xScale}
            yScale={yScale}
            getX={getX}
            offsetX={this.props.offsetX}
            getY={getY}
            className={`line-chart-dot`}
            color={color}
            radius='5'
            onMouseMove={
              !this.props.disableTooltips &&
              (_lineData => this.onLineMouseMove(_lineData))
            }
            width={width}
            height={height}
            focus={focused}
            nullHandling={nullHandling}
            onClick={(_lineData, dataItem) => {
              this.onCircleClick(_lineData, dataItem);
            }}
            focusedDataPoints={this.props.focusedDataPoints}
            focusedData={this.props.focusedData}
            onFocusedDataPointUpdate={focusedDataPoints =>
              this.props.onFocusedDataPointUpdate(focusedDataPoints)
            }
            useSelectionMode={this.props.useSelectionMode}
            selectionMode={this.props.selectionMode}
            isDashletMode={this.props.isDashletMode}
          />,
        );
      } else if (
        this.props.showLastPoint &&
        idx === this.state?.linePoints?.length - 1
      ) {
        const lastDataPoint = [_.last(linePoints)];
        circles.push(
          <Circles
            key={shortid.generate()}
            dataKey={dataKey}
            focusedPoint={this.props.hoverData?.point}
            data={lastDataPoint}
            xScale={xScale}
            yScale={yScale}
            getX={getX}
            offsetX={this.props.offsetX}
            getY={getY}
            className='line-chart-dot-last'
            radius='1'
            onMouseMove={
              !this.props.disableTooltips &&
              !this.props.selectionMode &&
              (_lineData => this.onLineMouseMove(_lineData))
            }
            width={width}
            height={height}
            focus={focused}
            nullHandling={nullHandling}
            onClick={(_lineData, dataItem) => {
              this.onCircleClick(_lineData, dataItem);
            }}
            focusedDataPoints={this.props.focusedDataPoints}
            focusedData={this.props.focusedData}
            onFocusedDataPointUpdate={focusedDataPoints =>
              this.props.onFocusedDataPointUpdate(focusedDataPoints)
            }
            useSelectionMode={this.props.useSelectionMode}
            selectionMode={this.props.selectionMode}
          />,
        );
      }
    });

    return {
      lines,
      circles,
    };
  }

  showToolTipAnchored() {
    const { lineData: allLineData } = this.state;

    const { anchor, pointData } = this.props.getAnchoredPointData();

    const xAxisPointData = pointData?.axises ?? [];

    this.showTooltipAt(allLineData, anchor.posX, anchor.posY, xAxisPointData);
  }

  getLabelDimensions(datum) {
    const { i18nPrefs } = this.props;
    const formattedLabel = _.isNil(datum?.formatter)
      ? datum?.value
      : datum.formatter.formatSmall(datum?.value, i18nPrefs);
    const textWidth = Viz.calcTextWidth(formattedLabel);
    const textHeight = Viz.calcTextHeight(formattedLabel);
    const rectWidth = textWidth + this.labelTextPadding * 2;
    const rectHeight = textHeight + this.labelTextPadding;

    return {
      width: rectWidth,
      height: rectHeight,
    };
  }

  renderLabel(datum, d3Selection) {
    const { isSecondaryPlot, i18nPrefs, theme } = this.props;
    const { value: label, formatter, focused, anchor } = datum;

    // do not show unfocused labels
    if (_.isNil(d3Selection) || !focused) {
      return;
    }

    const { width: rectWidth, height: rectHeight } = this.getLabelDimensions(
      datum,
    );

    if (!isSecondaryPlot) {
      d3Selection.attr(
        'transform',
        `translate(${anchor?.x - rectWidth / 2},${anchor?.y - rectHeight - 8})`,
      );
    }

    const formattedLabel = _.isNil(formatter)
      ? label
      : formatter.formatSmall(label, i18nPrefs);

    d3Selection
      .insert('rect')
      .attr('fill', theme?.colors?.Gray20)
      .attr('rx', this.labelRectRadius)
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', rectWidth)
      .attr('height', rectHeight + 4);

    d3Selection
      .append('text')
      .attr('fill', theme?.colors?.ContentText)
      .attr('dominant-baseline', 'hanging')
      .attr('x', this.labelTextPadding)
      .attr('y', 1 + this.labelTextPadding / 2)
      .style('font-weight', '300')
      .style('font-style', 'inherit')
      .style('font-size', '10px')
      .text(formattedLabel);
  }

  layoutLabels() {
    const { labelData, resetLabelClass, showDataLabels } = this.props;
    _.isFunction(resetLabelClass) && resetLabelClass(this.labelClass);

    if (showDataLabels) {
      _.forEach(
        _.filter(labelData, point => _.isNumber(point.y)),
        point => this.registerLabelData(point),
      );
    }
  }

  registerLabelData(data) {
    const {
      getY,
      isSecondaryPlot,
      registerLabel,
      height: renderingContainerHeight,
    } = this.props;

    const points = _.isArray(data) ? data : [data];
    const registerLabelInfo = points
      .filter(d => {
        const yVal = getY(d);
        return !_.isNaN(yVal) || !_.isNil(yVal);
      })
      .map(datum => ({
        value: getY(datum),
        anchor: {
          x: this.getPlotXPos(datum),
          y: this.getPlotYPos(datum),
        },
        isSecondaryPlot,
        formatter: datum?.formatter,
        renderLabel: (d, d3BaseElement) => this.renderLabel(d, d3BaseElement),
        focused: datum?.focused,
        renderingContainerHeight,
        labelClass: this.labelClass,
      }));

    _.isFunction(registerLabel) && registerLabel(registerLabelInfo);
  }

  /**
   * Called when the Meta key is down and the mouse moves across the chart
   */
  globalMouseMove(event) {
    let mousePos;
    try {
      mousePos = event
        ? pointer(event, this.container)
        : pointer(this.container);
    } catch {
      // do not do anything if mouse data cannot be accessed
      return;
    }

    if (
      (this.props.drillFocusEnabled &&
        !_.isEmpty(this.props.focusedDataPoints)) ||
      (this.props.useSelectionMode && this.props.selectionMode)
    ) {
      this.showToolTipAnchored();
    } else {
      const { line, point } = this.props.getHoverData(mousePos[0]);

      if (point) {
        this.showTooltipAt(
          line,
          this.props.xScale(joinChartData(point)) + this.props.offsetX,
          this.hoverY,
          point,
          true,
        );
      }
    }
  }

  componentWillUnmount() {
    // Clear any timeouts to prevent error updating unmounted chart
    if (this.clearTooltipTimeout) {
      clearTimeout(this.clearTooltipTimeout);
      this.clearTooltipTimeout = null;
      this.clearTooltipTimeoutAction = null;
    }
  }

  /**
   * Shows the ChartToolTip at the specified location
   * @param lineData
   * @param x - position on d3 cartesian grid
   * @param y - position on d3 cartesian grid
   * @param toolTipData
   * @param immediate only used in hide, if true tooltip is hidden immediately
   */
  showTooltipAt(lineData, x, y, toolTipData, immediate) {
    if (toolTipData) {
      // Show branch
      if (this.clearTooltipTimeout) {
        // Cancel any scheduled hide of the tooltip since we're about to show it
        clearTimeout(this.clearTooltipTimeout);
        this.clearTooltipTimeout = null;
      }

      if (
        _.isFunction(this.props.onHover) &&
        (!this.props.hoverData ||
          !_.isEqual(this.props.hoverData?.point, toolTipData))
      ) {
        // change in hover item
        this.props.onHover({ line: lineData, point: toolTipData }, x, y);
      }
    } else {
      // Hide branch
      // we may have moused out and over to the tooltip, delay to allow time for the tooltip over event to cancel it
      if (this.clearTooltipTimeout) {
        // another scheduled hide, cancel it and recreate
        clearTimeout(this.clearTooltipTimeout);
        this.clearTooltipTimeout = null;
      }

      this.clearTooltipTimeoutAction = () => {
        // Since we're in a timeout need to check to see if we're already unmounted before doing anything
        select(this.indicator).attr('visibility', 'hidden');

        if (_.isFunction(this.props.onHover) && this.props.hoverData) {
          this.props.onHover(0, 0, null);
        }
        this.clearTooltipTimeout = null;
        this.clearTooltipTimeoutAction = null;
      };

      if (immediate) {
        this.clearTooltipTimeoutAction();
      } else {
        // Dampened hide. Allows time for someone to mouse over the tooltip itself
        this.clearTooltipTimeout = setTimeout(
          this.clearTooltipTimeoutAction,
          350,
        );
      }
    }
  }

  /**
   * Called when the user mouses over a Line or its points
   * @param lineData
   */
  onLineMouseMove(lineData) {
    const mousePos = pointer(this.container);
    this.hoverX = mousePos[0];
    this.hoverY = mousePos[1];

    if (this.props.showGlobalTooltip) {
      // Meta is down, trigger show of global tooltip
      this.globalMouseMove(event);
      return;
    }

    if (_.isEmpty(lineData)) {
      return;
    }

    const { xScale, yScale, getX, getY, nullHandling } = this.props;
    const xPos = this.hoverX - this.props.offsetX;

    const nearestPointData = this.props.getNearestPointData(lineData, xPos);
    let yVal = yScale(getY(nearestPointData));
    if (
      LineChartUtils.isNull(nearestPointData, getY) &&
      nullHandling === HANDLE_NULL_AS.ZERO
    ) {
      yVal = yScale(0);
    }
    this.showTooltipAt(
      lineData,
      xScale(nearestPointData?.x) + this.props.offsetX,
      yVal,
      nearestPointData,
      getX(nearestPointData),
    );
  }

  onCircleClick(lineData, dataItem) {
    dataItem = {
      ...dataItem,
      info: LineChartUtils.collectPathTo(
        this.props.data,
        lineData,
        dataItem,
        this.props.xAxisShelf,
        this.props.dataFormatters,
        this.props?.i18nPrefs,
        this.props?.customFormatProps,
      ),
    };

    // Toggle selection
    if (this.props.useSelectionMode) {
      const collectDetailInfoArgs = this.props.getDrillContextArgs(
        lineData,
        dataItem,
      );

      this.props.toggleFocusedDataPoint(
        dataItem,
        lineData,
        collectDetailInfoArgs,
      );
    } else if (this.props.enableReportLink) {
      const drillContext = LineChartUtils.collectDetailInfo(
        this.props.data,
        lineData,
        dataItem,
        this.props.xAxisShelf,
      );

      this.props.openReportLink(drillContext, this.props.history);
    } else if (this.props.hasSideDrawerDrill) {
      const collectDetailInfoArgs = this.props.getDrillContextArgs(
        lineData,
        dataItem,
      );

      // using this for rendering styles
      this.props.clearFocusedDataPoints();
      this.props.toggleFocusedDataPoint(
        dataItem,
        lineData,
        collectDetailInfoArgs,
      );

      const drillContext = LineChartUtils.collectDetailInfo(
        this.props.data,
        lineData,
        dataItem,
        this.props.xAxisShelf,
      );

      this.props.setDrillContext(drillContext);
    } else {
      this.props.setFocusedData(lineData.pathInfo);
    }
  }

  onLineClick(lineData) {
    if (this.props.useSelectionMode && this.props.selectionMode) {
      this.props.clearFocusedDataPoints();
    }

    this.props.setFocusedData(lineData?.pathInfo);
  }

  onMouseOut(lineData, immediate) {
    this.showTooltipAt(null, 0, 0, 0, immediate);
  }

  getScaleDomainAndRange(scale) {
    return {
      range: scale.range() ?? [],
      domain: scale.domain() ?? [],
    };
  }

  hasScaleChanged(versionA, versionB) {
    const {
      domain: vAScaleDomain,
      range: vAScaleRange,
    } = this.getScaleDomainAndRange(versionA);
    const {
      domain: vBScaleDomain,
      range: vBScaleRange,
    } = this.getScaleDomainAndRange(versionB);

    const areScaleDomainsEqual = _.every(vAScaleDomain, (_domain, idx) =>
      _.isEqual(_domain, vBScaleDomain[idx]),
    );
    const areScaleRangesEqual = _.every(vAScaleRange, (_domain, idx) =>
      _.isEqual(_domain, vBScaleRange[idx]),
    );

    return !(areScaleDomainsEqual && areScaleRangesEqual);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const hasXScaleChanged = this.hasScaleChanged(
      this.props.xScale,
      nextProps.xScale,
    );
    const hasYScaleChanged = this.hasScaleChanged(
      this.props.yScale,
      nextProps.yScale,
    );

    const simplePropsChanged = _.some(
      [
        'height',
        'width',
        'getX',
        'getY',
        'disableLineSmoothing',
        'vizId',
        'nullHandling',
        'data.LINES',
        'focusedData',
        'focusedDataPoints',
        'showDataLabels',
      ],
      _prop => _.get(this.props, _prop) !== _.get(nextProps, _prop),
    );

    const stateChanged = _.some(
      ['lines', 'circles', 'lineData'],
      _prop => this.state[_prop] !== nextState[_prop],
    );

    return (
      simplePropsChanged || stateChanged || hasXScaleChanged || hasYScaleChanged
    );
  }

  render() {
    const { height, width } = this.props;
    const lineData = _.get(this.props, 'data.LINES', []);

    // The behavior of class-based components and the re-rendering in base cartesian is not friendly to setting lines & circles in componentDidUpdate
    const { lines, circles } = this.getUpdatedLinesAndCircles(lineData);

    this.layoutLabels();

    const globalTooltipLayer = this.props.globalMouseTooltipEnabled && (
      <rect
        style={{ visibility: 'hidden' }}
        width={Math.max(width, 0)}
        height={Math.max(height, 0)}
        className='chartAreaLine'
        id='chartAreaLine'
      />
    );
    return (
      <g width={width} height={height} className={this.props.className}>
        <g ref={el => (this.container = el)} className='captureMouseEvents'>
          {globalTooltipLayer}
          {lines}
          {circles}
        </g>
      </g>
    );
  }
}

Lines.defaultProps = {
  className: 'line',
  showLabels: false,
  focus: _.noop,
  focusedData: [],
  paletteOffset: 0, // Start color cycle offset by this amount
  offsetX: 0,
  xAxisShelf: 'XAXIS',
  globalMouseTooltipEnabled: true,
  showGlobalTooltip: false,
  i18nPrefs: {},
};

// can not re-use these selectors from viz-selectors otherwise we end up with a long circular reference
const getActive = (state, props) => {
  const discoveryId = _.isNil(props.vizId)
    ? state.discover.displayDiscovery
    : props.vizId;
  const { openDiscoveries } = state.discover;
  return _.get(openDiscoveries, `${discoveryId}.present`, null);
};

const tooltipDataSelector = createSelector([getActive], open => {
  return open ? open.tooltipData : null;
});
const hoverDataSelector = createSelector([tooltipDataSelector], tooltipData => {
  return tooltipData?.hoverData ? tooltipData.hoverData : {};
});

const focusedDataPointsSelector = createSelector([getActive], open => {
  return _.get(open, 'focusedDataPoints', []);
});

export default compose(
  pure,
  withDiscoverRouter,
  withTheme,
  LabelManagerConsumerHOC,
  ReportLinkMaybeSideDrawerHOC,
  connect(
    (state, props) => {
      const { i18nPrefs = {} } = state?.account?.currentUser;
      const { xScale, getX, offsetX } = props;

      const drillFocusEnabled =
        props.isInSideDrawer && !isEmpty(props.drillContext);

      const drillFocusPoints = focusPointsFromDrillContext({
        drillFocusEnabled,
        drillContext: props?.drillContext,
        data: props?.data,
        getXPos: point =>
          getPlotXPos({
            point,
            xScale,
            getX,
            offsetX,
          }),
      });

      let hoverPoint = hoverDataSelector(state, props);

      if (
        _.isEmpty(hoverPoint) &&
        drillFocusEnabled &&
        _.head(drillFocusPoints)
      ) {
        hoverPoint = {
          point: _.head(drillFocusPoints),
        };
      }

      return {
        isDashletMode: state.dashlet.isDashletMode,
        showGlobalTooltip: state.main.controlDown || props.showGlobalTooltip,
        hoverData: hoverPoint,
        drillFocusPoints,
        drillFocusEnabled,
        focusedDataPoints: focusedDataPointsSelector(state, props),
        i18nPrefs,
      };
    },
    (dispatch, ownProps) => ({
      openReportLink(drillContext) {
        dispatch(Discover.openReportLink(drillContext, ownProps.history));
      },
      setDrillContext(drillContext) {
        dispatch(Discover.setDrillContext(drillContext));
      },
      setFocusedData(dataItem) {
        ownProps.focus();
        dispatch(Discover.setFocusedVizData(ownProps.vizId, dataItem));
      },
      toggleFocusedDataPoint(pointData, lineData, collectDetailInfoArgs = {}) {
        dispatch(
          Discover.toggleFocusedDataPoint(
            ownProps.vizId,
            pointData,
            lineData,
            collectDetailInfoArgs,
          ),
        );
      },
      clearFocusedDataPoints() {
        dispatch(Discover.setFocusedDataPoints(ownProps.vizId, [], []));
      },
    }),
  ),
  withPropsOnChange(['data'], props => {
    // this block of code was re-implemented from 80dac5
    const { focusedData } = props;
    const lines = _.get(props, 'data.LINES', []);
    const maxLabels = 50;
    const totalDataPointCount = lines.reduce((count, lineData) => {
      return count + lineData?.length;
    }, 0);
    const pointData = _.reduce(
      lines,
      (collection, line, lineIndex) => {
        const focused =
          _.isEmpty(focusedData) || _.some(focusedData, line.pathInfo);
        line
          .filter((point, index) => {
            if (totalDataPointCount > maxLabels) {
              // try to evenly distribute the labels across lines
              const maxLineLabels = Math.floor(maxLabels / lines.length);
              const modFactor =
                line.length > maxLineLabels
                  ? Math.ceil(line.length / maxLineLabels)
                  : 1;

              const offset = Math.floor(modFactor / lines.length) * lineIndex;
              return index % modFactor === offset;
            } else {
              return true;
            }
          })
          .forEach(p => {
            collection.push({ ...p, focused });
          });
        return collection;
      },
      [],
    );
    return {
      labelData: pointData,
    };
  }),
)(Lines);
