import { useDiscoverTheme } from '../../../../common/emotion/theme';

export const useTabStyles = () => {
  const {
    colors: { PanelNavBackground },
  } = useDiscoverTheme();

  return {
    minHeight: '32px',
    minWidth: '32px',
    width: '32px',
    height: '32px',
    marginRight: '4px',
    borderRadius: '4px',
    div: {
      cursor: 'pointer',
    },
    '&:hover': {
      backgroundColor: PanelNavBackground,
    },
    '&.active': {
      backgroundColor: PanelNavBackground,
    },
  };
};
