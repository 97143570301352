import { ReactNode, useCallback, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ChartSpecs } from '../ChartSpecs';
import Discover from '../../common/redux/actions/DiscoverActions';
import {
  filter,
  head,
  isEqual,
  isFunction,
  isNil,
  map,
  merge,
  noop,
  get,
  slice,
  intersection,
  isEmpty,
  some,
  constant,
} from 'lodash';
import { Viz } from '../VizUtil';
import {
  ISearchableDropdownOption,
  SearchableDropdown,
} from '../../ui/dropdowns/searchable-dropdown';
import DiscoverQueries from '../../common/graphql/DiscoverQueries';
import { messages } from '../../i18n';
import {
  Header,
  SubHeader,
  LinkFilterStrategyDiv,
  LinkFilterStrategyLabelDiv,
  VizFormattingHeader,
  FlexibleWrapper,
  DrillLinkingRowWrapper,
  DrillLinkingDisabledToastIcon,
  FormControlLabelStyles,
  CheckboxStyles,
} from './viz-formatting.styles';
import {
  DisableableSpan,
  DisableableLabel,
} from '../../common/emotion/utility-components.styles';
import { useMultiSelectDrillLinkDisable } from '../viz-config.util';
import { Tooltip } from '../../components/ui/tooltip';
import DiscoverActions from '../../common/redux/actions/DiscoverActions';
import { withDiscoverOption } from '../discovery-context/discovery.context';
import { compose } from 'react-recompose';
import { ICustomFormatToggle, ILayout } from '../interfaces';
import { useQuery } from '@apollo/client';
import { makeDropdownOption } from '../../ui/dropdowns/searchable-dropdown/searchable-dropdown.utils';
import {
  useOpenVizSelector,
  useVizOptionSelector,
} from '../../common/redux/selectors/viz-selector.hook';
import { Box } from '@mui/system';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ToastIcon } from '../../icons';
import { Radio } from '../../components/ui/radio';

const UnconnectedVizFormatting = ({
  setFunnelStageVisibilityOptions,
  vizId,
  linkToReport,
  setLinkToReport,
  showLegendPanel,
  setShowLegendPanel,
  showLegendTitles,
  setShowLegendTitles,
  showDataLabels,
  setShowDataLabels,
  showFiltersPanel,
  setShowFilters,
  showRuntimeFilters,
  setShowRuntimeFilters,
  linkStrategy,
  setLinkFilterStrategy,
  children,
}) => {
  const viz = useOpenVizSelector({
    discoveryId: vizId,
  });

  const chartType = viz?.chartType;

  const isAdvancedMode = useSelector((state: any) => state?.main?.advanced);

  const chartSpec = ChartSpecs[chartType] || ChartSpecs.bar;

  const setCustomFormatToggle = useSetCustomFormatToggle(vizId);
  const formControlLabelSx = FormControlLabelStyles();
  const checkboxSx = CheckboxStyles();

  const updateLinkToReport = ({ id, name, options = {} }) => {
    let _linkToReport: { id?: string; name?: string; [opt: string]: any } = {};
    if (!isEmpty(id)) {
      _linkToReport = { id, name, ...options };
    }

    if (!isEqual(_linkToReport, linkToReport)) {
      setLinkToReport(_linkToReport);
    }
  };

  const renderTotals = () => {
    if (
      customSubtotalsToggles.length === 0 &&
      customGrandtotalsToggles.length === 0
    ) {
      return [];
    }
    const groupMargin = { marginTop: 16 };
    return [
      <Header key='header'>{messages.formatting.totals}</Header>,
      <Box key='form' className='pivot-conditional-formatting'>
        <Box className='pivot-conditional-formatting__form-group'>
          {customSubtotalsToggles.length > 0 && (
            <div>
              <SubHeader>{messages.formatting.subTotals}</SubHeader>
              {customSubtotalsToggles}
            </div>
          )}

          {customGrandtotalsToggles.length > 0 && (
            <div style={customSubtotalsToggles.length > 0 ? groupMargin : {}}>
              <SubHeader>{messages.formatting.grandTotals}</SubHeader>
              {customGrandtotalsToggles}
            </div>
          )}

          {countsTotals.length > 0 && (
            <div
              style={
                customSubtotalsToggles.length > 0 ||
                customGrandtotalsToggles.length > 0
                  ? groupMargin
                  : {}
              }
            >
              <SubHeader>{messages.formatting.counts}</SubHeader>
              {countsTotals}
            </div>
          )}
        </Box>
      </Box>,
    ];
  };

  const { multiSelectDrillLinkDisabled } = useMultiSelectDrillLinkDisable(
    get(chartSpec, 'id', ''),
    (viz?.layout ?? []) as ILayout,
  );

  const getCustomToggles = (spec, toggleType) => {
    // don't bother if there are no custom format options for this chart spec
    if (isEmpty(spec.customFormatToggles)) {
      return [];
    }
    // get the array of custom format toggles that are ON from the viz state for the toggle type section
    const togglesOfType = filter(spec.customFormatToggles, toggle => {
      return (
        (!isFunction(toggle.isAvailable) ||
          (isFunction(toggle.isAvailable) && toggle.isAvailable(viz))) &&
        toggle.type === toggleType &&
        (isNil(toggle.isAdvanced) || (toggle.isAdvanced && isAdvancedMode))
      );
    });

    const toggleOptions = togglesOfType
      .sort((a, b) => {
        const leftGroup = get(a, 'group.order', 0);
        const rightGroup = get(b, 'group.order', 0);
        return leftGroup - rightGroup;
      })
      .reduce((formatToggleComponents, formatToggle, idx, originatingArray) => {
        const previousGroupName =
          idx > 0 ? get(originatingArray[idx - 1], 'group.name', null) : null;

        // needs formatting
        const groupName = get(formatToggle, 'group.name', null);

        if (!isNil(groupName) && groupName !== previousGroupName) {
          formatToggleComponents.push(
            <DisableableLabel
              title={groupName}
              key={`toggle-${groupName}-toggle-${idx}`}
              className={'toggle-group'}
            >
              {groupName}
            </DisableableLabel>,
          );
        }

        const checked = Viz.isCustomFormatToggleOn(formatToggle.name, viz);
        const disabled = isFunction(formatToggle.isDisabled)
          ? formatToggle.isDisabled(viz)
          : false;
        if (
          !isNil(formatToggle.shelf) &&
          viz.layout[formatToggle.shelf].length > 2
        ) {
          formatToggleComponents.push(
            <ToggleWithShelfItemSelection
              key={`toggle-${groupName}-shelf-${idx}`}
              discoveryId={vizId}
              checked={checked}
              formatToggle={formatToggle}
            />,
          );
        } else if (formatToggle.requiresReportSelection) {
          formatToggleComponents.push(
            <ToggleWithReportSelection
              key={`toggle-${groupName}-report-${idx}`}
              formatToggle={formatToggle}
              checked={checked}
              linkToReport={linkToReport}
              setLinkToReport={updateLinkToReport}
              discoveryId={vizId}
              multiSelectDrillLinkDisabled={multiSelectDrillLinkDisabled}
            />,
          );
        } else {
          formatToggleComponents.push(
            <DisableableCheckbox
              key={`toggle-${groupName}-other-${idx}`}
              formatToggle={formatToggle}
              onChange={event =>
                setCustomFormatToggle({
                  name: formatToggle.name,
                  value: event.target.checked,
                })
              }
              checked={checked}
              disabled={disabled}
            />,
          );
        }
        return formatToggleComponents;
      }, []);
    return toggleOptions;
  };
  const customShowToggles = getCustomToggles(chartSpec, 'show');
  const customPropertyToggles = getCustomToggles(chartSpec, 'property');
  const customSubtotalsToggles = getCustomToggles(chartSpec, 'subtotals');
  const customGrandtotalsToggles = getCustomToggles(chartSpec, 'grandtotals');
  const countsTotals = getCustomToggles(chartSpec, 'counts');
  const customReportLinkingToggles = getCustomToggles(
    chartSpec,
    'reportLinking',
  );

  const funnelStageVisibilityValues = useVizOptionSelector({
    discoveryId: vizId,
    option: 'funnelStageVisibility',
    defaultValue: [],
  });

  const funnelStageVisibilityOptions = useMemo(() => {
    return chartType === 'funnel'
      ? map(funnelStageVisibilityValues ?? [], _stageValue => (
          <DisableableCheckbox
            formatToggle={{
              name: _stageValue?.key,
              displayTooltip: _stageValue?.label,
              displayLabel: _stageValue?.label,
            }}
            onChange={() => {
              const updatedVisibilityOptions = map(
                funnelStageVisibilityValues,
                _option => {
                  if (_stageValue?.key === _option.key) {
                    return {
                      ..._option,
                      isVisible: !_option.isVisible,
                    };
                  }
                  return _option;
                },
              );

              setFunnelStageVisibilityOptions(updatedVisibilityOptions);
            }}
            checked={_stageValue?.isVisible}
          />
        ))
      : [];
  }, [chartType, funnelStageVisibilityValues, setFunnelStageVisibilityOptions]);

  const legendDisabled = chartSpec.isToggleDisabled('showLegendPanel', viz);
  const legendTitlesDisabled = chartSpec.isToggleDisabled(
    'showLegendTitles',
    viz,
  );
  const showLinkStrategyPanel = Viz.isCustomFormatToggleOn(
    'enableReportLink',
    viz,
  );

  const onDrillStrategyChanged = event => {
    setLinkFilterStrategy(event.currentTarget.value);
  };
  return (
    <div className={'viz-formatting'}>
      <div className={'layout-container'}>
        <div className='viz-formatting-header'>{messages.formatting.show}</div>
        <Box onSubmit={e => e.preventDefault()}>
          <div className='viz-formatting-form-group'>
            {!isEmpty(funnelStageVisibilityOptions) && (
              <div className='viz-formatting-header'>
                {messages.formatting.layout}
              </div>
            )}
            <Box>
              <FormControlLabel
                sx={formControlLabelSx}
                control={
                  <Checkbox
                    sx={checkboxSx}
                    title={messages.formatting.legend}
                    checked={legendDisabled ? false : showLegendPanel}
                    disabled={legendDisabled}
                    onChange={(event: any) =>
                      setShowLegendPanel(event.target.checked)
                    }
                  />
                }
                label={<span>{messages.formatting.legend}</span>}
              />
              {!legendTitlesDisabled && (
                <FormControlLabel
                  sx={formControlLabelSx}
                  control={
                    <Checkbox
                      sx={checkboxSx}
                      title={messages.formatting.legendTitles}
                      checked={legendTitlesDisabled ? false : showLegendTitles}
                      onChange={(event: any) =>
                        setShowLegendTitles(event.target.checked)
                      }
                    />
                  }
                  label={<span>{messages.formatting.legendTitles}</span>}
                />
              )}
              {chartType !== 'pivot' ? (
                <FormControlLabel
                  sx={formControlLabelSx}
                  control={
                    <Checkbox
                      sx={checkboxSx}
                      title={messages.formatting.dataLabels}
                      checked={showDataLabels}
                      onChange={(event: any) =>
                        setShowDataLabels(event.target.checked)
                      }
                    />
                  }
                  label={<span>{messages.formatting.dataLabels}</span>}
                />
              ) : null}
              <FormControlLabel
                sx={formControlLabelSx}
                control={
                  <Checkbox
                    sx={checkboxSx}
                    title={messages.formatting.filters}
                    checked={showFiltersPanel}
                    onChange={(event: any) =>
                      setShowFilters(event.target.checked)
                    }
                  />
                }
                label={<span>{messages.formatting.filters}</span>}
              />
              <FormControlLabel
                sx={formControlLabelSx}
                control={
                  <Checkbox
                    sx={checkboxSx}
                    title={messages.formatting.runtimeFiltersDashlets}
                    checked={showRuntimeFilters}
                    onChange={(event: any) =>
                      setShowRuntimeFilters(event.target.checked)
                    }
                  />
                }
                label={
                  <span>{messages.formatting.runtimeFiltersDashlets}</span>
                }
              />
              {customShowToggles}
            </Box>
            {!isEmpty(funnelStageVisibilityOptions) && (
              <div className='viz-formatting-header'>
                {messages.formatting.stages}
              </div>
            )}
            {!isEmpty(funnelStageVisibilityOptions) && (
              <Box className={'funnelStageVisibilityOptions'}>
                {funnelStageVisibilityOptions}
              </Box>
            )}
          </div>
        </Box>

        {customPropertyToggles.length > 0 && (
          <div>
            <div className='viz-formatting-header'>
              {messages.formatting.properties}
            </div>
            <Box onSubmit={e => e.preventDefault()}>
              <Box className='viz-formatting-form-group'>
                {customPropertyToggles}
              </Box>
            </Box>
          </div>
        )}

        {renderTotals()}

        {customReportLinkingToggles.length > 0 && (
          <div>
            <VizFormattingHeader
              disabled={multiSelectDrillLinkDisabled}
              className='viz-formatting-header'
            >
              {messages.formatting.drillLinking}
            </VizFormattingHeader>
            {multiSelectDrillLinkDisabled && (
              <Tooltip
                placement='right'
                arrow
                title={messages.layoutPanel.drillLinkingDisabledInfo}
                enterDelay={500}
              >
                <DrillLinkingDisabledToastIcon>
                  <ToastIcon iconType={'info'} />
                </DrillLinkingDisabledToastIcon>
              </Tooltip>
            )}
            <Box onSubmit={e => e.preventDefault()}>
              <Box className='viz-formatting-form-group'>
                {customReportLinkingToggles}
                {showLinkStrategyPanel && !multiSelectDrillLinkDisabled && (
                  <LinkFilterStrategyDiv>
                    <LinkFilterStrategyLabelDiv
                      disabled={multiSelectDrillLinkDisabled}
                    >
                      {messages.formatting.targetReportFiltering}:
                    </LinkFilterStrategyLabelDiv>
                    <div>
                      <Radio
                        name='drill-filter-strategy'
                        checked={linkStrategy === 'update'}
                        value={'update'}
                        onChange={onDrillStrategyChanged}
                        label={messages.formatting.updateAndAddNewFilters}
                      />
                      <Radio
                        disabled={multiSelectDrillLinkDisabled}
                        name='drill-filter-strategy'
                        checked={linkStrategy === 'clear'}
                        value={'clear'}
                        onChange={onDrillStrategyChanged}
                        label={messages.formatting.clearAndAddNewFilters}
                      />
                      <Radio
                        name='drill-filter-strategy'
                        checked={linkStrategy === 'noop'}
                        value={'noop'}
                        onChange={onDrillStrategyChanged}
                        label={messages.formatting.doNotModifyFilters}
                      />
                    </div>
                  </LinkFilterStrategyDiv>
                )}
              </Box>
            </Box>
          </div>
        )}

        {children}
      </div>
    </div>
  );
};

export const ToggleWithReportSelection: any = ({
  formatToggle,
  checked,
  linkToReport: providedLinkToReport,
  setLinkToReport,
  discoveryId,
  multiSelectDrillLinkDisabled,
}) => {
  const [linkToReport, _setLinkToReport] = useState(providedLinkToReport);
  const setCustomFormatToggle = useSetCustomFormatToggle(discoveryId);

  const { loading, error, data: { visualizations = [] } = {} } = useQuery(
    DiscoverQueries.DiscoveriesQuery,
  );

  const linkReportOptions = useMemo(() => {
    return filter(visualizations, { chartType: ChartSpecs.pivot.id });
  }, [visualizations]);

  const linkReport = useCallback(
    linkingReport => {
      if (!isEqual(linkToReport, linkingReport)) {
        _setLinkToReport(linkingReport);
        isFunction(setLinkToReport) && setLinkToReport(linkingReport);
      }
    },
    [linkToReport, setLinkToReport],
  );

  if (loading || error) {
    return [];
  }

  const { id: linkedId, options: { isValid = true } = {} } = linkToReport;

  let errorMessage: string;
  if (
    !isNil(linkedId) &&
    isValid &&
    !some(linkReportOptions, { id: linkedId })
  ) {
    errorMessage = messages.formatting.errorReportNoLongerExists;
    linkReport(
      merge(linkToReport, {
        options: { isValid: false },
      }),
    );
  }

  const options = map(linkReportOptions, v => makeDropdownOption(v.id, v.name));
  const defaultValue: ISearchableDropdownOption = !isNil(linkedId)
    ? {
        value: linkedId,
        label: linkToReport?.name,
        isSelected: true,
      }
    : null;
  const defaultChecked = checked && !multiSelectDrillLinkDisabled;

  return (
    <ToggledSelector
      id={'reportLinkDropdown'}
      key={`${defaultChecked}`}
      formatToggle={formatToggle}
      defaultChecked={defaultChecked}
      onToggle={toggledValue =>
        setCustomFormatToggle({
          name: formatToggle.name,
          value: toggledValue,
        })
      }
      defaultValue={defaultValue}
      placeholder={messages.formatting.searchReports}
      errorMessage={errorMessage}
      options={options}
      disabled={multiSelectDrillLinkDisabled}
      onSelect={(options: ISearchableDropdownOption[]) => {
        const option = head(options) ?? {};

        linkReport({
          id: option?.value,
          name: option?.label,
        });
      }}
    />
  );
};

const useSetCustomFormatToggle = discoveryId => {
  const dispatch = useDispatch();
  return useCallback(
    ({
      name: toggleName,
      value: toggledValue,
      options,
    }: {
      name: string;
      value: boolean;
      options?: any;
    }) => {
      isFunction(dispatch) &&
        dispatch(
          Discover.setCustomFormatToggle(
            discoveryId,
            toggledValue,
            toggleName,
            options,
          ),
        );
    },
    [dispatch, discoveryId],
  );
};

const DisableableCheckbox = ({
  formatToggle,
  onChange,
  checked = false,
  disabled = false,
}) => {
  const formControlLabelSx = FormControlLabelStyles();
  const checkboxSx = CheckboxStyles();
  return (
    <FormControlLabel
      sx={formControlLabelSx}
      control={
        <Checkbox
          sx={checkboxSx}
          title={get(
            messages,
            formatToggle.displayLabel,
            formatToggle.displayLabel,
          )}
          checked={checked}
          className={formatToggle.name}
          disabled={disabled}
          onChange={isFunction(onChange) ? onChange : noop}
        />
      }
      label={get(
        messages,
        formatToggle.displayLabel,
        formatToggle.displayLabel,
      )}
    />
  );
};

const ToggleWithShelfItemSelection = ({
  discoveryId,
  formatToggle,
  checked,
}) => {
  const viz = useOpenVizSelector({ discoveryId });
  const allFields = viz.layout[formatToggle.shelf].map(f => f.name);
  const validFields = slice(allFields, 0, allFields.length - 1);
  const options = Viz.getCustomFormatToggleOptions(formatToggle.name, viz);
  let selected = get(options, 'fields', 'ALL');
  // ensure selected fields are currently in play
  if (selected !== 'ALL') {
    selected = intersection(selected, validFields);
  }
  const selectedOptions = map(selected, fieldName =>
    makeDropdownOption(fieldName),
  );

  const setCustomFormatToggle = useSetCustomFormatToggle(discoveryId);

  return (
    <ToggledSelector
      id={`toggle-selector-${formatToggle.name}`}
      key={`toggle-selector-${formatToggle.name}`}
      formatToggle={formatToggle}
      defaultChecked={checked}
      onToggle={toggledValue =>
        setCustomFormatToggle({
          name: formatToggle.name,
          value: toggledValue,
        })
      }
      defaultValue={selectedOptions}
      placeholder={messages.formatting.searchReports}
      options={map(validFields, fieldName => makeDropdownOption(fieldName))}
      onSelect={(options: ISearchableDropdownOption[]) => {
        const selectedFields = map(options, 'value');

        if (selectedFields.length === 0) {
          // set default options when none selected
          setCustomFormatToggle({
            name: formatToggle.name,
            value: true,
            options: formatToggle.getDefaultOptions(viz),
          });
        } else if (selectedFields.length === validFields.length) {
          // set special value when all fields selected
          setCustomFormatToggle({
            name: formatToggle.name,
            value: true,
            options: {
              fields: 'ALL',
            },
          });
        } else {
          setCustomFormatToggle({
            name: formatToggle.name,
            value: true,
            options: {
              fields: selectedFields,
            },
          });
        }
      }}
      multiSelect
    />
  );
};

export const ToggledSelector = ({
  id,
  formatToggle,
  defaultChecked = false,
  onToggle = noop,
  placeholder,
  defaultValue,
  errorMessage,
  options,
  onSelect = noop,
  multiSelect = false,
  disabled,
}: {
  id: string;
  formatToggle: ICustomFormatToggle;
  defaultChecked?: boolean;
  onToggle: (toggledValue: boolean) => void;
  placeholder?: string;
  defaultValue?: ISearchableDropdownOption | ISearchableDropdownOption[];
  errorMessage?: string;
  options?: ISearchableDropdownOption[];
  onSelect?: (options: ISearchableDropdownOption[]) => void;
  multiSelect?: boolean;
  disabled?: boolean;
}) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);
  const formControlLabelSx = FormControlLabelStyles();
  const checkboxSx = CheckboxStyles();
  return (
    <DrillLinkingRowWrapper>
      <FormControlLabel
        sx={formControlLabelSx}
        control={
          <Checkbox
            sx={checkboxSx}
            title={get(
              messages,
              formatToggle.displayLabel,
              formatToggle.displayLabel,
            )}
            checked={isChecked}
            className={formatToggle.name}
            disabled={disabled}
            onChange={() => {
              setIsChecked(prevChecked => {
                const toggledValue = !prevChecked;
                onToggle(toggledValue);
                return toggledValue;
              });
            }}
          />
        }
        label={
          <DisableableSpan
            title={get(
              messages,
              formatToggle.displayTooltip,
              formatToggle.displayTooltip,
            )}
          >
            {get(
              messages,
              formatToggle.displayLabel,
              formatToggle.displayLabel,
            )}
          </DisableableSpan>
        }
      />
      <FlexibleWrapper>
        {isChecked && (
          <SearchableDropdown
            id={id}
            style={{
              alignSelf: 'center',
              padding: '0 4px',
            }}
            placeholder={placeholder}
            defaultValue={defaultValue}
            errorMessage={errorMessage}
            options={options}
            onSelect={onSelect}
            multiSelect={multiSelect}
            headerSx={{
              width: '100%',
            }}
          />
        )}
      </FlexibleWrapper>
    </DrillLinkingRowWrapper>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setShowDataLabels: checked => {
    dispatch(
      Discover.setSettingForViz({
        id: ownProps.vizId,
        setting: 'showDataLabels',
        value: checked,
      }),
    );
  },
  setShowFilters: checked => {
    dispatch(
      Discover.setSettingForViz({
        id: ownProps.vizId,
        setting: 'showFiltersPanel',
        value: checked,
      }),
    );
  },
  setShowRuntimeFilters: checked => {
    dispatch(
      Discover.setSettingForViz({
        id: ownProps.vizId,
        setting: 'showRuntimeFilters',
        value: checked,
      }),
    );
  },
  setShowLegendPanel: checked => {
    dispatch(
      Discover.setSettingForViz({
        id: ownProps.vizId,
        setting: 'showLegendPanel',
        value: checked,
      }),
    );
  },
  setShowLegendTitles: checked => {
    dispatch(
      Discover.setSettingForViz({
        id: ownProps.vizId,
        setting: 'showLegendTitles',
        value: checked,
      }),
    );
  },
  setLinkToReport: linkToReport => {
    dispatch(
      Discover.setSettingForViz({
        id: ownProps.vizId,
        setting: 'linkToReport',
        value: linkToReport,
      }),
    );
  },
  setLinkFilterStrategy: strategy => {
    dispatch(
      Discover.setSettingForViz({
        id: ownProps.vizId,
        setting: 'linkStrategy',
        value: strategy,
      }),
    );
  },
  setFunnelStageVisibilityOptions: (
    stageOptions: { key: string; label: string }[],
  ) => {
    dispatch(
      DiscoverActions.setFunnelStageVisibilityOptions(
        ownProps.vizId,
        stageOptions,
      ),
    );
  },
});

export const VizFormatting = compose<
  any,
  { vizId: string; children: ReactNode }
>(
  withDiscoverOption({ option: 'showFiltersPanel', defaultValue: true }),
  withDiscoverOption({ option: 'showLegendPanel', defaultValue: true }),
  withDiscoverOption({ option: 'showLegendTitles', defaultValue: true }),
  withDiscoverOption({ option: 'showDataLabels', defaultValue: true }),
  withDiscoverOption({ option: 'linkStrategy', defaultValue: 'update' }),
  withDiscoverOption({ option: 'linkToReport', defaultValue: {} }),
  withDiscoverOption({ option: 'showRuntimeFilters' }),
  connect(constant({}), mapDispatchToProps),
)(UnconnectedVizFormatting);
