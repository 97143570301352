import { Navigate } from 'react-router-dom';
import _ from 'lodash';
import InListFilter from '../../discovery/filter/exports/InListFilter';
import DateFilter from '../../discovery/filter/exports/DateFilter';
import { FilterOperators } from '../../discovery/filter/FilterOperators';
import {
  FilterTypes,
  TimestampFilterSubTypes,
} from '../../discovery/filter/Filter';
import { IVizBase, IDehydratedViz } from '../../discovery';
import { IMonitorEvent } from '../../monitors/interfaces';
import { IComparisonMonitorConfig } from './interfaces';
import { IFilter } from '../../datasets';
import moment from '../../common/Moment';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import { compose, withProps } from 'react-recompose';
import { shortid } from '../../common/utilities/shortid-adapter';
import { ROUTER_DIRS } from '../../common';

export const ComparisonTimePeriodMapping = {
  DAY: 'DAYS',
  CALENDAR_WEEK: 'CALENDAR_WEEKS',
  CALENDAR_MONTH: 'CALENDAR_MONTHS',
  MONTH: 'MONTHS',
  WEEK: 'WEEKS',
  YEAR: 'YEARS',
  QUARTER: 'CALENDAR_QUARTERS',
  CALENDAR_QUARTER: 'CALENDAR_QUARTERS',
  CALENDAR_YEAR: 'CALENDAR_YEARS',
};

const DefaultPeriod = 'CALENDAR_MONTHS';

const RelativeDateOperators = FilterOperators.forFilterType(
  FilterTypes.DATE,
  TimestampFilterSubTypes.RELATIVE_DATES,
);

export const getDateFiltersForComparisonMonitorRedirect = (
  viz: IDehydratedViz,
  monitorEvent: IMonitorEvent,
  monitorConfig: IComparisonMonitorConfig,
) => {
  const filterOpts: any = _.find(viz.options, _.matches({ key: 'filters' }));
  let existingFilters;
  try {
    existingFilters = JSON.parse(filterOpts?.value);
  } catch {
    existingFilters = {};
  }
  const snapshotDate: IFilter = _.get(existingFilters, 'Snapshot Date');
  const { startTimestamp } = monitorEvent;
  const { comparisonPeriodType, comparisonPeriodNum } = monitorConfig;
  const axisValues = _(viz?.layout)
    .filter(({ id }) => _.includes(['XAXIS', 'YAXIS'], id))
    .flatMap(({ fields }) => fields)
    .uniq()
    .value();
  const filterArgs = [
    `${comparisonPeriodNum}`,
    ComparisonTimePeriodMapping[comparisonPeriodType] || DefaultPeriod,
    'true',
    moment(startTimestamp).format(moment.ISO8601),
  ];
  if (snapshotDate) {
    _.set(snapshotDate, 'expression.left.operands', filterArgs);
    return {
      'Snapshot Date': snapshotDate,
    };
  } else if (_.find(axisValues, v => /snapshot date/i.test(v))) {
    const snapshotDateAttr = _.find(
      viz?.dataset?.attributes,
      _.matches({
        name: 'Snapshot Date',
      }),
    );
    return {
      'Snapshot Date': DateFilter(
        snapshotDateAttr,
        ['4', ..._.tail(filterArgs)],
        RelativeDateOperators.Past,
      ),
    };
  }
  return {};
};

export const ComparisonMonitorRedirect = () => {
  let func: any = props => {
    if (props?.loading) {
      return <div key={`comp-mon-redirect-${shortid.generate()}`} />;
    }
    const {
      monitorEvent,
      visualizations,
      openVisualization,
      visualization,
    } = props;
    const { id: monitorEventId } = monitorEvent;
    const monitorConfig = JSON.parse(monitorEvent?.monitor?.config ?? null);
    const { group } = JSON.parse(monitorEvent?.payload ?? {});
    const [segment, segmentValue] =
      _(group)
        .toPairs()
        .head() || [];

    const {
      labels: { targetReport },
    } = monitorConfig || {};

    const linkedContent: IVizBase = _.find(
      visualizations,
      _.matches({ id: targetReport }),
    );
    if (linkedContent) {
      let segmentField;
      let filters = {};
      if (segment) {
        segmentField = _.find(
          linkedContent?.dataset?.attributes,
          _.conforms({
            name: name => _.toLower(name) === _.toLower(segment),
          } as any),
        );
      }
      if (segmentField) {
        filters = {
          ...filters,
          [segmentField.name]: InListFilter(segmentField, segmentValue),
        };
      }
      openVisualization(linkedContent);
      const relativeFilters = getDateFiltersForComparisonMonitorRedirect(
        { ...(visualization || {}), ...linkedContent },
        monitorEvent,
        monitorConfig,
      );
      _.assign(filters, relativeFilters);
      return (
        <Navigate
          to={{
            pathname: ROUTER_DIRS.OPEN_VIZ(targetReport),
          }}
          state={{
            filters,
            monitorEventId,
          }}
        />
      );
    }
    return <Navigate to={ROUTER_DIRS.ROOT} />;
  };
  func = compose(
    withProps((ownProps: any) => {
      const monitorConfig = JSON.parse(
        ownProps?.monitorEvent?.monitor?.config ?? null,
      );
      const { labels: { targetReport } = {} as any } = monitorConfig || {};
      return { id: targetReport };
    }),
    graphql(
      gql`
        query getVizLayout($id: String!) {
          visualization(id: $id) {
            layout {
              id
              fields
            }
          }
        }
      `,
      {
        props: (ownProps: any) => {
          const { loading, error, visualization } = ownProps?.data || {};
          return { loading, error, visualization };
        },
      },
    ),
  )(func);
  func.supports = monitorEvent => monitorEvent?.eventType === 'comparison';
  return func;
};
