import { BarUtils } from '../../../common/d3/Bars';
import StackChartUtils from '../StackChartUtils';
import Util from '../../../common/Util';
import _ from 'lodash';
import * as ReactDOM from 'react-dom';
import { ChartTooltipData, ChartTooltipReportLinkNote } from '../chart-tooltip';
import palette from '../../../common/d3/ColorPalette';
import Stacks from '../../../common/d3/Stacks';
import { Viz } from '../../VizUtil';
import { join as joinChartData } from '../../charts/ChartUtils';
import { METRIC_ORIENTATION } from '../../../common/d3/Axis';
import ColorManager from '../../../common/d3/ColorManager';
import { messages } from '../../../i18n';
import { max as d3Max, min as d3Min, scaleBand, scaleLinear } from 'd3';

class StackPlot {
  constructor(parameters) {
    const {
      vizId,
      viz,
      valuesName,
      xAxisName,
      data,
      querySort,
      queryResults,
      legendData,
      layout,
      width,
      height,
      showDataLabels,
      paletteOffset,
      chartPadding,
      presetPadding,
      defaultXAxisHeight,
      hoverFunction,
      labelRotation,
      isMobile,
      defaultYAxisWidth,
      customFormatToggles,
      disableTooltips,
      enableReportLink,
      linkToReport,
      nullHandling,
      focusedData,
      i18nPrefs = {},
      customFormatProps,
      isSecondaryPlot = false,
    } = parameters;
    this.vizId = vizId;
    this.viz = viz;
    this.valuesName = valuesName;
    this.xAxisName = xAxisName;
    this.data = data;
    this.querySort = querySort;
    this.queryResults = queryResults;
    this.legendData = legendData;
    this.layout = layout;
    this.width = width;
    this.height = height;
    this.showDataLabels = showDataLabels;
    this.paletteOffset = paletteOffset;
    this.chartPadding = chartPadding;
    this.defaultXAxisHeight = defaultXAxisHeight;
    this.defaultYAxisWidth = defaultYAxisWidth;
    this.presetPadding = presetPadding;
    this.hoverFunction = hoverFunction;
    this.labelRotation = labelRotation || 'vertical';
    this.isMobile = isMobile;
    this.customFormatToggles = customFormatToggles || [];
    this.scales = this.createScales(this.width);
    this.xScale = this.scales.xScaleDefault;
    this.yScale = this.scales.yScaleDefault;
    this.disableTooltips = disableTooltips;
    this.shouldRotateAxisLabels = this.shouldRotateAxisLabels();
    this.enableReportLink = enableReportLink && focusedData.length <= 1;
    this.linkToReport = linkToReport;
    this.nullHandling = nullHandling;
    this.focusedData = focusedData;
    this.i18nPrefs = i18nPrefs;
    this.customFormatProps = customFormatProps;
    this.isSecondaryPlot = isSecondaryPlot;

    const labelInfo = Util.calcLabelInfo(
      BarUtils.getDistinctGroups(this.data, d => StackChartUtils.getX0(d)),
    );

    this.maxChars = this.shouldRotateAxisLabels ? labelInfo.maxChars : 10;
  }
  // eslint-disable-next-line lodash/prefer-constant
  getType() {
    return 'stacks';
  }

  isActive() {
    return this.layout[this.valuesName].length > 0;
  }

  getYAxisLabel() {
    return Viz.getAxisLabel(this.layout[this.valuesName]);
  }

  getYDomain() {
    return this.yScale.domain();
  }

  setYScale(scale) {
    this.yScale = scale;
  }

  getXAxisLabel() {
    if (!_.isEmpty(this.layout.XAXIS)) {
      return Viz.getAxisLabel(this.layout.XAXIS);
    }
  }

  createScales(width) {
    const { data, height, chartPadding, isMobile } = this;

    const { getX } = StackChartUtils;

    let padProps = BarUtils.getBarPadding(data.length);
    if (!_.isEmpty(chartPadding)) {
      padProps = chartPadding;
    }
    const { barPadding, barPaddingOuter } = padProps;

    const group = scaleBand()
      .domain(data.map(d => getX(d)))
      .range([0, width])
      .paddingInner(barPadding)
      .paddingOuter(barPaddingOuter);

    const x = scaleBand()
      .domain([])
      .range([0, group.bandwidth()]);

    let bounds = data.reduce(
      (accum, current) => {
        const min = d3Min(current.VALUES, v => v.bottom);
        const max = d3Max(current.VALUES, v => v.top);
        accum[0] = Math.min(accum[0], min);
        accum[1] = Math.max(accum[1], max);
        return accum;
      },
      [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER],
    );

    if (this.showAsPercentage()) {
      bounds = [d3Min(bounds) < 0 ? -1 : 0, d3Max(bounds) <= 0 ? 0 : 1];
    } else {
      const bufferVal = isMobile ? 0.1 : 0.08;
      bounds[0] += bounds[0] * bufferVal;
      bounds[1] += bounds[1] * bufferVal;
    }

    const y = scaleLinear()
      .range([height, 0])
      .domain(bounds)
      .nice();

    return {
      groupScaleDefault: group,
      xScaleDefault: x,
      yScaleDefault: y,
    };
  }

  shouldRotateAxisLabels() {
    if (this.getDistinctGroups() > 2) {
      // calculate the distance between two bars
      const distance = this.xScale.step();
      if (distance < 64) {
        // Go Vertical
        return true;
      }
    }
    return false;
  }

  getDistinctGroups() {
    const xAxisValues = this.data.map(d => d.XAXIS);
    return xAxisValues.length;
  }

  getXScale() {
    return this.scales.groupScaleDefault;
  }

  getYScale() {
    return this.yScale;
  }

  setWidth(width) {
    this.width = width;
    this.scales = this.createScales(this.width);
    this.xScale = this.scales.groupScaleDefault;

    this.xScale =
      this.presetPadding && !_.isNil(this.presetPadding.groupPadding)
        ? this.xScale
            .paddingOuter(this.presetPadding.groupPaddingOuter)
            .paddingInner(this.presetPadding.groupPadding)
        : this.xScale;

    this.yScale = this.scales.yScaleDefault;
  }
  setHeight(height) {
    this.height = height;
    this.scales = this.createScales(this.width);
    this.xScale = this.scales.xScaleDefault;
    this.yScale = this.scales.yScaleDefault;
  }
  getPreferredWidth() {
    let adjustedChartWidth = this.width;
    let adjustedXScale = this.scales.xScaleDefault;
    while (
      this.layout[this.valuesName].length > 0 &&
      adjustedXScale.bandwidth() < 18
    ) {
      const currentWidth = adjustedXScale.bandwidth();
      adjustedChartWidth = (18 / currentWidth) * adjustedChartWidth;
      adjustedXScale = this.createScales(adjustedChartWidth).xScaleDefault;
    }

    return adjustedChartWidth;
  }

  getStepSize() {
    return this.scales.groupScaleDefault.step();
  }

  getXAxisHeight() {
    // we need to account for the primary x-axis-label in this height calc as well.
    const xAxisLabelHeight = this.isMobile ? 16 : 24;
    const hasXaxisLabel =
      !_.isEmpty(this.layout.XAXIS) ||
      (_.isEmpty(this.layout.XAXIS) && !_.isEmpty(this.layout.STACK));

    if (this.shouldRotateAxisLabels) {
      const labelInfo = Util.calcLabelInfo(
        BarUtils.getDistinctGroups(this.data, d => StackChartUtils.getX0(d)),
      );
      if (this.labelRotation === 'vertical') {
        return labelInfo.maxWidth + (hasXaxisLabel ? xAxisLabelHeight : 0);
      } else {
        const radians = (35 * Math.PI) / 180;
        const height = Math.sin(radians) * labelInfo.maxWidth;
        return height + (this.isMobile ? 32 : 36);
      }
    }
    return this.defaultXAxisHeight;
  }

  getYAxisWidth() {
    return this.defaultYAxisWidth;
  }

  getPreferredHeight() {
    if (!this.shouldRotateAxisLabels) {
      return this.height;
    }
    return this.height - (this.getXAxisHeight() - this.defaultXAxisHeight);
  }

  getComponent(props) {
    return (
      <Stacks
        isSecondaryPlot={this.isSecondaryPlot}
        className={props.className}
        colorPalette={this._getColorPalette()}
        customFormatToggles={this.customFormatToggles}
        data={this.data}
        enableReportLink={this.enableReportLink}
        focus={props.focus}
        focusedData={this.focusedData}
        getStack={d => StackChartUtils.getStack(d)}
        getStackColor={(dataItem, defaultColor) =>
          this.getStackColor(dataItem, defaultColor)
        }
        groupScale={this.scales.groupScaleDefault}
        height={this.height}
        isMobile={this.isMobile}
        onHover={(data, x, y) => this.hoverFunction(data, 'stacks', x, y)}
        ref={stacks => {
          this.stacks = stacks;
          if (props.plotRef) {
            props.plotRef(stacks);
          }
        }}
        showLabels={props.showLabels}
        vizId={this.vizId}
        width={this.width}
        xScale={this.xScale}
        yScale={this.yScale}
        customFormatProps={this.customFormatProps}
      />
    );
  }

  getComponentRef() {
    return ReactDOM.findDOMNode(this.stacks);
  }

  // eslint-disable-next-line lodash/prefer-constant
  getMeasureOffsetInData() {
    return 0;
  }

  getTooltip(dataItem, formatters, globalTooltip, useFiscalCalendar) {
    // only show tooltip info for focused stack items
    if (
      !_.isEmpty(this.focusedData) &&
      !_.some(this.focusedData, _.pick(dataItem, 'stackName'))
    ) {
      return [];
    }
    const components = [
      <ChartTooltipData
        key={`stackplot-tooltip-data-${dataItem.tooltipInfo}`}
        data={dataItem.tooltipInfo}
        useFiscalCalendar={useFiscalCalendar}
      />,
    ];
    if (this.enableReportLink) {
      components.push(
        <ChartTooltipReportLinkNote name={this.linkToReport.name} />,
      );
    }
    return components;
  }

  _getColorPalette() {
    // if the stack is not horizontal, we want the stack colors to get assigned from top down. so our color palette needs to account for that
    let colorPalette = [...palette];
    // get the number of unique stack elements, trim the palette to that length and then reverse it
    const maxStacks = this.legendData.size;
    colorPalette = colorPalette.slice(0, maxStacks).reverse();
    return colorPalette;
  }

  getStackColor(dataItem) {
    // we only support stack ordinal ordering if there is only a single attribute in the STACK shelf and it has an ordinalAttribute
    const { vizId } = this;
    return ColorManager.getColor(vizId, dataItem.stackName);
  }

  getLegendTitle(isComboComponent = false) {
    if (isComboComponent) {
      if (!_.isEmpty(this.layout.STACK)) {
        const firstParentFieldName = _.get(
          _.head(this.layout.STACK),
          'parentField.name',
          'not populated',
        );
        const stackFields = this.layout?.STACK ?? [];
        const isAllParentField =
          !_.isEmpty(stackFields) &&
          _.every(stackFields, {
            parentField: {
              name: firstParentFieldName,
            },
          });

        if (isAllParentField) {
          return firstParentFieldName;
        }

        return joinChartData(this.layout.STACK.map(f => f.name));
      } else if (!_.isEmpty(this.layout.VALUES)) {
        return messages.stackBar.columnsShelf;
      } else {
        return '';
      }
    }
    if (!_.isEmpty(this.layout.STACK)) {
      return joinChartData(this.layout.STACK.map(f => f.name));
    } else if (!_.isEmpty(this.layout.VALUES)) {
      return messages.stackBar.valuesShelf;
    } else {
      return '';
    }
  }

  getLegendData() {
    // the legend should be a unique list of all of the stack names
    // however, they need to remain in the order we get back from the server
    const { vizId } = this;
    const legendDataSet = new Set();
    const unique = this.legendData;

    unique.forEach(stackName => {
      legendDataSet.add({
        label: stackName,
        shape: 'SQUARE',
        color: ColorManager.getColor(vizId, stackName),
        info: { stackName },
      });
    });
    return legendDataSet;
  }

  globalMouseMove() {
    // Bar has no global tooltip
  }

  getMetricOrientation() {
    return METRIC_ORIENTATION.VERTICAL;
  }

  showAsPercentage() {
    const percentageToggle = this.customFormatToggles.find(
      t => t.key === 'asPercentage',
    );
    const isPercentage = _.isNil(percentageToggle)
      ? false
      : percentageToggle.on;

    return isPercentage;
  }
}

export default StackPlot;
