import { useSelector } from 'react-redux';
import { Fragment, useEffect } from 'react';
import { SidebarNavigation } from '../../discovery/sidebar-navigation';
import { Header } from '../../discovery/header';
import {
  AppBackground,
  MainContentWrapper,
} from '../../ui/app-background/app-background.styles';
import { Outlet, Route, Routes } from 'react-router-dom';
import { ROUTER_DIRS } from '../../common';
import { HeaderBar } from '../../discovery/header/header.styles';
import _ from 'lodash';
import URLs from '../../common/Urls';
import { ReportLinkRedirect } from '../../common/hoc/ReportLinkRedirect';
import NewRelic from '../../common/NewRelic';
import { DiscoverTabs } from '../../discovery/discover-tabs';
import Library from '../../views/Library';
import Util from '../../common/Util';
import MobileLibrary from '../../views/MobileLibrary';
import { CenteredSpinner } from '../../common/widgets/Status';

const SideNavAndHeader = ({ loadPinnedDiscoveries = false }) => {
  const { isMobile = false } = useSelector(({ main }: any) => main);
  return (
    <Fragment>
      {!isMobile && <SidebarNavigation key='mainNav' />}
      <Header loadPinnedDiscoveries={loadPinnedDiscoveries} />
    </Fragment>
  );
};

const WithAppBackground = ({ children }) => {
  const isResponsive = useSelector(
    ({ main: { isMobile = false }, dashlet: { isDashletMode = false } }: any) =>
      isMobile || isDashletMode,
  );

  if (isResponsive) {
    return <>{children}</>;
  }

  return <AppBackground>{children}</AppBackground>;
};

export const WithNav = ({ children }) => {
  return (
    <WithAppBackground>
      <Routes>
        <Route
          path={ROUTER_DIRS.OPEN_VIZ_PATTERN}
          element={<SideNavAndHeader loadPinnedDiscoveries={false} />}
        />
        <Route
          path={'*'}
          element={<SideNavAndHeader loadPinnedDiscoveries={true} />}
        />
      </Routes>
      <MainContentWrapper>{children}</MainContentWrapper>
    </WithAppBackground>
  );
};

export const Shell = ({ children = [] } = {}) => {
  const { appUrl = '' } = useSelector(({ main }: any) => main);

  const logoImgSrc = Util.assetUrl({
    appUrl,
    path: 'discover_logo_modern.svg',
  });

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div id='mainHeader'>
        <HeaderBar>
          <img
            src={logoImgSrc}
            className='logo'
            style={{ marginLeft: '24px' }}
          />
        </HeaderBar>
      </div>
      <div
        className={'mainBackground'}
        style={{ width: '100%', height: '100%' }}
      >
        {_.isEmpty(children) ? <CenteredSpinner /> : children}
      </div>
    </div>
  );
};

export const RedirectReportLink = routeProps => {
  const { drillContextId } = URLs.getQueryParams();
  return <ReportLinkRedirect {...{ ...routeProps, drillContextId }} />;
};

const useIsAuthenticatedView = () => {
  const {
    login: { isLoggedIn } = {},
    dashlet: { isDashletMode } = {},
  } = useSelector(({ dashlet, login }: any) => ({ dashlet, login }));

  return isLoggedIn || isDashletMode;
};

export const AuthenticatedView = () => {
  const isAuthenticated = useIsAuthenticatedView();

  return isAuthenticated ? <Outlet /> : <Shell />;
};

const useTrackedByDomain = domain => {
  useEffect(() => {
    if (domain) {
      NewRelic.addPageAction('main-page-load', ({
        domain,
      } as unknown) as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const TrackedDiscoverView = () => {
  useTrackedByDomain('discover');

  return (
    <div id='discover-content-panel'>
      <DiscoverTabs />
    </div>
  );
};

export const TrackedLibraryView = () => {
  const { isMobile = false } = useSelector(({ main }: any) => main);

  useTrackedByDomain('library');

  return isMobile ? <MobileLibrary /> : <Library />;
};
