import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import * as Fonts from '../../../components/ui/fonts';
import { SortDirection } from 'react-virtualized';
import { isEqual } from 'lodash';
import classnames from 'classnames';
import { SortIndicator } from '../../../icons';

export const DIMENSIONS = {
  HEADER_HEIGHT: 48,
  ROW_HEIGHT: 40,
  LIST_ROW_HEIGHT: 32,
  LIST_WIDTH: 200,
  EDIT_ANNOTATIONS_TABLE_WIDTH: 465,
};

export const Root = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  margin-bottom: 16px;
`;

export const TableRoot = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
`;

export const TableInner = styled.div(
  ({ theme: { colors: { ContentBackground } = {} } = {} }) => css`
    display: flex;
    flex-flow: row nowrap;
    flex: 1;
    background: ${ContentBackground};
  `,
);

export const TableActionToolBar = styled.div(
  ({ theme: { colors: { ContentBackground } = {} } = {} }) => css`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    background: ${ContentBackground};
    padding: 16px 24px;
  `,
);

export const TableEmptyView = styled.div(
  ({ theme: { colors: { TableBorderColor } = {} } = {} }) => css`
    position: absolute;
    top: ${DIMENSIONS.HEADER_HEIGHT}px;
    height: ${DIMENSIONS.HEADER_HEIGHT}px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-bottom: 1px solid ${TableBorderColor};
  `,
);

export const Specs = styled.div`
  font-size: 14px;
  font-weight: ${Fonts.LightFontWeight};
`;

export const BoldText = styled.span`
  font-weight: ${Fonts.RegularFontWeight};
`;

export const Label = styled.span`
  font-size: 14px;
  margin: 0px 8px 0px 24px;
`;

export const LinkButton = styled.button(
  ({ theme: { colors: { ActiveLinkText } = {} } = {} }) => css`
    background: transparent;
    border: none;
    font-size: 14px;
    color: ${ActiveLinkText};
    cursor: pointer;
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  `,
);

export const Header = styled.div`
  font-size: 16px;
  font-weight: ${Fonts.MediumFontWeight};
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const ActionToolBar = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

export const FilterToolBar = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

export const HeaderCell = styled.div(
  ({ theme: { colors: { TableHeaderTextColor } = {} } = {} }) => css`
    font-size: 14px;
    font-weight: ${Fonts.MediumFontWeight};
    color: ${TableHeaderTextColor};
    text-transform: capitalize;
    white-space: nowrap;
    padding: 8px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & .sort-indicator {
      margin-top: 0;
    }
  `,
);

export const BodyCell = styled.div(
  ({ theme: { colors: { CorvanaLightText } = {} } = {} }) => css`
    font-size: 12px;
    font-weight: ${Fonts.LightFontWeight};
    display: flex;
    align-items: center;
    padding: 8px 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    height: 100%;
    &.dim {
      color: ${CorvanaLightText};
      button {
        color: ${CorvanaLightText};
      }
    }
  `,
);

const StyledSortIndicator = styled(SortIndicator)`
  margin-left: 8px;
`;

export const Spinner = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  flex: 1;
  filter: brightness(1) grayscale(1) contrast(2);
  #loader > div {
    position: initial;
  }
`;

export const PreviewList = styled.div(
  ({
    theme: {
      darkMode,
      colors: { TableHeaderBackground, TableBorderColor } = {},
    } = {},
  }) => css`
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    padding: 10px 8px;
    background: ${TableHeaderBackground};
    border-left: 1px solid
      ${darkMode ? TableHeaderBackground : TableBorderColor};
  `,
);
export const List = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
`;
export const Grid = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
`;
export const ListRow = styled.div(
  ({ theme: { colors: { ContentBackground, Strokes } = {} } = {} }) => css`
    font-size: 12px;
    font-weight: ${Fonts.RegularFontWeight};
    padding: 8px;
    background: ${ContentBackground};
    border-bottom: 1px solid ${Strokes};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
);

export const ListHeader = styled(ListRow)(
  ({ theme: { colors: { DataPreviewHeader } = {} } = {} }) => css`
    font-weight: ${Fonts.MediumFontWeight};
    background: ${DataPreviewHeader};
    width: ${DIMENSIONS.LIST_WIDTH}px;
    height: ${DIMENSIONS.LIST_ROW_HEIGHT}px;
  `,
);

export const EditGridHeader = styled(ListRow)(
  ({ theme: { colors: { TableHeaderBackgroundColor } = {} } = {} }) => css`
    font-weight: ${Fonts.MediumFontWeight};
    background: ${TableHeaderBackgroundColor};
    width: ${DIMENSIONS.EDIT_ANNOTATIONS_TABLE_WIDTH}px;
    height: ${DIMENSIONS.LIST_ROW_HEIGHT}px;
  `,
);

const GridCellStyleBase = ({
  theme: { colors: { CorvanaLightText } = {} } = {},
}) => css`
  font-size: 12px;
  font-weight: ${Fonts.LightFontWeight};
  display: flex;
  flex: 1;
  align-items: center;
  padding: 6px;
  height: 32px;
  line-height: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.dim {
    color: ${CorvanaLightText};
    button {
      color: ${CorvanaLightText};
    }
  }
`;

export const GridCell = styled.div(
  ({ theme }) => css`
    ${GridCellStyleBase({ theme })};
    &.isDisabled {
      color: ${theme?.colors?.CorvanaLightText};
    }
  `,
);

export const GridInputCell = styled.div(
  ({ theme }) => css`
    ${GridCellStyleBase({ theme })};
    &.isDisabled {
      color: ${theme?.colors?.CorvanaLightText};
      input {
        background-color: ${theme?.colors?.ContentBackground};
      }
    }
  `,
);

export const TextField = styled.div(
  ({
    theme: {
      colors: { CorvanaLightText, TableInputDisabledBackgroundColor } = {},
    } = {},
  }) => css`
    font-weight: ${Fonts.LightFontWeight};
    &.isDisabled {
      color: ${CorvanaLightText};
      background-color: ${TableInputDisabledBackgroundColor};
      input {
        background-color: ${TableInputDisabledBackgroundColor};
      }
    }
  `,
);

export const GridHeaderCell = styled.div(
  ({ theme: { colors: { HeaderGrey } = {} } = {} }) => css`
    font-size: 12px;
    font-weight: ${Fonts.MediumFontWeight};
    text-transform: capitalize;
    padding: 4px;
    background: ${HeaderGrey};
  `,
);

/*
  Helper function to manually disable tabbing on header elements, current support for optional
  tabIndex value on React Virtualized Table only overrides the inner Grid tabIndex.
*/
const disableTabIndex = ref => {
  if (ref) {
    const node = ReactDOM.findDOMNode(ref);
    const parent = node.parentNode;
    if (parent) {
      parent.setAttribute('tabIndex', '-1');
    }
  }
};

export const HeaderCellRenderer = ({ label }) => {
  return <HeaderCell ref={ref => disableTabIndex(ref)}>{label}</HeaderCell>;
};

export const GridHeaderCellRenderer = ({ label }) => {
  return (
    <GridHeaderCell ref={ref => disableTabIndex(ref)}>{label}</GridHeaderCell>
  );
};

export const HeaderCellRendererWithSortIndicator = ({
  label,
  dataKey,
  sortDirection,
  sortBy,
  allowSortableIcon = true,
}) => {
  return (
    <HeaderCell ref={ref => disableTabIndex(ref)}>
      {label}
      {isEqual(sortBy, dataKey) && (
        <StyledSortIndicator
          sortDirection={sortDirection}
          className={classnames(
            'sort-indicator',
            'sorted',
            isEqual(sortDirection, SortDirection.DESC) ? 'desc' : 'asc',
          )}
        />
      )}
      {allowSortableIcon && !isEqual(sortBy, dataKey) && (
        <StyledSortIndicator className='sort-indicator sortable' />
      )}
    </HeaderCell>
  );
};
