import Const from '../actions/ActionConst';
import { ChartSpecs } from '../../../discovery/ChartSpecs';
import undoable from 'redux-undo';
import _, {
  forEach,
  map,
  get,
  reject,
  isArray,
  isEmpty,
  toPairs,
  find,
  flatten,
  values,
  includes,
  mapValues,
  partial,
  replace,
  isObject,
  last,
  flatMap,
  some,
  isEqual,
  range,
  isNil,
} from 'lodash';
import { composeResetReducer } from 'redux-reset-store';
import { Viz } from '../../../discovery/VizUtil';
import { validateFilters } from '../../../discovery/filter/FilterValidation';
import { validateSlicers } from '../../../discovery/slicer/util';
import { ShelfTypes } from '../../../discovery/interfaces';
import { parseJSON } from '../../../common/Util';
import { Labels, Types, USE_FISCAL_REPORTING } from '../../Constants';
import DateFilter from '../../../discovery/filter/exports/DateFilter';
import { makeUniqueFieldName } from '../../../discovery/charts/waterfall';
import { FieldMetadata } from '../../../datasets';
import { shortid } from '../../utilities/shortid-adapter';

const removeFieldFromShelf = (layout, shelf, field) => {
  const shelfFields = layout[shelf] ? [...layout[shelf]] : [];
  const removed = shelfFields.filter(f => {
    return f.name !== field.name;
  });
  return removed;
};
const updateMissingFields = (viz, field) => {
  return _.filter(viz.missingFields, f => !_.isEqual(f.name, field.name));
};
const updateMissingFilters = (viz, field) => {
  return _.filter(viz.missingFilters, f => !_.isEqual(f.field, field.name));
};
const addFieldToShelf = (layout, shelf, field, index) => {
  const shelfFields = layout[shelf] ? [...layout[shelf]] : [];
  let added = [];
  if (_.isUndefined(index) || index === shelfFields.length) {
    // just put it at the end
    added = [...shelfFields, field];
  } else {
    const before = shelfFields.slice(0, index);
    const after = shelfFields.slice(index);
    added = [...before, field, ...after];
  }
  return added;
};
const updateSlicerSelections = (viz, shelf, field) => {
  const { name } = field;
  let slicerSelections = viz?.options?.slicerSelections;
  if (shelf === 'SLICER' && slicerSelections) {
    slicerSelections = _(slicerSelections)
      .thru(JSON.parse)
      .reject({ name })
      .thru(JSON.stringify)
      .value();
  }
  return slicerSelections;
};

export const updateShelfDependentCustomFormatToggles = (viz, field) => {
  const customFormatToggles = Viz.getCustomFormatTogglesFromViz(viz);
  const rowSubtotals = customFormatToggles.find(t => t.key === 'rowSubtotals');
  let updated = [...customFormatToggles];

  // remove field reference from row subtotals
  const subtotalOptions = _.get(rowSubtotals, 'options', {});
  let subtotalFields = _.get(rowSubtotals, 'options.fields', []);
  if (_.isArray(subtotalFields)) {
    subtotalFields = subtotalFields.filter(
      fieldName => fieldName !== field.name,
    );

    updated = updated.filter(toggle => toggle.key !== 'rowSubtotals');

    updated.push({
      ...rowSubtotals,
      options: {
        ...subtotalOptions,
        fields: [...subtotalFields],
      },
    });
  }
  return updated;
};

export const OpenDiscoveryInternalReducer = composeResetReducer(
  (state = {}, action) => {
    const open = state;

    switch (action.type) {
      case Const.Discover.SET_OPEN_DISCOVERY_STATE:
        return { ...state, ...action.discovery };
      case Const.Discover.CHANGE_RANGE_SELECTION:
        return { ...state, rangeSelection: action.rangeSelection };
      case Const.Discover.CHANGE_DISPLAY_RANGE:
        return { ...state, displayDateRange: action.displayDateRange };
      case Const.Discover.CHANGE_THRESHOLD:
        return { ...state, threshold: action.threshold };
      case Const.Discover.CHANGE_DISCOVERY_NAME:
        return {
          ...state,
          name: action.name,
          viz: { ...state.viz, name: action.name },
        };
      case Const.Discover.SET_DISCOVERY_TAGS: {
        return { ...state, tags: action.tags };
      }
      case Const.Discover.SET_CONFIG_PANEL_DETAIL: {
        return {
          ...open,
          viz: {
            ...open.viz,
            options: {
              ...open.viz.options,
              configPanelDetail: action.detail,
            },
          },
        };
      }
      case Const.Discover.SET_PANEL_DETAIL: {
        return {
          ...open,
          viz: {
            ...open.viz,
            options: {
              ...open.viz.options,
              showPanel: !_.isNil(action.detail),
              panelDetail: action.detail,
              insightsChecked: action.detail === Labels.DISCOVERY_INSIGHTS,
            },
          },
        };
      }
      case Const.Discover.ADD_FIELD_TO_VIZ: {
        const layout = { ...open.viz.layout };
        layout[action.shelf] = addFieldToShelf(
          layout,
          action.shelf,
          action.field,
          action.index,
        );
        const queryId = shortid.generate();
        return {
          ...state,
          viz: {
            ...open.viz,
            layout,
            queryId,
            layoutMapping: {},
          },
        };
      }
      case Const.Discover.REMOVE_FIELD_FROM_VIZ: {
        const removeLayout = { ...open.viz.layout };
        removeLayout[action.shelf] = removeFieldFromShelf(
          removeLayout,
          action.shelf,
          action.field,
        );
        const removeQueryId = shortid.generate();

        let customFormatToggles = _.get(
          open,
          'viz.options.customFormatToggles',
          [],
        );
        const slicerSelections = updateSlicerSelections(
          open.viz,
          action.shelf,
          action.field,
        );

        // If the field is being moved out of a shelf, make sure its also removed from any subtotal options
        const updatedToggles = updateShelfDependentCustomFormatToggles(
          open.viz,
          action.field,
        );
        customFormatToggles = [...updatedToggles];

        const stringToggles = _.isString(customFormatToggles)
          ? customFormatToggles
          : JSON.stringify(customFormatToggles);

        const missingFields = updateMissingFields(open.viz, action.field);

        const fieldMetadata = Viz.getAllFieldMeta(open?.viz);
        if (fieldMetadata[action.field.name]) {
          delete fieldMetadata[action.field.name];
        }

        return {
          ...state,
          viz: {
            ...open.viz,
            layout: removeLayout,
            queryId: removeQueryId,
            layoutMapping: {},
            options: {
              ...open.viz.options,
              ...fieldMetadata,
              slicerSelections,
              customFormatToggles: stringToggles,
            },
            missingFields,
          },
        };
      }

      case Const.Discover.RENAME_FIELD_IN_SHELF: {
        const renameLayout = { ...open.viz.layout };
        const shelfFields = _.get(renameLayout, action.shelf, []);
        const positionOfField = shelfFields.findIndex(
          f => f.name === action.previousName,
        );
        if (positionOfField > -1) {
          const fields = [...shelfFields];
          fields.splice(positionOfField, 1, action.field);
          renameLayout[action.shelf] = [...fields];
          const renameQueryId = shortid.generate();
          return {
            ...state,
            viz: {
              ...open.viz,
              layout: renameLayout,
              queryId: renameQueryId,
              layoutMapping: {},
            },
          };
        }
        return state;
      }
      case Const.Discover.MOVE_FIELD_TO_ANOTHER_SHELF: {
        const moveLayout = { ...open.viz.layout };

        const beforeRmIdx = moveLayout[action.fromShelf].findIndex(
          f => f.name === action.field.name,
        );
        const removed = removeFieldFromShelf(
          moveLayout,
          action.fromShelf,
          action.field,
        );
        moveLayout[action.fromShelf] = removed;
        const slicerSelections = updateSlicerSelections(
          open.viz,
          action.fromShelf,
          action.field,
        );

        let { index } = action;
        // if moving in the same shelf, and we are moving the item down, adjust the index since we just removed one
        if (action.fromShelf === action.toShelf && index > beforeRmIdx) {
          index--;
        }

        const added = addFieldToShelf(
          moveLayout,
          action.toShelf,
          action.field,
          index,
        );
        moveLayout[action.toShelf] = added;

        const moveQueryId = shortid.generate();

        let customFormatToggles = _.get(
          open,
          'viz.options.customFormatToggles',
          [],
        );

        // If the field is being moved out of a shelf, make sure its also removed from any subtotal options
        if (action.fromShelf !== action.toShelf) {
          const updatedToggles = updateShelfDependentCustomFormatToggles(
            open.viz,
            action.field,
          );
          customFormatToggles = [...updatedToggles];
        }

        const stringToggles = _.isString(customFormatToggles)
          ? customFormatToggles
          : JSON.stringify(customFormatToggles);

        return {
          ...state,
          viz: {
            ...open.viz,
            queryId: moveQueryId,
            layout: moveLayout,
            options: {
              ...open.viz.options,
              slicerSelections,
              customFormatToggles: stringToggles,
            },
          },
        };
      }
      case Const.Discover.UPDATE_DISCOVERY_WITH_VERSION: {
        const viz = { ...action.visualization };
        const hydratedViz = Viz.rehydrateViz(viz);
        const { name, tags } = hydratedViz;
        return {
          ...state,
          name,
          tags: [...tags],
          viz: hydratedViz,
          loading: false,
          error: null,
        };
      }
      case Const.Discover.UPDATE_DISCOVERY_WITH_VIZ: {
        const viz = { ...action.visualization };
        const hydratedViz = Viz.rehydrateViz(viz);
        return { ...state, viz: hydratedViz, loading: false, error: null };
      }
      case Const.Discover.UPDATE_DISCOVERY_WITH_ERROR: {
        return { ...state, error: action.error, loading: false };
      }
      case Const.Discover.SET_SETTING_FOR_VIZ: {
        const viz = { ...open.viz };
        let { options } = viz;
        const { setting } = action;
        let { value } = action;
        options = { ...options, ...action.props };
        if (!_.isString(value)) {
          value = JSON.stringify(value);
        }

        return {
          ...open,
          viz: { ...viz, options: { ...options, [setting]: value } },
        };
      }
      case Const.Discover.CHANGE_VIZ_TYPE: {
        const viz = { ..._.cloneDeep(open.viz) };

        const savedLayout =
          viz.layoutMapping[`${viz.chartType}-${action.chartType}`];
        if (viz.chartType === action.chartType) {
          // Do nothing
          return state;
        }
        if (!_.isNil(savedLayout)) {
          // Use stored layout
          viz.layout = savedLayout;
        } else {
          // Generate new viz layout
          ChartSpecs[action.chartType].copyLayoutToViz(viz, action.chartType);
          viz.layoutMapping[`${viz.chartType}-${action.chartType}`] = {
            ...viz.layout,
          };
        }
        const vizFilters = Viz.getFiltersFromViz(viz);
        let cachedVizFilters = open.cachedVizFilters ?? vizFilters;

        const existedCurrentSnapshotFilter = vizFilters['Current Snapshot'];

        const snapshotDateField = _.find(
          viz.dataset.attributes,
          a => _.lowerCase(a.name) === _.lowerCase('Snapshot Date'),
        );
        const existedSnapshotDateFilter = vizFilters['Snapshot Date'];
        const defaultSnapshotDataFilter = JSON.parse(
          JSON.stringify(
            DateFilter(
              snapshotDateField,
              ['7', 'DAYS', 'false'],
              'thisAndPast',
            ),
          ),
        );
        const isExistedSnapshotDateEqualDefault = Viz.isTwoFiltersExpressionEqual(
          existedSnapshotDateFilter ?? {},
          defaultSnapshotDataFilter,
        );

        if (action.chartType === ChartSpecs.waterfall.id) {
          let filters = vizFilters;
          if (existedCurrentSnapshotFilter) {
            cachedVizFilters = _.cloneDeep(vizFilters);
            filters = _.omit(vizFilters, 'Current Snapshot');
          }
          if (existedSnapshotDateFilter) {
            if (!isExistedSnapshotDateEqualDefault) {
              viz.options.filters = JSON.stringify({
                ...filters,
                [snapshotDateField.name]: existedSnapshotDateFilter,
              });
            }
          } else {
            viz.options.filters = JSON.stringify({
              ...filters,
              [snapshotDateField.name]: defaultSnapshotDataFilter,
            });
          }
        } else {
          const filters = isExistedSnapshotDateEqualDefault
            ? _.omit(vizFilters, 'Snapshot Date')
            : vizFilters;
          if (isExistedSnapshotDateEqualDefault) {
            viz.options.filters = JSON.stringify({
              ...(!existedCurrentSnapshotFilter ? cachedVizFilters : {}),
              ...filters,
            });
          }
        }

        return {
          ...state,
          focusedData: [],
          focusedDataPoints: [],
          collectDetailInfoArgs: null,
          cachedVizFilters,
          viz: {
            ...viz,
            queryId: shortid.generate(),
            chartType: action.chartType,
          },
        };
      }
      case Const.Discover.REORDER_VIZ_SHELF: {
        const reorderLayout = { ...open.viz.layout };

        reorderLayout[action.shelfId] = action.shelfFields;
        return {
          ...state,
          viz: {
            ...open.viz,
            queryId: shortid.generate(),
            layout: reorderLayout,
          },
        };
      }
      case Const.Discover.SET_VIZ_ACCESS: {
        const viz = { ...open.viz };
        const isPrivate = action.access !== 'public';
        viz.isPrivate = isPrivate;
        return { ...state, viz };
      }
      case Const.Discover.SORT_VIZ_DATA: {
        const viz = { ...open.viz };
        const { layout: sortLayout } = state.viz;
        const options = { ...state.viz.options };
        const fieldName = action.field.name;
        const shelfName = action.shelf.name;
        const { shelfFields } = action;
        let querySort;

        const chartSpec = ChartSpecs[viz.chartType];
        const metricShelfNames = _(chartSpec.shelves)
          .values()
          .filter({ shelfType: ShelfTypes.MEASURE })
          .map('name')
          .value();

        try {
          querySort = JSON.parse(options.querySort);
        } catch (e) {
          querySort = {};
        }

        const sortAlreadyExists = !!_.find(querySort, {
          direction: action.direction,
          shelfName,
          fieldName,
        });

        if (action.shelf.shelfType === ShelfTypes.MEASURE) {
          querySort = _.omitBy(querySort, value => {
            return _(['x-axis', 'y-axis', ...metricShelfNames])
              .map(_.toLower)
              .includes(_.toLower(value.shelfName));
          });
        }

        if (_.includes(['x-axis', 'y-axis'], _.toLower(shelfName))) {
          querySort = _.omitBy(querySort, value => {
            return _(metricShelfNames)
              .map(_.toLower)
              .includes(_.toLower(value.shelfName));
          });
        }

        let queryList = _.values(querySort);
        queryList = _.sortBy(queryList, ['priority']);
        queryList = _.map(queryList, (item, index) => {
          return { ...item, priority: index + 1 };
        });
        querySort = _.keyBy(queryList, 'fieldName');

        let lastSortPriority = _.reduce(
          querySort,
          (result, value) => {
            return Math.max(result, value.priority);
          },
          0,
        );

        if (action.direction === 'remove_sort' || sortAlreadyExists) {
          shelfFields.map(item => {
            delete querySort[item.name];
          });
        } else {
          shelfFields.forEach(item => {
            querySort[item.name] = {
              direction: action.direction,
              shelfName,
              fieldName: item.name,
              priority: ++lastSortPriority,
            };
          });
        }

        // always make these last
        _(querySort)
          .filter({ shelfName: 'stackBar.stackShelf' })
          .forEach(item => {
            item.priority = item.priority + ++lastSortPriority;
          });

        // cleanup bad sorts as a precaution
        const layoutNames = _.uniq(
          _.reduce(
            sortLayout,
            (acc, val) => {
              const keys = _.map(val, 'name');
              acc = acc.concat(keys);
              return acc;
            },
            [],
          ),
        );
        const queryNames = _.keys(querySort);
        const diff = _.difference(queryNames, layoutNames);

        querySort = _.omitBy(querySort, (item, key) => {
          return _.includes(diff, key);
        });

        options.querySort = JSON.stringify(querySort);

        return {
          ...open,
          viz: { ...viz, options: { ...options } },
        };
      }

      case Const.Discover.SET_SHOW_CUSTOM_FORMAT_OPTION: {
        const { viz } = open;
        const { options } = viz;
        const toggles = Viz.getCustomFormatTogglesFromViz(viz);

        const toggleSetting = toggles.find(t => t.key === action.name);
        const applyOptions = !_.isNil(action.options);

        if (_.isNil(toggleSetting)) {
          const toggle = { key: action.name, on: action.checked };
          if (applyOptions) {
            toggle.options = { ...action.options };
          }
          toggles.push(toggle);
        } else {
          toggleSetting.on = action.checked;
          if (applyOptions) {
            toggleSetting.options = {
              ...toggleSetting.options,
              ...action.options,
            };
          }
        }

        // behavior for specific custom format options
        let focusedDataPoints = open.focusedDataPoints ?? [];
        if (action.name === 'enableReportLink' && !toggleSetting.on) {
          focusedDataPoints = [];
        }

        const stringToggles = JSON.stringify(toggles);
        return {
          ...open,
          focusedDataPoints,
          viz: {
            ...viz,
            options: { ...options, customFormatToggles: stringToggles },
          },
        };
      }

      case Const.Discover.SET_FUNNEL_STAGE_VISIBILITY_OPTIONS: {
        const { viz = {} } = open;

        const { options = {} } = viz;

        const funnelStageVisibility = JSON.stringify(action.stageOptions);

        return {
          ...open,
          viz: {
            ...viz,
            options: { ...options, funnelStageVisibility },
          },
        };
      }

      case Const.Discover.BULK_FILTER_UPDATE: {
        const viz = { ...open.viz };
        const { options } = viz;
        const { clearFields, newFilters } = action;
        const filters = _.omit(Viz.getFiltersFromViz(viz), clearFields);
        _.forEach(newFilters, filter => {
          filters[filter.field] = { ...filter };
        });
        const stringFilters = JSON.stringify(filters);
        return {
          ...open,
          viz: { ...viz, options: { ...options, filters: stringFilters } },
        };
      }
      case Const.Discover.APPLY_VIZ_FIELD_FILTER: {
        // filters are stored as a JSON string, handle accordingly
        const viz = { ...open.viz };
        const { options } = viz;
        const filters = Viz.getFiltersFromViz(viz);
        filters[action.filter.field] = { ...action.filter };
        const stringFilters = JSON.stringify(filters);
        return {
          ...open,
          viz: { ...viz, options: { ...options, filters: stringFilters } },
        };
      }
      case Const.Discover.REMOVE_VIZ_FIELD_FILTER: {
        // filters are stored as a JSON string, handle accordingly
        const viz = { ...open.viz };
        const options = { ...viz.options };

        const filters = Viz.getFiltersFromViz(viz);
        const missingFilters = updateMissingFilters(open.viz, {
          name: action.filter.field,
        });
        const cachedVizFilters = _.omit(
          open.cachedVizFilters,
          action.filter.field,
        );
        // Remove filter item
        if (!_.isUndefined(filters[action.filter.field])) {
          delete filters[action.filter.field];
        }
        if (Object.keys(filters).length === 0) {
          // Remove filter option if there are no filters
          delete options.filters;

          return {
            ...open,
            cachedVizFilters,
            viz: { ...viz, options: { ...options }, missingFilters },
          };
        } else {
          const stringFilters = JSON.stringify(filters);
          return {
            ...open,
            cachedVizFilters,
            viz: {
              ...viz,
              options: { ...options, filters: stringFilters },
              missingFilters,
            },
          };
        }
      }

      /*
       * Applies filters and slicers from a drill link/deep link. Validates the remaining fields.
       * action:
       *   filters: IAnyAttribute[]
       *   prependFilters?: IAnyAttribute[]
       *   slicers: IAttribute[]
       *   slicerSelections: ISlicerOption[]
       *   removeFiltersOnFields?: string[]
       *   calcs?: IAnyAttribute[]
       *   metrics?: IAnyAttribute[]
       *   toShelves?: FieldsToShelves
       * */
      case Const.Discover.APPLY_VIZ_STATE: {
        // filters are stored as a JSON string, handle accordingly
        let viz = _.cloneDeep(open.viz);

        if (isNil(viz)) {
          // state not fully initialized
          return state;
        }

        let currentFilters = Viz.getFiltersFromViz(viz);
        let currentSlicers = Viz.getSlicers(viz);
        let currentSlicerSelections = Viz.getSlicerSelections(viz);
        const updatingSlicers = action.slicers ?? [];
        const updatingSlicerNames = _.map(updatingSlicers, 'name') ?? [];
        const updatingSlicerSelections = action.slicerSelections ?? [];
        const updatingFilters = action.filters ?? [];
        const {
          [USE_FISCAL_REPORTING]: vizFiscalCalendar = false,
        } = Viz.getFiscalCalendarInfo(viz);
        const useFiscalCalendar = action.useFiscalCalendar ?? vizFiscalCalendar;

        let filters = {};
        let slicerSelections = [];
        // remove filters on specified fields
        const fieldNamesNotIn = (_fieldsWithName, _names) =>
          _.filter(
            _fieldsWithName,
            _field => !_.includes(_names, _field?.name),
          );
        if (
          !_.isNil(action.removeFiltersOnFields) &&
          action.removeFiltersOnFields.length === 1 &&
          action.removeFiltersOnFields[0] === '__ALL__'
        ) {
          currentFilters = {};
          currentSlicers = [];
          currentSlicerSelections = [];
        } else {
          action.removeFiltersOnFields.forEach(fieldName => {
            delete currentFilters[fieldName];
          });
          currentSlicerSelections = fieldNamesNotIn(
            currentSlicerSelections,
            updatingSlicerNames,
          );
        }

        let targetVizCalcs = Viz.getCalcsFromViz(viz) ?? [];

        // could have better flag. Waterfall is the only chart right now with action.calcs
        const drillingFromWaterfall =
          isArray(action.calcs) && !isEmpty(action.calcs);

        if (drillingFromWaterfall) {
          const fields = Viz.getAllAvailableFields(viz) ?? [];

          const existingFieldNames = new Set(map(fields, 'name'));

          const fieldMetadata = Viz.getAllFieldMeta(open?.viz);

          const existingFieldsInShelves = map(
            flatMap(values(open?.viz?.layout)),
            'name',
          );
          const drillingCalcNames = map(action.calcs, 'name');

          // filter existing calcs and run side effects
          targetVizCalcs = reject(targetVizCalcs, ({ name }) => {
            if (
              includes(drillingCalcNames, name) ||
              get(
                fieldMetadata,
                `${name}.${FieldMetadata.WATERFALL_DRILL_CALC}`,
                false,
              )
            ) {
              if (includes(existingFieldsInShelves, name)) {
                // remove field from shelf
                const shelfId = Viz.findShelfContainingField(viz.layout, name);
                if (shelfId) {
                  // update viz object, as it can be modified later
                  viz = {
                    ...viz,
                    layout: {
                      ...viz.layout,
                      [shelfId]: removeFieldFromShelf(viz.layout, shelfId, {
                        name,
                      }),
                    },
                  };
                }

                if (existingFieldNames.has(name)) {
                  existingFieldNames.delete(name);
                }
                delete fieldMetadata[name];
              }
              return true;
            }
            return false;
          });

          // grouping to avoid calcs with duplicate names
          const groupedCalcs = _.groupBy(action.calcs, ({ name }) =>
            existingFieldNames[name] ? 'rename' : 'valid',
          );

          const newCalcs = [];
          newCalcs.push(...(groupedCalcs.valid ?? []));

          const renamedCalcs = map(groupedCalcs.rename ?? [], _calc => {
            const newName = makeUniqueFieldName(
              Array.from(existingFieldNames),
              _calc.name,
            );

            return [
              _calc.name,
              {
                ..._calc,
                name: newName,
              },
            ];
          });

          forEach(renamedCalcs, ([oldName, _calc]) => {
            const hasToShelvesReference =
              !isEmpty(action.toShelves) &&
              includes(map(flatten(values(action.toShelves)), 'name'), oldName);

            if (hasToShelvesReference) {
              action.shelves = mapValues(action.toShelves, fieldNames => {
                if (!includes(fieldNames, oldName)) {
                  return fieldNames;
                }

                return map(fieldNames, partial(replace, oldName, _calc.name));
              });
            }
          });

          newCalcs.push(...map(renamedCalcs, last));

          forEach(newCalcs, _calc => {
            fieldMetadata[_calc.name] = {
              ...(fieldMetadata[_calc.name] ?? {}),
              [FieldMetadata.WATERFALL_DRILL_CALC]: true,
            };
          });

          targetVizCalcs.push(...newCalcs);

          // update viz object, as it can be modified later
          viz = {
            ...viz,
            options: {
              ...viz.options,
              calcFields: JSON.stringify(targetVizCalcs),
            },
          };
        }

        if (validateFilters(viz, _.values(updatingFilters))) {
          if (!_.isEmpty(currentFilters)) {
            // merge filters
            filters = { ...currentFilters, ...updatingFilters };
            if (action.prependFilters) {
              const newFilterKeys = _.keys(updatingFilters);
              const currentFilterKeys = _.keys(currentFilters);
              const diff = _.difference(currentFilterKeys, newFilterKeys);
              const prepended = {};
              newFilterKeys.concat(diff).forEach(key => {
                prepended[key] = filters[key];
              });
              filters = prepended;
            }
          } else {
            filters = { ...updatingFilters };
          }
        } else {
          filters = { ...currentFilters };
        }

        if (validateSlicers(viz, updatingSlicers, updatingSlicerSelections)) {
          if (!_.isEmpty(currentSlicers)) {
            const preservedSlicers = fieldNamesNotIn(
              currentSlicers,
              updatingSlicerNames,
            );
            const preservedSlicersNames = _.map(preservedSlicers, 'name');
            const newSlicers =
              fieldNamesNotIn(updatingSlicers, _.map(currentSlicers, 'name')) ??
              [];

            const replacedSlicers = _.reduce(
              currentSlicers,
              (acc, _slicer) => {
                if (!_.includes(preservedSlicersNames, _slicer?.name)) {
                  // current slicer is being replaced
                  return [
                    ...acc,
                    _.find(updatingSlicers, { name: _slicer?.name }),
                  ];
                }
                return [...acc, _slicer];
              },
              [],
            );

            viz.layout.SLICER = [...replacedSlicers, ...newSlicers];
          } else {
            viz.layout.SLICER = [...updatingSlicers];
          }

          slicerSelections = [
            ...currentSlicerSelections,
            ...updatingSlicerSelections,
          ];
        }

        // add any metrics
        if (action.metrics.length > 0) {
          const { layout } = viz;
          const allFieldsInPlay = Viz.getAllFieldsInPlay(layout);
          const valueShelves = Viz.getValueShelves(viz);
          const fields = Viz.getAllAvailableFields(viz);

          const existingFieldNames = new Set(map(fields, 'name'));

          // default to first available value shelf
          const shelfId = valueShelves.length > 0 ? valueShelves[0].id : '';
          if (!_.isNil(layout[shelfId])) {
            const existingFields = [...layout[shelfId]];
            const validMetrics = action.metrics.filter(
              m =>
                existingFieldNames.has(m.name) &&
                !find(allFieldsInPlay, { name: m.name }),
            );
            layout[shelfId] = [...existingFields, ...validMetrics];
            viz = { ...viz, layout, layoutMapping: {} };
          }
        }

        if (isObject(action.toShelves) && !isEmpty(action.toShelves)) {
          const availableFields = Viz.getAllAvailableFields(viz);
          forEach(toPairs(action.toShelves), ([shelfId, fieldNames]) => {
            forEach(fieldNames, fieldName => {
              const field = find(availableFields, { name: fieldName });
              if (field) {
                viz.layout[shelfId] = addFieldToShelf(
                  viz.layout,
                  shelfId,
                  field,
                );
              }
            });
          });
        }

        const shelves = _.keys(viz.layout);
        const fieldSelectors = flatMap(shelves, shelfId => {
          return map(
            range(0, viz.layout[shelfId]?.length),
            idx => `layout[${shelfId}][${idx}].name`,
          );
        });

        viz = {
          ...viz,
          options: {
            ...viz.options,
            [USE_FISCAL_REPORTING]: JSON.stringify(useFiscalCalendar),
            filters: JSON.stringify(filters),
            slicerSelections: JSON.stringify(slicerSelections),
          },
        };

        // fire change if one actually happened
        if (
          some(
            [
              ...fieldSelectors,
              `options.${USE_FISCAL_REPORTING}`,
              'options.filters',
              'options.calcFields',
              'options.slicerSelections',
            ],
            selector => !isEqual(get(viz, selector), get(open.viz, selector)),
          )
        ) {
          // note that the `viz` variable could have been modified above and may not be the original object
          return {
            ...open,
            insightMode: true, // flag to differentiate when viz has been modified programmatically
            viz,
          };
        }

        // don't fire change
        return state;
      }
      case Const.Discover.SAVE_CALC_FIELD: {
        // calcs are stored as a JSON string, handle accordingly
        const viz = { ...open.viz };
        let calcs = Viz.getCalcsFromViz(viz);
        let insertionIndex = calcs.length;
        if (!_.isNil(action.previousName)) {
          insertionIndex = calcs.findIndex(c => c.name === action.previousName);

          // if a rename occurred, go find any other calc that references the old name and update it to the new one
          calcs.forEach(calc => {
            if (
              !_.isNil(calc.formula) &&
              _.includes(calc.formula, `[${action.previousName}]`)
            ) {
              const updated = _.replace(
                calc.formula,
                `[${action.previousName}]`,
                `[${action.field.name}]`,
              );
              calc.formula = updated;
            }
          });
        }
        if (insertionIndex > -1) {
          calcs.splice(insertionIndex, 1, action.field);
        }
        calcs = Viz.hydrateTimeHierarchyFields({
          datasetAttributes: [],
          calcFields: calcs,
          timeHierarchies: [],
        }).fields;
        calcs = _.flatMap(calcs, calc => [
          calc,
          ..._.get(calc, 'children', []),
        ]);

        const stringCalcs = JSON.stringify(calcs);
        return {
          ...open,
          viz: { ...viz, options: { ...viz.options, calcFields: stringCalcs } },
        };
      }
      case Const.Discover.DELETE_CALC_FIELDS: {
        // calcs are stored as a JSON string, handle accordingly
        const deleteCalcViz = { ...open.viz };
        const deleteCalcOptions = deleteCalcViz.options;
        let deleteCalcs = _.isNil(deleteCalcOptions.calcFields)
          ? []
          : deleteCalcOptions.calcFields;
        if (_.isString(deleteCalcs)) {
          try {
            deleteCalcs = JSON.parse(deleteCalcs);
          } catch (error) {
            console.warn(
              'Could not parse calcFields',
              deleteCalcOptions.calcFields,
            );
          }
        }
        if (!_.isEmpty(deleteCalcs)) {
          action.fields.forEach(f => {
            const deleteIndex = deleteCalcs.findIndex(c => c.name === f.name);
            if (deleteIndex !== -1) {
              deleteCalcs.splice(deleteIndex, 1);
            }

            // Remove from shelves
            Object.keys(deleteCalcViz.layout).forEach(shelf => {
              deleteCalcViz.layout[shelf] = deleteCalcViz.layout[shelf].filter(
                attr => attr.name !== f.name,
              );
            });
          });
        }

        const stringCalcs = JSON.stringify(deleteCalcs);
        return {
          ...open,
          viz: {
            ...deleteCalcViz,
            options: { ...deleteCalcOptions, calcFields: stringCalcs },
          },
        };
      }
      case Const.Discover.UPDATE_FIELD_IN_VIZ: {
        const updateLayout = { ...open.viz.layout };
        const { previousName, field, fieldMeta: _fieldMeta = {} } = action;
        // find the field in the shelves based on the previous name (it might have changed)
        let foundShelf = null;
        let foundShelfKey = null;
        let foundFieldIndex = -1;
        Object.entries(updateLayout).forEach(entry => {
          const shelf = entry[1];
          const shelfKey = entry[0];
          if (!_.isEmpty(shelf)) {
            shelf.forEach((f, i) => {
              if (f.name === previousName) {
                foundShelf = [...shelf];
                foundShelfKey = shelfKey;
                foundFieldIndex = i;
              }
            });
          }
        });
        if (foundShelf !== null && foundFieldIndex >= 0) {
          foundShelf[foundFieldIndex] = field;
          updateLayout[foundShelfKey] = [...foundShelf];
        }

        const openVizState = {
          ...state,
          viz: {
            ...open.viz,
            queryId: shortid.generate(),
            layout: updateLayout,
          },
        };

        const fieldMetadata = Viz.getAllFieldMeta(openVizState?.viz);

        if (_.isBoolean(get(_fieldMeta, FieldMetadata.IS_DECREASE))) {
          fieldMetadata[field.name] = {
            ...(fieldMetadata[field.name] ?? {}),
            isDecrease: get(_fieldMeta, FieldMetadata.IS_DECREASE),
          };
        }

        if (!_.isEmpty(fieldMetadata)) {
          openVizState.viz.options.fieldMetadata = fieldMetadata;
        }

        if (field?.calcType === Types.TIMESTAMP) {
          const timeCalcs = Viz.createTimeCalcFieldsForViz(openVizState?.viz);
          openVizState.viz.options.timeHierarchies = JSON.stringify(timeCalcs);
        }

        return openVizState;
      }
      case Const.Discover.SHOW_TIME_HIERARCHY: {
        const timeViz = { ...open.viz };
        const timeVizOptions = timeViz.options;
        let timeHierarchies = _.isNil(timeVizOptions.timeHierarchies)
          ? {}
          : timeVizOptions.timeHierarchies;

        if (_.isString(timeHierarchies)) {
          try {
            timeHierarchies = JSON.parse(timeHierarchies);
          } catch (error) {
            console.warn(
              'Could not parse timeHierarchies',
              timeVizOptions.timeHierarchies,
            );
          }
        }
        const { timeHierarchyFields } = action;
        if (_.isEmpty(timeHierarchyFields)) {
          // we are removing the hierarchy all together
          delete timeHierarchies[action.field.name];
        } else {
          timeHierarchies[action.field.name] = [...timeHierarchyFields];
        }
        const visibleTimeHierarchyLevels = Object.entries(
          timeHierarchies,
        ).reduce((accum, [key, fields]) => {
          accum[key] = fields
            .filter(f => !f.hidden)
            .map(f => f.timeAttribute.key);
          return accum;
        }, {});
        const stringTimeHierarchies = JSON.stringify(timeHierarchies);
        const currentExpandTimeFields = _.get(open, 'expandTimeFields', []);
        return {
          ...open,
          expandTimeFields: [...currentExpandTimeFields, action.field.name],
          viz: {
            ...timeViz,
            options: {
              ...timeVizOptions,
              timeHierarchies: stringTimeHierarchies,
              visibleTimeHierarchyLevels,
            },
          },
        };
      }
      case Const.Discover.CLEAR_VIZ_TARGETS: {
        const targetViz = { ...open.viz };
        const targetVizOptions = { ...targetViz.options };
        return {
          ...open,
          viz: {
            ...targetViz,
            options: {
              ...targetVizOptions,
              selectedTargets: '[]',
            },
          },
        };
      }
      case Const.Discover.SELECT_VIZ_TARGET: {
        const targetViz = { ...open.viz };
        const targetVizOptions = { ...targetViz.options };
        let selectedTargets = [];
        if (_.isString(targetVizOptions.selectedTargets)) {
          try {
            selectedTargets = JSON.parse(targetVizOptions.selectedTargets);
          } catch (error) {
            console.warn(
              'Could not parse selectedTargets',
              targetVizOptions.selectedTargets,
            );
          }
        } else if (!_.isEmpty(targetVizOptions.selectedTargets)) {
          selectedTargets = [...targetVizOptions.selectedTargets];
        }

        if (action.isSelected) {
          // we are adding this to the list of selected targets
          selectedTargets = [...selectedTargets, action.target];
        } else {
          // we are removing this from the selected targets
          selectedTargets = selectedTargets.filter(t => {
            return !_.isEqual(t, action.target);
          });
        }
        const selectedTargetsString = JSON.stringify(selectedTargets);
        return {
          ...open,
          viz: {
            ...targetViz,
            options: {
              ...targetVizOptions,
              selectedTargets: selectedTargetsString,
            },
          },
        };
      }

      case Const.Discover.UPDATE_VIZ_SETTING: {
        const openViz = { ...open.viz };
        const openVizOptions = { ...openViz.options };
        const options = { ...openVizOptions, [action.key]: action.value };
        const viz = { ...openViz, options };

        return {
          ...open,
          viz,
        };
      }

      case Const.Discover.UPDATE_AGGREGATION: {
        const openViz = { ...open.viz };
        const fieldIndex = _.findIndex(openViz.layout[action.shelfId], [
          'name',
          action.field,
        ]);
        const layoutList = [...openViz.layout[action.shelfId]];
        const field = layoutList[fieldIndex];
        const { defaultAggregation } = field;

        const options = { ...openViz.options };
        const customAggregations = parseJSON(options.customAggregations);
        if (
          action.aggregation !== defaultAggregation &&
          action.aggregation !==
            Viz.customAggregation({
              options: open.viz.options,
              field,
            })
        ) {
          customAggregations[Viz.defaultCustomAggSelector(action.field)] =
            action.aggregation;
        } else {
          delete customAggregations[Viz.defaultCustomAggSelector(action.field)];
        }
        options.customAggregations = JSON.stringify(customAggregations);
        return {
          ...open,
          viz: {
            ...openViz,
            options,
          },
        };
      }

      case Const.Discover.REPLACE_SELECTED_VIZ_TARGETS: {
        const targetViz = { ...open.viz };
        const targetVizOptions = { ...targetViz.options };
        let selectedTargets = action.target;
        if (_.isNil(selectedTargets)) {
          selectedTargets = [];
        } else if (_.isArray(selectedTargets)) {
          // no-op
        } else if (_.isObject(selectedTargets)) {
          // replacing with single target
          selectedTargets = [selectedTargets];
        }
        const selectedTargetsString = JSON.stringify(selectedTargets);
        return {
          ...open,
          viz: {
            ...targetViz,
            options: {
              ...targetVizOptions,
              selectedTargets: selectedTargetsString,
            },
          },
        };
      }

      case Const.Discover.SET_PIVOT_SORTING: {
        const viz = { ...open.viz };
        const { options } = viz;
        return {
          ...open,
          viz: { ...viz, options: { ...options, sort: action.sort } },
        };
      }

      case Const.Discover.DESIGNATE_RUNTIME_FILTER: {
        const parse = option => {
          if (_.isString(option)) {
            try {
              return JSON.parse(option);
            } catch {
              return {};
            }
          } else {
            return option;
          }
        };

        // field must have annotations in order to work
        if (!action.field || _.isEmpty(action.field?.annotations)) {
          return { ...open };
        }

        const {
          viz: {
            options: { runtimeFilters = {} },
          },
        } = open;

        const parsedRuntimeFilters = parse(runtimeFilters);

        parsedRuntimeFilters[action.field?.name] = {
          ...action.field,
        };

        return {
          ...open,
          viz: {
            ...open?.viz,
            options: {
              ...open.viz?.options,
              runtimeFilters: parsedRuntimeFilters,
            },
          },
        };
      }

      case Const.Discover.REMOVE_RUNTIME_FILTER: {
        if (!action.fieldName) {
          return { ...open };
        }

        let {
          viz: {
            options: { runtimeFilters = {} },
          },
        } = open;

        // on initial load, runtimeFilters may be a string
        if (_.isString(runtimeFilters)) {
          try {
            runtimeFilters = JSON.parse(runtimeFilters);
          } catch {
            runtimeFilters = {};
          }
        }

        if (runtimeFilters[action.fieldName]) {
          delete runtimeFilters[action.fieldName];
        }

        return {
          ...open,
          viz: {
            ...open?.viz,
            options: {
              ...open.viz?.options,
              runtimeFilters,
            },
          },
        };
      }
    }
    return state;
  },
  {},
);

export const OpenDiscoveryReducer = id => {
  const config = {
    // Your specific redux-undo config. You may need to tweak initTypes or
    // ignoreInitialState here depending on how you hydrate your store to ensure undoable
    // starts with a valid state
    undoType: 'UNDO',
    redoType: 'REDO',
    jumpToPastType: 'JUMP_TO_PAST',
    neverSkipReducer: true,
    debug: false,
    ignoreInitialState: true,
    filter: action => {
      const ignoredActions = [
        Const.Discover.SET_OPEN_DISCOVERY_STATE,
        Const.Discover.UPDATE_DISCOVERY_WITH_ERROR,
      ];
      return action.discoveryId === id && !ignoredActions.includes(action.type);
    },
  };

  return undoable(OpenDiscoveryInternalReducer, config);
};
