import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import _, { forEach, has, head, isEmpty, reject } from 'lodash';
import DiscoverActions from '../../common/redux/actions/DiscoverActions';
import { useNavigate } from 'react-router-dom';
import { useHasValueChanged } from '../../common/utilities/state-helpers.hook';
import { IViz } from '../interfaces';
import { IDiscoverReducerState } from '../../common/redux/reducers/DiscoverReducers';

export const usePinnedDiscoveries = ({
  loadPinnedDiscoveries,
  pinnedDiscoveries,
  openDiscoveries,
}: {
  loadPinnedDiscoveries: boolean;
  pinnedDiscoveries: IViz[];
  openDiscoveries: IDiscoverReducerState['openDiscoveries'];
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // loads pinned discoveries based on listDiscoveries call
  useEffect(() => {
    if (loadPinnedDiscoveries && !isEmpty(pinnedDiscoveries)) {
      forEach(pinnedDiscoveries, disc => {
        if (!has(openDiscoveries, disc?.id)) {
          dispatch(DiscoverActions.openVisualization(disc));
        }
      });
    }
  }, [
    dispatch,
    navigate,
    pinnedDiscoveries,
    openDiscoveries,
    loadPinnedDiscoveries,
  ]);

  const numPinsToLoad = useMemo(
    () =>
      reject(pinnedDiscoveries, ({ id: vizId }) => has(openDiscoveries, vizId))
        ?.length,
    [openDiscoveries, pinnedDiscoveries],
  );

  const hasPinsChanged = useHasValueChanged({
    value: numPinsToLoad,
    defaultValue: 0,
  });

  const pinLoadsComplete = hasPinsChanged && numPinsToLoad === 0;

  useEffect(() => {
    const vizId = head(pinnedDiscoveries)?.id;
    // setDisplayDiscovery was called opening other visualizations, override here
    if (pinLoadsComplete && has(openDiscoveries, vizId)) {
      dispatch(DiscoverActions.setDisplayDiscovery(vizId));
    }
  }, [pinnedDiscoveries, pinLoadsComplete, dispatch, openDiscoveries]);
};
