import _, { isNil } from 'lodash';
import { isDashletMode } from '../auth';
import { ROUTER_DIRS } from './Constants';

const IDM_PATH_HINT = 'X-IDM-PATH-HINT';
let history = [];
let lastLocation = {
  pathname: ROUTER_DIRS.ROOT,
};

export const URLs = {
  goToLastOrHome: (...additionalIgnorePaths: string[]) => {
    if (isDashletMode()) {
      return;
    }
    let path = ROUTER_DIRS.ROOT as any;
    const pathHint = localStorage.getItem(IDM_PATH_HINT);
    if (!_.isEmpty(pathHint)) {
      path = pathHint;
      localStorage.removeItem(IDM_PATH_HINT);
    } else if (
      !isNil(lastLocation) &&
      !_.some(
        [ROUTER_DIRS.LOGIN, ...additionalIgnorePaths],
        ignorePath =>
          !_.isEmpty(lastLocation) &&
          (lastLocation as any).pathname.includes(ignorePath),
      )
    ) {
      path = lastLocation;
    }
    history.push(path);
  },
  /**
   * Called when user has successfully logged in.
   */
  redirectAfterLogin: () => {
    if (!history) {
      // here on initial load with pre-authenticated client before history has been captured. Ignore
      return;
    }
    lastLocation = lastLocation || (history as any).location;
    URLs.goToLastOrHome();
  },
  setHistoryAndLastLocation: (_history, _lastLocation) => {
    history = _history;

    if (_lastLocation?.pathname !== lastLocation?.pathname) {
      lastLocation = _lastLocation;
    }
  },
  goTo: (path, state?) => {
    // state can change with the same location.pathname
    if (state) {
      const location = {
        pathname: path,
        state,
      };
      history.push(location);
    } else if (path && (history as any).location.pathname !== path) {
      // ignore requests to the same location.pathname
      history.push(path);
    }
  },
  getCurrent: () => {
    return (history as any).location.pathname;
  },
  getQueryParams: () => {
    return URLs.parseQueryParams(location.search);
  },
  parseQueryParams: (search: string) => {
    return _(search)
      .chain()
      .trimStart('?')
      .split('&')
      .map(s =>
        _(s)
          .split('=')
          .map(decodeURIComponent)
          .value(),
      )
      .reject(pair => pair.length != 2)
      .reject(([key]) => !key)
      .fromPairs()
      .value();
  },
  stringifyQueryParams: (params: { [key: string]: any }) => {
    const qs = _(params)
      .omitBy((val, key) => _.isNil(val) || val === '' || _.isEmpty(key))
      .map(
        (val, key) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
      )
      .join('&');
    return _.isEmpty(qs) ? '' : `?${qs}`;
  },
  appendQueryParams: (params: { [key: string]: any }) => {
    return _(URLs.getQueryParams())
      .assign(params)
      .thru(URLs.stringifyQueryParams)
      .value();
  },
  joinUrls: (...urls: string[]) => {
    let result = _(urls)
      .map(c => _.trim(c, '/'))
      .reject(_.isEmpty)
      .join('/');
    if (_.startsWith(_.head(urls), '/') && !_.startsWith(result, '/')) {
      result = `/${result}`;
    }
    if (_.endsWith(_.last(urls), '/') && !_.endsWith(result, '/')) {
      result += '/';
    }
    return result;
  },
};

export default URLs;
