import Library from './component';
import { connect } from 'react-redux';
import { compose, withProps } from 'react-recompose';
import { withRouter } from '../../common/utilities/with-router';
import Discover from '../../common/redux/actions/DiscoverActions';
import MainActions from '../../common/redux/actions/MainActions';
import DiscoverQueries from '../../common/graphql/DiscoverQueries';
import { graphql } from '@apollo/client/react/hoc';
import {
  ACCOUNT_SELECTORS,
  isAdmin,
} from '../../common/redux/selectors/AccountSelectors';
import { TABLE_SELECTORS } from '../../common/redux/selectors/TableSelectors';
import _, { get, isFunction } from 'lodash';
import { CreateVizOptions, UpdateVizOptions } from '../../common/graphql';
import Util from '../../common/Util';
import { ROUTER_DIRS } from '../../common';

export const mapStateToProps = (state, ownProps) => {
  return {
    openDiscoveries: state.discover.openDiscoveries,
    saveError: state.discover.saveError,
    isAdvanced: state.main.advanced,
    isAdmin: isAdmin(state.account),
    currentUser: ACCOUNT_SELECTORS.getCurrentUser(state),
    search: TABLE_SELECTORS.getSearch(state, ownProps),
    tags: TABLE_SELECTORS.getSearchTags(state, ownProps),
    chartTypes: TABLE_SELECTORS.getSearchChartTypes(state, ownProps),
    searchDatasets: TABLE_SELECTORS.getSearchDatasets(state, ownProps),
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearSaveError: () => {
      dispatch(Discover.setSaveError(undefined));
    },
    openDiscovery: discovery => {
      dispatch(Discover.openDiscovery(discovery));
    },
    openVisualization: discovery => {
      dispatch(Discover.openVisualization(discovery));
    },
    closeDeletedDiscovery: discovery => {
      dispatch(Discover.closeDeletedDiscovery(discovery));
    },
    setSearch: search => {
      dispatch(MainActions.setSearch(ownProps.domain, search));
    },
    setSearchTags: tags => {
      dispatch(MainActions.setSearchTags(ownProps.domain, tags));
    },
    setSearchChartTypes: chartTypes => {
      dispatch(MainActions.setSearchChartTypes(ownProps.domain, chartTypes));
    },
    setSearchDatasets: datasets => {
      dispatch(MainActions.setSearchDatasets(ownProps.domain, datasets));
    },
    showNewViz: () => {
      ownProps.history.push(ROUTER_DIRS.NEW_VIZ);
    },
  };
};

export const VizAndDatasetQuery = graphql(DiscoverQueries.DiscoveriesQuery, {
  options: () => ({
    fetchPolicy: 'cache-first',
  }),
  props: ({
    data: {
      loading = true,
      visualizations: dehydratedVizualizations,
      datasets,
    },
  }: any = {}) => {
    const visualizations = dehydratedVizualizations
      ? dehydratedVizualizations.map(v => {
          const description = _.find(v.options, { key: 'description' });
          const dataset = _.find(datasets, { id: v.datasetId });
          return {
            ...v,
            discoveryType: 'VISUALIZATION',
            dataset,
            description: !_.isNil(description) ? description.value : '',
            datasetName: dataset?.name,
          };
        })
      : [];
    return { visualizations, datasets, loading };
  },
});

const SaveVizMutation = graphql(DiscoverQueries.CreateVisualizationMutation, {
  options: CreateVizOptions,
  props: ({ mutate }) => ({
    newVisualization(viz) {
      return mutate({
        variables: {
          viz,
        },
        onError: error => {
          console.log('creating visualization error', error);
        },
      });
    },
  }),
});

const UpdateVizMutation = graphql(DiscoverQueries.UpdateVisualizationMutation, {
  options: UpdateVizOptions,
  props: ({ ownProps, mutate }: any) =>
    ({
      updateVisualization(viz, updateKey) {
        mutate({
          variables: {
            viz: Util.removeTypeNameRecursively(
              _.pick(viz, [
                'id',
                'name',
                'layout',
                'options',
                'private',
                'tags',
              ]),
            ),
          },
        })
          .then(({ data }) => {
            // Update open discovery
            const open = ownProps.openDiscoveries[viz.id];
            if (!_.isNil(open)) {
              if (updateKey === 'name') {
                ownProps.updateName(viz, viz.name);
              } else if (updateKey === 'description') {
                const entry = _.find(data.updateVisualization.options, {
                  key: 'description',
                });
                ownProps.updateVizSetting(viz.id, 'description', entry.value);
              } else if (updateKey === 'isPrivate') {
                ownProps.setVizAccess(
                  viz.id,
                  data.updateVisualization.isPrivate ? 'private' : 'public',
                );
              }
            }
          })
          .catch(error => {
            ownProps.setErrorToast(error.message);
          });
      },
    } as any),
});

const DeleteVizMutation = graphql(DiscoverQueries.DeleteVisualizationMutation, {
  options: {
    update: (store, { data: { deleteVisualization } }: any) => {
      // https://www.apollographql.com/docs/react/caching/cache-interaction/#example-deleting-a-field-from-a-cached-object
      store.modify({
        id: store.identify(deleteVisualization),
        fields: (_fields, { DELETE }) => DELETE,
      });
    },
  },
  props: ({ ownProps, mutate }: any) =>
    ({
      deleteVisualization(id) {
        mutate({
          variables: {
            id,
          },
        })
          .then(() => {
            // Close discovery if in open discoveries
            if (get(ownProps?.openDiscoveries ?? [], id)) {
              isFunction(ownProps?.closeDeletedDiscovery) &&
                ownProps.closeDeletedDiscovery(id);
            }
          })
          .catch(error => {
            console.log('Error deleting discovery.', error);
          });
      },
    } as any),
});

export default compose(
  withRouter,
  withProps(() => {
    return {
      domain: 'library',
    };
  }),
  connect(mapStateToProps, mapDispatchToProps),
  VizAndDatasetQuery,
  SaveVizMutation,
  UpdateVizMutation,
  DeleteVizMutation,
)(Library);
