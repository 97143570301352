import { createContext } from 'react';
import _, { includes } from 'lodash';
import { format as d3Format } from 'd3';
import moment, { setGlobalFormat } from './Moment';
import Moment from 'moment';
import { IInternationalizationPreferences } from '../account/interfaces';
import {
  ICustomFormatProps,
  ICustomNegativeFormat,
} from '../discovery/custom-format-modal';
import { IAnnotation } from '../datasets';
import JSON5 from 'json5';
import { getConfig } from './global-discover-config';
import { IDashletFilter } from './utilities/sugar-filter-converter/sugar-filter-converter.interfaces';
import {
  PivotGreen,
  PivotYellow,
  PivotRed,
  PivotWhite,
  PivotBlue,
} from '../common/emotion/theme/colors.styles';

export const IDM_TOKEN_HEADER_NAME = 'X-IDM-ACCESS-TOKEN';
export const IDM_PATH_HINT = 'X-IDM-PATH-HINT';

export const DEFAULT_DATE_FORMAT = 'l';
export const DEFAULT_TIME_FORMAT = 'LT';

let dateFormat = DEFAULT_DATE_FORMAT;
let timeFormat = DEFAULT_TIME_FORMAT;

let currentUser;

export const getCurrentUser = () => currentUser;
export const setCurrentUser = _currentUser => {
  currentUser = _currentUser;
};

export const getDateFormat = () => dateFormat;
export const getTimeFormat = () => timeFormat;
export const getDateTimeFormat = () => `${dateFormat} ${timeFormat}`;

export const DEFAULT_EXCEL_ROW_RENDER_THRESHOLD = 50000;

export type DynamicFieldConfig = {
  sugarModule: string;
  field: string;
  fieldSlug: string;
};

export type DynamicValue = {
  [key: string]: string | string[]; // [value in DynamicFields]: string | string[];
};

export type DynamicFieldsType = {
  [key: string]: {
    [key: string]: string;
  };
};

export const DynamicFields: DynamicFieldsType = {
  Accounts: {
    id: '__ACCOUNT_ID__',
    name: '__ACCOUNT_NAME__',
  },
  Cases: {
    id: '__CASE_ID__',
    name: '__CASE_NAME__',
  },
  Opportunities: {
    id: '__OPP_ID__',
    name: '__OPP_NAME__',
  },
  RevenueLineItems: {
    id: '__RLI_ID__',
    name: '__RLI_NAME__',
  },
  Users: {
    name: '__FULLNAME__',
    userName: '__EMAIL__',
    id: '__SRN__',
  },
  Forecasts: {
    reportees: '__FORECASTS_REPORTEES_LIST__',
    timeperiodstart: '__FORECASTS_TIME_PERIOD_START__',
    timeperiodend: '__FORECASTS_TIME_PERIOD_END__',
  },
};

export enum SortDirection {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export const AllowedDashletDrillLinkDynamicFields = [
  DynamicFields.Forecasts.reportees,
  DynamicFields.Forecasts.timeperiodstart,
  DynamicFields.Forecasts.timeperiodend,
];

export const DynamicCalcValues = [
  DynamicFields.Forecasts.timeperiodstart,
  DynamicFields.Forecasts.timeperiodend,
];

export const DynamicFieldValues = _.flatMap(DynamicFields, _.values);
export const DashletOnlyDynamicFieldValues = _(DynamicFields)
  .omit('Users')
  .flatMap(_.values)
  .value();

export const reverseSlugLookup = fieldSlug => {
  const result = _.findKey(DynamicFields, f =>
    _.includes(_.values(f), fieldSlug),
  );
  return result;
};

const dashboardFilters: IDashletFilter = {};
export const setDashboardFilters = (vizId: string, filter: IDashletFilter) => {
  if (_.isEmpty(filter)) {
    _.unset(dashboardFilters, vizId);
  }
  _.assign(dashboardFilters, filter);
};

export const getDashboardFilters = (): IDashletFilter =>
  _.omit(dashboardFilters, 'undefined');

const dynamicValues: DynamicValue = {};
export const setDynamicValues = (obj: DynamicValue) =>
  _.assign(dynamicValues, obj);

export const getDynamicValues = (): DynamicValue =>
  _.omit(dynamicValues, 'undefined');

export const SugarContext = createContext(getDynamicValues());
export const SugarFilterContext = createContext(getDashboardFilters());
export const isDynamicFieldValue = value =>
  _.includes(DynamicFieldValues, value);

export const isDashletOnlyDynamicFieldValue = value =>
  _.includes(DashletOnlyDynamicFieldValues, value);

const updateMomentFormat = () => {
  setGlobalFormat(getDateTimeFormat());
  Moment.defaultFormat = getDateTimeFormat();
};

export const convertSugarDateFormat = sugarDateFormat =>
  _.isEmpty(sugarDateFormat)
    ? DEFAULT_DATE_FORMAT
    : sugarDateFormat
        .split('m')
        .join('MM')
        .split('d')
        .join('DD');

export const convertSugarTimeFormat = sugarTimeFormat =>
  _.isEmpty(sugarTimeFormat)
    ? DEFAULT_TIME_FORMAT
    : sugarTimeFormat.split('i').join('mm');

export const setDatetimeFormat = ({
  dateFormat: sugarDateFormat = '',
  timeFormat: sugarTimeFormat = '',
}) => {
  dateFormat = convertSugarDateFormat(sugarDateFormat) || dateFormat;
  timeFormat = convertSugarTimeFormat(sugarTimeFormat) || timeFormat;
  updateMomentFormat();
};

updateMomentFormat();

export const pendoConstants = {
  action: 'loaded',
  eventLabel: 'Test - Visualization Loaded',
  category: 'Discover',
};

export const sugarDateFormats = [
  ['Y-m-d', '2010-12-23'],
  ['m-d-Y', '12-23-2010'],
  ['d-m-Y', '23-12-2010'],
  ['Y/m/d', '2010/12/23'],
  ['m/d/Y', '12/23/2010'],
  ['d/m/Y', '23/12/2010'],
  ['Y.m.d', '2010.12.23'],
  ['d.m.Y', '23.12.2010'],
  ['m.d.Y', '12.23.2010'],
];

export const sugarTimeFormats = [
  ['H:i', '23:00'],
  ['h:ia', '11:00pm'],
  ['h:iA', '11:00PM'],
  ['h:i a', '11:00 pm'],
  ['h:i A', '11:00 PM'],
  ['H.i', '23.00'],
  ['h.ia', '11.00pm'],
  ['h.iA', '11.00PM'],
  ['h.i a', '11.00 pm'],
  ['h.i A', '11.00 PM'],
];

export const numGroupSeparators = [
  [',', '1,234,456'],
  ['.', '1.234.456'],
];

export const decimalCharacters = [
  ['.', '123.45'],
  [',', '123,45'],
];

export const negativeFormats: ICustomNegativeFormat[] = [
  {
    pre: '-',
    post: '',
  },
  {
    pre: '',
    post: '',
  },
  {
    pre: '(',
    post: ')',
  },
  {
    pre: '(-',
    post: ')',
  },
];

export const whiteStripesId = 'whiteStripes';
export const whiteStripesFill = `url(#${whiteStripesId})`;

export const DASHLET_DRILL_VIZ_SUFFIX = '-dashlet-drill';
export const addDashletSuffix = (vizId: string) => {
  if (includes(vizId, DASHLET_DRILL_VIZ_SUFFIX)) {
    return vizId;
  }

  return `${vizId}${DASHLET_DRILL_VIZ_SUFFIX}`;
};
export enum DASHLET_DRILL_TYPE {
  SOURCE = 'source',
  TARGET = 'target',
}
export const stripDashletSuffix = (vizId = '') =>
  vizId.replace(new RegExp(DASHLET_DRILL_VIZ_SUFFIX), '');
export const hasDashletSuffix = (_vizId: string) =>
  includes(_vizId ?? '', DASHLET_DRILL_VIZ_SUFFIX);

export const isFramelessMfeMode = () =>
  (window as any)?.App?.config?.appId === 'SugarCRM';

export const getIsDevMode = () => {
  const frontendUrl = getFrontendUrl();
  return includes(frontendUrl, 'localhost');
};

export const getFrontendUrl = () => {
  let {
    APPLICATION_URL: frontendUrl,
    TENANT_ID: tenantId,
    SKIP_URL_TENANT: skipUrlTenant,
  } = getConfig();
  if (!skipUrlTenant && frontendUrl && !_.includes(frontendUrl, tenantId)) {
    const url = new URL(frontendUrl);
    url.host = [tenantId, url.host].join('.');
    frontendUrl = url.toString();
  }
  return (isFramelessMfeMode() && frontendUrl) || window?.location?.origin;
};

export const CUSTOM_FORMAT_ANNOTATION = 'CUSTOM_FORMAT_PROPS';
export const FIELD_SEPARATOR_SYMBOL = '|';
export const FIELD_SEPARATOR = ' | ';
export const PRIVACY_POLICY_LINK =
  'https://www.sugarcrm.com/legal/privacy-policy';
export const HELP_LINK = 'https://support.sugarcrm.com/Documentation/Discover/';
export const SUPPORT_LINK =
  'https://support.sugarcrm.com/sl/resources/working_with_sugar_support/';
export const VIEW_FUNCTION_REF_LINK =
  'https://support.sugarcrm.com/Documentation/Discover/Sugar_Discover_Functions/';
export const NULL_TOKEN = '__NULL__';
export const NULL_DISPLAY = '-';
export const EMPTY_STRING_TOKEN = '__EMPTY_STRING__';
export const EMPTY_STRING_DISPLAY = '--';
export const EMPTY_CELL_TOKEN = '__EMPTY_CELL__';
export const EMPTY_CELL_DISPLAY = '';
export const SUGARCRM_PRIMARY_DATASET_SYSTEM_NAME = 'SugarCRM';
export const SUGARCRM_WEBSITE = 'https://www.sugarcrm.com';

export const VIZ = {
  LEFT_PANEL_MIN_WIDTH: 320,
  LEFT_PANEL_WIDTH: 420,
  LEGEND_PANEL_WIDTH: 180,
};

export const DnDTypes = {
  VIZ_FIELD: 'viz_field',
  CALC_FIELD: 'calc_field',
};

export const ComponentTypes = {
  ACTIVITY: 'ACTIVITY',
  INSIGHTS: 'INSIGHTS',
  REDIRECT: 'REDIRECT',
  INSIGHTS_LIST: 'INSIGHTS_LIST',
};

export const PriorPeriodTypes = {
  VALUE_OF: {
    key: 'VALUE_OF',
    abbreviation: null,
    displayText: 'priorPeriod.typeValueOf',
  },
  CHANGE_FROM: {
    key: 'CHANGE_FROM',
    abbreviation: 'priorPeriod.typeChangeFromAbbreviation',
    displayText: 'priorPeriod.typeChangeFrom',
  },
  PCT_CHANGE_FROM: {
    key: 'PCT_CHANGE_FROM',
    abbreviation: 'priorPeriod.typePercentChangeFromAbbreviation',
    displayText: 'priorPeriod.typePercentChangeFrom',
  },
};

const CATEGORIES = {
  ACCOUNT: 'account',
  GENERAL: 'general',
  ACTIVITY: 'activity',
  VIZ: 'viz',
  VIZ_LAYOUT: 'viz-layout',
  DATASET: 'dataset',
  DISCOVER: 'discover',
  LIBRARY: 'library',
  ADMIN: 'administration',
  LEADS: 'LEADS',
};

const EVENTS = {
  /* Login/Logout */
  LOGOUT: { event: 'logout', category: CATEGORIES.ACCOUNT },
  LOGOUT_CANCELLED: { event: 'logout-cancelled', category: CATEGORIES.ACCOUNT },
  IDLE_LOGOUT: { event: 'idle-logout', category: CATEGORIES.ACCOUNT },
  TOUCH_COOKIE: { event: 'touch-cookie', category: CATEGORIES.ACCOUNT },
  TOGGLE_STAY_LOGGED_IN: {
    event: 'toggle-stay-logged-in',
    category: CATEGORIES.ACCOUNT,
  },
  REQUEST_PASSWORD_RESET: {
    event: 'request-password-reset',
    category: CATEGORIES.ACCOUNT,
  },
  RETURN_TO_LOGIN: { event: 'return-to-login', category: CATEGORIES.ACCOUNT },
  LOGIN_ATTEMPT: { event: 'login-attempt', category: CATEGORIES.ACCOUNT },
  VIEW_PRIVACY_POLICY: {
    event: 'view-privacy-policy',
    category: CATEGORIES.GENERAL,
  },
  VIEW_TERMS_OF_SERVICE: {
    event: 'view-terms-of-service',
    category: CATEGORIES.GENERAL,
  },

  /* Password */
  FORGOT_PASSWORD: { event: 'forgot-password', category: CATEGORIES.ACCOUNT },
  CHANGE_PASSWORD: { event: 'change-password', category: CATEGORIES.ACCOUNT },
  CREATE_PASSWORD_AND_LOGIN: {
    event: 'create-password-and-login',
    category: CATEGORIES.ACCOUNT,
  },
  RESET_PASSWORD_AND_LOGIN: {
    event: 'reset-password-and-login',
    category: CATEGORIES.ACCOUNT,
  },
  UPDATE_PASSWORD: { event: 'update-password', category: CATEGORIES.ACCOUNT },
  TOGGLE_PASSWORD_TEXT: {
    event: 'toggle-password-text',
    category: CATEGORIES.ACCOUNT,
  },

  /* Notification */
  SLACK_NOTIFICATION_ON: {
    event: 'slack-notification-on',
    category: CATEGORIES.ACCOUNT,
  },
  SLACK_NOTIFICATION_OFF: {
    event: 'slack-notification-off',
    category: CATEGORIES.ACCOUNT,
  },
  SMS_NOTIFICATION_ON: {
    event: 'sms-notification-on',
    category: CATEGORIES.ACCOUNT,
  },
  SMS_NOTIFICATION_OFF: {
    event: 'sms-notification-off',
    category: CATEGORIES.ACCOUNT,
  },
  EMAIL_NOTIFICATION_ON: {
    event: 'email-notification-on',
    category: CATEGORIES.ACCOUNT,
  },
  EMAIL_NOTIFICATION_OFF: {
    event: 'email-notification-off',
    category: CATEGORIES.ACCOUNT,
  },

  /* Slack */
  ADD_TO_SLACK: { event: 'add-to-slack', category: CATEGORIES.ACCOUNT },
  CHANGE_SLACK_CHANNEL: {
    event: 'change-slack-channel',
    category: CATEGORIES.ACCOUNT,
  },

  /* User profile */
  UPDATE_USER_PROFILE: {
    event: 'update-user-profile',
    category: CATEGORIES.ACCOUNT,
  },
  UPLOAD_PROFILE_IMAGE: {
    event: 'upload-profile-image',
    category: CATEGORIES.ACCOUNT,
  },
  UPLOAD_PROFILE_IMAGE_REJECTED: {
    event: 'upload-profile-image-rejected',
    category: CATEGORIES.ACCOUNT,
  },
  UPLOAD_PROFILE_IMAGE_FAILED: {
    event: 'upload-profile-image-failed',
    category: CATEGORIES.ACCOUNT,
  },
  REMOVE_PROFILE_IMAGE: {
    event: 'remove-profile-image',
    category: CATEGORIES.ACCOUNT,
  },

  /* Activity */
  ACTIVITY_CONTENT_OPENED: {
    event: 'activity-content-opened',
    category: CATEGORIES.ACTIVITY,
  },

  /* Feedback */
  FEEDBACK_POSITIVE: { event: 'feedback-positive' },
  FEEDBACK_NEGATIVE: { event: 'feedback-negative' },
  FEEDBACK_NEUTRAL: { event: 'feedback-neutral' },

  /* Library */
  OPEN_VIZ: { event: 'open-viz' },
  OPEN_MONITOR: { event: 'open-monitor' },
  DELETE_VIZ: { event: 'delete-viz' },
  DELETE_MONITOR: { event: 'delete-monitor' },
  DELETE_CANCELLED: { event: 'delete-cancelled' },
  CHANGE_VIZ_NAME: { event: 'change-viz-name' },
  CHANGE_VIZ_ACCESS: { event: 'change-viz-access' },
  CHANGE_VIZ_NAME_CANCELLED: { event: 'change-viz-name-cancelled' },
  CHANGE_VIZ_DESCRIPTION: { event: 'change-viz-description' },
  CHANGE_VIZ_DESCRIPTION_CANCELLED: {
    event: 'change-viz-description-cancelled',
  },
  CHANGE_MONITOR_NAME: { event: 'change-monitor-name' },
  CHANGE_MONITOR_NAME_CANCELLED: { event: 'change-monitor-name-cancelled' },
  CLOSE_DISCOVERY: { event: 'close-discovery' },
  CREATE_NEW_VIZ: { event: 'create-new-viz' },
  CREATE_NEW_VIZ_SELECT_DATASOURCE: {
    event: 'create-new-viz-select-datasource',
  },

  /* Dataset */
  EDIT_DATASET: { event: 'edit-dataset' },
  REFRESH_DATASET_SALESFORCE: { event: 'refresh-dataset-salesforce' },
  REFRESH_DATASET_SUGARCRM: { event: 'refresh-dataset-sugarcrm' },
  REFRESH_DATASET_CSV: { event: 'refresh-dataset-csv' },
  DELETE_DATASET: { event: 'delete-dataset' },
  DELETE_DATASET_CANCELLED: { event: 'delete-dataset-cancelled' },
  UPLOAD_CSV_FILE: { event: 'upload-csv-file' },
  DROP_FILE_TO_UPLOAD: { event: 'drop-file-to-upload' },
  DROP_FILE_TO_UPLOAD_REJECTED: { event: 'drop-file-to-upload-rejected' },
  SELECT_IMPORT_SOURCE_TYPE: type => {
    return { event: `select-import-source-type-${type}` };
  },
  UPDATE_COLUMN_METADATA: metadataType => {
    return { event: `update-column-metadata-${metadataType}` };
  },
  DATASET_FILE_SETTING_CHANGED: propertyName => {
    return { event: `update-column-metadata-${propertyName}` };
  },
  SAVE_DATASET_EDIT: { event: 'save-dataset-edit' },
  ADD_DATASET_CALC: { event: 'add-dataset-calc' },
  REMOVE_DATASET_CALC: { event: 'remove-dataset-calc' },

  /* Viz */
  SAVE_VIZ: { event: 'save-viz' },
  SAVE_NEW_VIZ: { event: 'save-new-viz' },
  SAVE_AS_VIZ: { event: 'save-as-viz' },
  SAVE_CANCELLED: { event: 'save-cancelled' },
  UNDO_VIZ: { event: 'undo-viz' },
  REDO_VIZ: { event: 'redo-viz' },
  RESET_VIZ: { event: 'reset-viz' },
  SHARE_VIZ: { event: 'share-viz' },
  DOWNLOAD_VIZ_CHART: { event: 'download-viz-chart' },
  SHOW_PANEL: panelName => {
    return { event: `show-panel-${panelName.toLowerCase()}` };
  },
  CLOSE_CONFIG_PANEL: { event: 'close-config-panel' },
  CLOSE_DETAIL_PANEL: { event: 'close-detail-panel' },
  SHOW_LEGEND: { event: 'show-legend' },
  CLOSE_LEGEND: { event: 'close-legend' },
  LEGEND_TITLES_ON: { event: 'legend-titles-on' },
  LEGEND_TITLES_OFF: { event: 'legend-titles-off' },
  CHART_TYPE_SELECTED: name => {
    return { event: `chart-type-selected-${name.toLowerCase()}` };
  },
  DATA_LABELS_ON: { event: 'data-labels-on' },
  DATA_LABELS_OFF: { event: 'data-labels-off' },
  SHOW_FILTERS: { event: 'show-filters' },
  CLOSE_FILTERS: { event: 'close-filters' },
  SEARCH_FIELDS: { event: 'search-fields' },
  SET_LINKED_REPORT: { event: 'set-linked-report' },
  REMOVE_LINKED_REPORT: { event: 'remove-linked-report' },
  REPORT_LINK_STRATEGY: strategy => {
    return { event: `report-link-strategy-${strategy.toLowerCase()}` };
  },
  SHOW_TIME_HIERARCHY: specifier => {
    return { event: `show-time-hierarchy-${specifier.toLowerCase()}` };
  },
  HIDE_TIME_HIERARCHY: { event: 'hide-time-hierarchy' },
  SAVE_CALC: { event: 'save-calc' },
  SAVE_FILTER: { event: 'save-filter' },
  REMOVE_FILTER: { event: 'remove-filter' },
  ALIGN_Y_AXIS_AT_ZERO_ON: { event: 'align-y-axis-at-zero-on' },
  ALIGN_Y_AXIS_AT_ZERO_OFF: { event: 'align-y-axis-at-zero-off' },
  CHART_SUMMARY_ON: { event: 'chart-summary-on' },
  CHART_SUMMARY_OFF: { event: 'chart-summary-off' },
  CUSTOM_FORMAT_TOGGLE: (name, isOn) => {
    return {
      event: `custom-format-toggle-${name.toLowerCase()}${
        isOn ? '-on' : '-off'
      }`,
    };
  },
  CONDITIONAL_FORMATTING_TOGGLE: isOn => {
    return {
      event: `conditional-formatting-toggle-${isOn ? '-on' : '-off'}`,
    };
  },
  DRILL_LINKING_TOGGLE: (link, isOn) => {
    return {
      event: `drill-linking-toggle-${link}-${isOn ? '-on' : '-off'}`,
    };
  },
  CONDITIONAL_FORMATTING_COLOR_SCALE_CHANGE: colorScale => {
    return { event: `conditional-formatting-color-scale-change-${colorScale}` };
  },
  PIN_VIZ: { event: 'pinned' },
  UNPIN_VIZ: { event: 'unpinned' },
  HANDLE_NULLS_AS: option => {
    return { event: `nulls-as-${option}` };
  },

  /* Viz Layout - these will be tracked differently in GTM (more fields) based on the category */
  ADD_FIELD: (shelf, fieldType, chartType, method = 'context-menu') => {
    return {
      event: 'add-field',
      shelf,
      fieldType,
      chartType,
      method,
      category: CATEGORIES.VIZ_LAYOUT,
    };
  },
  MOVE_FIELD: (shelf, fieldType, chartType, method = 'context-menu') => {
    return {
      event: 'move-field',
      shelf,
      fieldType,
      chartType,
      method,
      category: CATEGORIES.VIZ_LAYOUT,
    };
  },
  MODIFY_FIELD: (shelf, fieldType, chartType, method = 'context-menu') => {
    return {
      event: 'modify-field',
      shelf,
      fieldType,
      chartType,
      method,
      category: CATEGORIES.VIZ_LAYOUT,
    };
  },
  REMOVE_FIELD: (fieldType, chartType, method = 'context-menu') => {
    return {
      event: 'remove-field',
      shelf: 'trashcan',
      fieldType,
      chartType,
      method,
      category: CATEGORIES.VIZ_LAYOUT,
    };
  },
  RENAME_FIELD: (shelf, fieldType, chartType, method = 'context-menu') => {
    return {
      event: 'rename-field',
      shelf,
      fieldType,
      chartType,
      method,
      category: CATEGORIES.VIZ_LAYOUT,
    };
  },
  REPOSITION_FIELD: (shelf, fieldType, chartType, method = 'context-menu') => {
    return {
      event: 'reposition-field-in-shelf',
      shelf,
      fieldType,
      chartType,
      method,
      category: CATEGORIES.VIZ_LAYOUT,
    };
  },
  DELETE_CALC_FIELD: (fieldType, chartType, method = 'context-menu') => {
    return {
      event: 'delete-calc-field',
      shelf: undefined,
      fieldType,
      chartType,
      method,
      category: CATEGORIES.VIZ_LAYOUT,
    };
  },
  DELETE_CALC_FIELD_CANCELLED: (
    fieldType,
    chartType,
    method = 'context-menu',
  ) => {
    return {
      event: 'delete-calc-field-cancelled',
      shelf: undefined,
      fieldType,
      chartType,
      method,
      category: CATEGORIES.VIZ_LAYOUT,
    };
  },

  /* Generic events */
  CLOSE_DIALOG: { event: 'close-dialog' },
  SORT_COLUMN: (direction, name) => {
    return { event: `sort-column-${direction.toLowerCase()}-${name}` };
  },

  /* Administration */
  ADMIN_INVITE_USERS: { event: 'invite-users', category: CATEGORIES.ADMIN },
  ADMIN_CREATE_USER: { event: 'create-user', category: CATEGORIES.ADMIN },
  ADMIN_UPDATE_USER: { event: 'update-user', category: CATEGORIES.ADMIN },
  ADMIN_CREATE_USER_FAILED: {
    event: 'create-user-failed',
    category: CATEGORIES.ADMIN,
  },
  ADMIN_UPDATE_USER_FAILED: {
    event: 'update-user-failed',
    category: CATEGORIES.ADMIN,
  },
  INSIGHT_VIEWED: (discovery, insight) => {
    return {
      event: 'insight-viewed',
      insight: JSON.stringify(_.omit(insight, ['scoreDetail', '__typename'])),
      category: CATEGORIES.VIZ,
    };
  },
};
export const TRENDLINE_TOGGLE_SELECTOR = 'trendline';
export const TRENDLINE_OPTION_SELECTOR = 'trendlineStrategy';
export enum TRENDLINE_STRATEGY {
  LINEAR = 'LINEAR',
  POLYNOMIAL = 'POLYNOMIAL',
}

export const Tracking = {
  CATEGORIES,
  EVENTS,
};

// If the input is not a number return the null display char
const NullIfNaN = func => (
  value,
  i18nPrefs: IInternationalizationPreferences = {},
  customFormatProps?: ICustomFormatProps,
) => {
  const numValue = +value;
  return !_.isFinite(numValue)
    ? NULL_DISPLAY
    : func(numValue, i18nPrefs, customFormatProps);
};

export const handleEmptyString = value => {
  return value === '' || value === EMPTY_STRING_TOKEN
    ? EMPTY_STRING_DISPLAY
    : value === EMPTY_CELL_TOKEN
    ? EMPTY_CELL_DISPLAY
    : value;
};

export const getCustomFormatProps = (
  annotations: IAnnotation[],
): ICustomFormatProps => {
  const customProps = _.find(annotations, { key: CUSTOM_FORMAT_ANNOTATION })
    ?.value;
  return !_.isNil(customProps)
    ? JSON5.parse(customProps)
    : ({} as ICustomFormatProps);
};

export interface IFormatProto<T = any> {
  dataType: string;
  displayName: string;
  formatType: string;
  formatExample(
    currencySymbol?: string,
    i18nPrefs?: IInternationalizationPreferences,
  ): string;
  grouping: number;
  formatSmall?: (
    val: T,
    i18nPrefs?: IInternationalizationPreferences,
    customFormatProps?: ICustomFormatProps,
  ) => string;
  format(
    val: T,
    i18nPrefs?: IInternationalizationPreferences,
    customFormatProps?: ICustomFormatProps,
  ): string;
  formatText(
    val: T,
    i18nPrefs?: IInternationalizationPreferences,
    customFormatProps?: ICustomFormatProps,
  ): string;
}

export const DATASET_EDITOR_PANELS = {
  DATA_PREVIEW: 'DATA_PREVIEW',
  ANNOTATIONS: 'ANNOTATIONS',
  FIELD_SETTINGS: 'FIELD_SETTINGS',
};

export const DEFAULT_NUM_GROUP_SEPARATOR = ',';
export const DEFAULT_DECIMAL_SEPARATOR = '.';

export const getNumberFormatSeparators = (
  i18nPrefs?: IInternationalizationPreferences,
): IInternationalizationPreferences => {
  const {
    numGroupSeparator = DEFAULT_NUM_GROUP_SEPARATOR,
    decimal = DEFAULT_DECIMAL_SEPARATOR,
  } = i18nPrefs ?? {};

  return {
    numGroupSeparator: _.isEmpty(numGroupSeparator)
      ? DEFAULT_NUM_GROUP_SEPARATOR
      : numGroupSeparator,
    decimal: _.isEmpty(decimal) ? DEFAULT_DECIMAL_SEPARATOR : decimal,
  };
};

export const replaceNumberSeparators = (
  strNumber = '',
  i18nPrefs: IInternationalizationPreferences = {},
): string => {
  const separators = getNumberFormatSeparators(i18nPrefs);

  return separators.numGroupSeparator === ',' && separators.decimal === '.'
    ? strNumber
    : _.replace(strNumber, /,|\./g, _char => {
        return _.get(
          {
            ',': separators.numGroupSeparator,
            '.': separators.decimal,
          },
          _char,
          _char,
        );
      });
};

/**
 * Base object for formatters. Most inherit the formatText and override format.
 */
export const FormatProto = class<T> implements IFormatProto<T> {
  dataType: string;
  formatType: string;
  displayName: string;
  formatExample(
    currencySymbol?: string,
    i18nPrefs: IInternationalizationPreferences = {},
  ): string {
    return replaceNumberSeparators(currencySymbol, i18nPrefs);
  }
  grouping: number;
  format(val: T): string {
    return val as any;
  }
  formatText(val: T): string {
    return this.format(val);
  }
};

export const DATA_FORMATTER: { [key: string]: IFormatProto } = {
  NUMBER: _.assign(new FormatProto<number>(), {
    dataType: 'Number',
    formatType: 'Number',
    displayName: 'dataFormats.number',
    formatExample: (value, i18nPrefs: IInternationalizationPreferences = {}) =>
      replaceNumberSeparators('1,000.23', i18nPrefs),
    grouping: 2,
    format: NullIfNaN(
      (value, i18nPrefs: IInternationalizationPreferences = {}) =>
        replaceNumberSeparators(d3Format(',.2f')(value), i18nPrefs),
    ),
    formatSmall: NullIfNaN(
      (value, i18nPrefs: IInternationalizationPreferences = {}) => {
        if (value < 100 && value > -100) {
          return replaceNumberSeparators(d3Format('.2f')(value), i18nPrefs);
        } else {
          return replaceNumberSeparators(
            d3Format('.3s')(value).replace('G', 'B'),
            i18nPrefs,
          );
        }
      },
    ),
  }),
  WHOLE_NUMBER: _.assign(new FormatProto<number>(), {
    dataType: 'Number',
    formatType: 'Whole Number',
    displayName: 'dataFormats.wholeNumber',
    formatExample: (value, i18nPrefs: IInternationalizationPreferences = {}) =>
      replaceNumberSeparators('1,023', i18nPrefs),
    grouping: 2,
    format: NullIfNaN(
      (value, i18nPrefs: IInternationalizationPreferences = {}) =>
        replaceNumberSeparators(d3Format(',.0f')(value), i18nPrefs),
    ),
    formatSmall: NullIfNaN(
      (value, i18nPrefs: IInternationalizationPreferences = {}) => {
        const noDecimal = Math.round(value);
        if (noDecimal < 100 && noDecimal > -100) {
          return replaceNumberSeparators(d3Format('.0f')(value), i18nPrefs);
        } else {
          return replaceNumberSeparators(
            d3Format('.3s')(noDecimal).replace('G', 'B'),
            i18nPrefs,
          );
        }
      },
    ),
  }),
  PERCENT: _.assign(new FormatProto<number>(), {
    dataType: 'Number',
    formatType: 'Percent',
    displayName: 'dataFormats.percent',
    formatExample: (value, i18nPrefs: IInternationalizationPreferences = {}) =>
      replaceNumberSeparators('10.23%', i18nPrefs),
    grouping: 2,
    format: NullIfNaN(
      (value, i18nPrefs: IInternationalizationPreferences = {}) =>
        replaceNumberSeparators(d3Format(',.2%')(value), i18nPrefs),
    ),
    formatSmall: NullIfNaN(
      (value, i18nPrefs: IInternationalizationPreferences = {}) => {
        if (value < 1 && value > -1) {
          return replaceNumberSeparators(d3Format('.1%')(value), i18nPrefs);
        } else {
          return replaceNumberSeparators(d3Format('.0%')(value), i18nPrefs);
        }
      },
    ),
  }),
  WHOLE_PERCENT: _.assign(new FormatProto<number>(), {
    dataType: 'Number',
    formatType: 'Whole Percent',
    displayName: 'dataFormats.wholePercent',
    formatExample: (value, i18nPrefs: IInternationalizationPreferences = {}) =>
      replaceNumberSeparators('10%', i18nPrefs),
    grouping: 2,
    format: NullIfNaN(
      (value, i18nPrefs: IInternationalizationPreferences = {}) =>
        replaceNumberSeparators(d3Format(',.0%')(value), i18nPrefs),
    ),
    formatSmall: NullIfNaN(
      (value, i18nPrefs: IInternationalizationPreferences = {}) =>
        replaceNumberSeparators(d3Format('.0%')(value), i18nPrefs),
    ),
  }),

  ACCOUNTING: _.assign(new FormatProto<number>(), {
    dataType: 'Number',
    formatType: 'Accounting',
    displayName: 'dataFormats.accounting',
    formatExample: (value, i18nPrefs: IInternationalizationPreferences = {}) =>
      replaceNumberSeparators('($1,000.23)', i18nPrefs),
    example: (
      currencySymbol = '$',
      i18nPrefs: IInternationalizationPreferences = {},
    ) => replaceNumberSeparators(`(${currencySymbol}1,000.23)`, i18nPrefs),
    grouping: 3,
    format: NullIfNaN(
      (value, i18nPrefs: IInternationalizationPreferences = {}) =>
        replaceNumberSeparators(`${d3Format('($,.2f')(value)}`, i18nPrefs),
    ),
    formatSmall: NullIfNaN(
      (value, i18nPrefs: IInternationalizationPreferences = {}) => {
        if (value < 100 && value > -100) {
          return replaceNumberSeparators(
            `${d3Format('($.2f')(value)}`,
            i18nPrefs,
          );
        } else {
          // derive the currency prefix. d3 has the ability to set the prefix/suffix for currency in Viz.configureCurrencySymbol
          const currencyPrefix = `${d3Format('$')(0)}`.slice(0, -1);
          const beforeSIPrefixReplaced = `${d3Format('($.3s')(value)}`;
          const number = _.join(
            _.invokeMap(
              _.split(beforeSIPrefixReplaced, currencyPrefix),
              String.prototype.replace,
              'G',
              'B', // replace 'B' SI-prefix when not the currency symbol
            ),
            currencyPrefix,
          );
          return replaceNumberSeparators(number, i18nPrefs);
        }
      },
    ),
  }),
  FINANCIAL: _.assign(new FormatProto<number>(), {
    dataType: 'Number',
    formatType: 'Financial',
    displayName: 'dataFormats.financial',
    formatExample: (value, i18nPrefs: IInternationalizationPreferences = {}) =>
      replaceNumberSeparators('(1,000.23)', i18nPrefs),
    grouping: 3,
    format: NullIfNaN(
      (value, i18nPrefs: IInternationalizationPreferences = {}) =>
        replaceNumberSeparators(d3Format('(,.2f')(value), i18nPrefs),
    ),
    formatSmall: NullIfNaN(
      (value, i18nPrefs: IInternationalizationPreferences = {}) => {
        if (value < 100 && value > -100) {
          return replaceNumberSeparators(d3Format('(.2f')(value), i18nPrefs);
        } else {
          return replaceNumberSeparators(
            d3Format('(.3s')(value).replace('G', 'B'),
            i18nPrefs,
          );
        }
      },
    ),
  }),
  CURRENCY: _.assign(new FormatProto<number>(), {
    dataType: 'Number',
    formatType: 'Currency',
    displayName: 'dataFormats.currency',
    formatExample: (value, i18nPrefs: IInternationalizationPreferences = {}) =>
      replaceNumberSeparators('-$1,000.23', i18nPrefs),
    example: (
      currencySymbol = '$',
      i18nPrefs: IInternationalizationPreferences = {},
    ) => replaceNumberSeparators(`-${currencySymbol}1,000.23`, i18nPrefs),
    grouping: 3,
    format: NullIfNaN(
      (value, i18nPrefs: IInternationalizationPreferences = {}) =>
        replaceNumberSeparators(d3Format('-$,.2f')(value), i18nPrefs),
    ),
    formatSmall: NullIfNaN(
      (value, i18nPrefs: IInternationalizationPreferences = {}) => {
        if (value < 100 && value > -100) {
          return replaceNumberSeparators(d3Format('-$.2f')(value), i18nPrefs);
        } else {
          // derive the currency prefix. d3 has the ability to set the prefix/suffix for currency in Viz.configureCurrencySymbol
          const currencyPrefix = `${d3Format('$')(0)}`.slice(0, -1);
          const beforeSIPrefixReplaced = `${d3Format('-$.3s')(value)}`;
          const number = _.join(
            _.invokeMap(
              _.split(beforeSIPrefixReplaced, currencyPrefix),
              String.prototype.replace,
              'G',
              'B', // replace 'B' SI-prefix when not the currency symbol
            ),
            currencyPrefix,
          );
          return replaceNumberSeparators(number, i18nPrefs);
        }
      },
    ),
  }),
  CUSTOM: _.assign(new FormatProto<number>(), {
    dataType: 'Number',
    formatType: 'Custom',
    displayName: 'dataFormats.custom',
    formatExample: _.constant(''),
    format: NullIfNaN(
      (
        value,
        i18nPrefs: IInternationalizationPreferences = {},
        customFormatProps: ICustomFormatProps,
      ) => {
        const {
          decimalPlaces,
          negativeFormat,
          prefixValue,
          suffixValue,
          includeSeparator,
          includeCurrency,
        } = !_.isEmpty(customFormatProps)
          ? customFormatProps
          : {
              decimalPlaces: 0,
              negativeFormat: { pre: '', post: '' },
              prefixValue: '',
              suffixValue: '',
              includeSeparator: false,
              includeCurrency: false,
            };
        const currencySymbol = '$';
        const cleanDecimal =
          !_.isNaN(decimalPlaces) && !_.isNil(decimalPlaces)
            ? decimalPlaces
            : 0;
        if (value >= 0) {
          return `${prefixValue}${includeCurrency ? currencySymbol : ''}${
            includeSeparator
              ? replaceNumberSeparators(
                  d3Format(`,.${cleanDecimal}f`)(value),
                  i18nPrefs,
                )
              : Number(value).toFixed(decimalPlaces)
          }${suffixValue}`.trim();
        } else {
          const displayVal = Math.abs(value);
          return `${prefixValue}${negativeFormat.pre}${
            includeCurrency ? currencySymbol : ''
          }${
            includeSeparator
              ? replaceNumberSeparators(
                  d3Format(`,.${cleanDecimal}f`)(displayVal),
                  i18nPrefs,
                )
              : Number(displayVal).toFixed(decimalPlaces)
          }${negativeFormat.post}${suffixValue}`.trim();
        }
      },
    ),
    formatSmall: NullIfNaN(
      (
        value,
        i18nPrefs: IInternationalizationPreferences = {},
        customFormatProps: ICustomFormatProps,
      ) => {
        const smallVal = customFormatProps?.includeCurrency
          ? DATA_FORMATTER.CURRENCY.formatSmall(
              value,
              i18nPrefs,
              customFormatProps,
            )
          : DATA_FORMATTER.NUMBER.formatSmall(
              value,
              i18nPrefs,
              customFormatProps,
            );
        return `${
          !_.isEmpty(customFormatProps?.prefixValue)
            ? customFormatProps?.prefixValue
            : ''
        }${smallVal}${
          !_.isEmpty(customFormatProps?.suffixValue)
            ? customFormatProps?.suffixValue
            : ''
        }`;
      },
    ),
    formatText: NullIfNaN(
      (
        value,
        i18nPrefs: IInternationalizationPreferences = {},
        customFormatProps: ICustomFormatProps,
      ) => {
        return DATA_FORMATTER.CUSTOM.format(
          value,
          i18nPrefs,
          customFormatProps,
        );
      },
    ),
    grouping: 4,
  }),
  STRING: _.assign(new FormatProto<string>(), {
    dataType: 'String',
    formatType: 'String',
    displayName: 'dataFormats.string',
    formatExample: _.constant(''),
    grouping: 1,
    format: value =>
      _.isString(value) ? handleEmptyString(value) : `${value}`,
    formatSmall: value => handleEmptyString(value),
  }),

  TIMESTAMP: _.assign(new FormatProto<Date | string>(), {
    dataType: 'Timestamp',
    formatType: 'Timestamp',
    displayName: 'dataFormats.timestamp',
    formatExample: _.constant('1/31/2018 3:50 PM'),
    grouping: 4,
    // Note, all epoch times coming from the query engine are already timezone adjusted
    format: (value, i18nPrefs) => {
      const dateFormat = convertSugarDateFormat(i18nPrefs?.dateFormat);
      const timeFormat = convertSugarTimeFormat(i18nPrefs?.timeFormat);
      const dateTimeFormat = `${dateFormat} ${timeFormat}`;
      return formatTimestamp(dateTimeFormat, value);
    },
    formatSmall: (value, i18nPrefs) => {
      const fmt = convertSugarDateFormat(i18nPrefs?.dateFormat);
      return formatTimestamp(fmt, value);
    },
  }),

  BOOLEAN: _.assign(new FormatProto<string>(), {
    dataType: 'Boolean',
    formatType: 'String',
    displayName: 'dataFormats.string',
    formatExample: _.constant(''),
    grouping: 1,
    format: value =>
      _.isString(value) ? handleEmptyString(value) : `${value}`,
    formatSmall: value => handleEmptyString(value),
  }),
};

const formatterMap = {};

const formatTimestamp = (fmt: any, value: any) => {
  if (_.isDate(value)) {
    return moment.utc(value).format(fmt);
  } else if (_.isString(value)) {
    if (value === NULL_DISPLAY || value === NULL_TOKEN) {
      return NULL_DISPLAY;
    }
    const offsetRegex = /[+-]\d{4}$/;
    const weekRegex = /^week\s+\d{1,2}$/i;
    const noOffset = value.replace(offsetRegex, '').trim();
    const dateToFormat = moment.utc(noOffset);
    return dateToFormat.isValid() && !value.match(weekRegex)
      ? dateToFormat.format(fmt)
      : value;
  } else {
    return value;
  }
};

const getFormatterByName = (formatType: string): IFormatProto => {
  // Search the formatters that are available in the format dropdowns
  let formatter = _.find(DATA_FORMATTER, { formatType });

  // if it is not one of those, it could be a custom format...
  if (_.isNil(formatter)) {
    formatter = _.get(formatterMap, formatType);
  }
  return formatter;
};

export const DELEGATING_FORMATTER = _.assign(new FormatProto(), {
  // change format?
  dataType: 'Custom',
  formatType: 'Format Function Formatter',
  displayName: 'dataFormats.formatFunctionFormatter',
  formatExample: (value, i18nPrefs: IInternationalizationPreferences = {}) =>
    replaceNumberSeparators('10.23% or 1.0 or 1', i18nPrefs),
  grouping: 1,
  format: (value, i18nPrefs: IInternationalizationPreferences = {}) => {
    let formatter = getFormatterByName(value.format);
    if (_.isNil(formatter)) {
      formatter = DATA_FORMATTER.NUMBER;
    }
    return formatter.format(value.val, i18nPrefs);
  },
  formatSmall: (value, i18nPrefs: IInternationalizationPreferences = {}) => {
    let formatter = getFormatterByName(value.format);
    if (_.isNil(formatter)) {
      formatter = DATA_FORMATTER.NUMBER;
    }
    return formatter.formatSmall(value.val, i18nPrefs);
  },
});

/**
 * The Standard D3 Percentage formatter multiplies by 100 before formatting. This is for non-fractional percentage
 * values which should not be modified.
 */
export const NON_FRACTIONAL_PERCENT_FORMATTER = _.assign(
  new FormatProto<number>(),
  {
    dataType: 'Number',
    formatType: 'Non Fractional Percent',
    displayName: 'dataFormats.nonFractionalPercent',
    formatExample: (value, i18nPrefs: IInternationalizationPreferences = {}) =>
      replaceNumberSeparators('10.23%', i18nPrefs),
    grouping: 2,
    format: (value, i18nPrefs: IInternationalizationPreferences = {}) =>
      DATA_FORMATTER.PERCENT.format(value / 100, i18nPrefs),
    formatSmall: (value, i18nPrefs: IInternationalizationPreferences = {}) =>
      DATA_FORMATTER.PERCENT.formatSmall(value / 100, i18nPrefs),
  },
);

export const TINY_NUMBER_FORMATTER = _.assign(new FormatProto<number>(), {
  dataType: 'Number',
  formatType: 'Tiny Number',
  displayName: 'dataFormats.tinyNumber',
  formatExample: (value, i18nPrefs: IInternationalizationPreferences = {}) =>
    replaceNumberSeparators('1,000.23', i18nPrefs),
  grouping: 2,
  format: NullIfNaN((value, i18nPrefs: IInternationalizationPreferences = {}) =>
    replaceNumberSeparators(d3Format(',.3s')(value), i18nPrefs),
  ),
  formatSmall: NullIfNaN(
    (value, i18nPrefs: IInternationalizationPreferences = {}) => {
      if (value < 100 && value > -100) {
        return replaceNumberSeparators(d3Format('.3s')(value), i18nPrefs);
      } else {
        return replaceNumberSeparators(
          d3Format('.3s')(value).replace('G', 'B'),
          i18nPrefs,
        );
      }
    },
  ),
});

export const DATE_FORMATTER = {
  ..._.cloneDeep(DATA_FORMATTER.TIMESTAMP),
  formatExample: _.constant('1/31/2018'),
  formatType: 'DATE',
  format: DATA_FORMATTER.TIMESTAMP.formatSmall,
  formatText: DATA_FORMATTER.TIMESTAMP.formatSmall,
};

Object.assign(formatterMap, {
  [NON_FRACTIONAL_PERCENT_FORMATTER.formatType]: NON_FRACTIONAL_PERCENT_FORMATTER,
  [DELEGATING_FORMATTER.formatType]: DELEGATING_FORMATTER,
  [TINY_NUMBER_FORMATTER.formatType]: TINY_NUMBER_FORMATTER,
  [DATE_FORMATTER.formatType]: DATE_FORMATTER,
});

export const AXIS_FORMATTER = {
  NUMBER_AXIS: _.assign(new FormatProto<number>(), {
    dataType: 'Number',
    formatType: 'Number',
    displayName: 'dataFormats.number',
    formatExample: (value, i18nPrefs: IInternationalizationPreferences = {}) =>
      replaceNumberSeparators('1,000.23', i18nPrefs),
    grouping: 2,
    format: NullIfNaN(
      (value, i18nPrefs: IInternationalizationPreferences = {}) =>
        replaceNumberSeparators(d3Format(',.2f')(value), i18nPrefs),
    ),
    formatSmall: NullIfNaN(
      (value, i18nPrefs: IInternationalizationPreferences = {}) => {
        if (value === 0) {
          return '0';
        } else if (value < 100 && value > -100) {
          return replaceNumberSeparators(d3Format('.1f')(value), i18nPrefs);
        } else {
          return replaceNumberSeparators(
            d3Format('.3s')(value).replace('G', 'B'),
            i18nPrefs,
          );
        }
      },
    ),
  }),
};
export const DATA_TYPE_FORMAT = {
  getFormattersByDataType: (dataType: string): IFormatProto[] => {
    return _(DATA_FORMATTER)
      .values()
      .filter(df => df.dataType.toLowerCase() === dataType.toLowerCase())
      .tap((_formatters: IFormatProto[]) => {
        // default to string formatter
        return !_.isEmpty(_formatters)
          ? _formatters
          : _formatters.push(
              DATA_TYPE_FORMAT.getDefaultFormatterForType(dataType),
            );
      })
      .value();
  },
  getDefaultFormatterForType: (dataType: string): IFormatProto => {
    if (_.isNil(dataType)) {
      return DATA_FORMATTER.STRING;
    }
    switch (dataType.toLowerCase()) {
      case 'calc':
      case 'prior_period_calc':
      case 'number':
        return DATA_FORMATTER.NUMBER;
      case 'time_calc':
      case 'timestamp':
        return DATA_FORMATTER.TIMESTAMP;
      default:
        return DATA_FORMATTER.STRING;
    }
  },
  getFormatterByName,
};

// recommended regex for email validation from W3C: https://www.w3.org/TR/html5/forms.html#valid-e-mail-address
export const EMAIL_REGEX = /^[\w!#$%&'*+./=?^`{|}~-]+@[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?(?:\.[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?)*$/;
export const URL_REGEX = /https?:\/\/(www\.)?[\w#+.=~-]{2,256}(\.[a-z]{2,6})?(:\d{1-5})?/;

/* Pivot Table */
export const VIZ_QUERY_TOTALS_FLAG = '__ALL__';

const COLORS = {
  GREEN: PivotGreen,
  YELLOW: PivotYellow,
  RED: PivotRed,
  WHITE: PivotWhite,
  BLUE: PivotBlue,
};
export const PIVOT_TABLE = {
  MIN_CELL_WIDTH: 110,
  MAX_CELL_WIDTH: 200,
  REPEATS_INDEX: 1,
  COLOR_SCALES: {
    GYR: {
      key: 'GYR',
      name: 'formatting.colorScale.GYR',
      colors: [COLORS.GREEN, COLORS.YELLOW, COLORS.RED],
      swatch: `linear-gradient(to right, ${COLORS.GREEN}, ${COLORS.YELLOW}, ${COLORS.RED})`,
    },
    RYG: {
      key: 'RYG',
      name: 'formatting.colorScale.RYG',
      colors: [COLORS.RED, COLORS.YELLOW, COLORS.GREEN],
      swatch: `linear-gradient(to right, ${COLORS.RED}, ${COLORS.YELLOW}, ${COLORS.GREEN})`,
    },
    GWR: {
      key: 'GWR',
      name: 'formatting.colorScale.GWR',
      colors: [COLORS.GREEN, COLORS.WHITE, COLORS.RED],
      swatch: `linear-gradient(to right, ${COLORS.GREEN}, ${COLORS.WHITE}, ${COLORS.RED})`,
    },
    RWG: {
      key: 'RWG',
      name: 'formatting.colorScale.RWG',
      colors: [COLORS.RED, COLORS.WHITE, COLORS.GREEN],
      swatch: `linear-gradient(to right, ${COLORS.RED}, ${COLORS.WHITE}, ${COLORS.GREEN})`,
    },
    RWB: {
      key: 'RWB',
      name: 'formatting.colorScale.RWB',
      colors: [COLORS.RED, COLORS.WHITE, COLORS.BLUE],
      swatch: `linear-gradient(to right, ${COLORS.RED}, ${COLORS.WHITE}, ${COLORS.BLUE})`,
    },
    BWR: {
      key: 'BWR',
      name: 'formatting.colorScale.BWR',
      colors: [COLORS.BLUE, COLORS.WHITE, COLORS.RED],
      swatch: `linear-gradient(to right, ${COLORS.BLUE}, ${COLORS.WHITE}, ${COLORS.RED})`,
    },
    GW: {
      id: 'GW',
      name: 'formatting.colorScale.GW',
      colors: [COLORS.GREEN, COLORS.WHITE],
      swatch: `linear-gradient(to right, ${COLORS.GREEN}, ${COLORS.WHITE})`,
    },
    WG: {
      id: 'WG',
      name: 'formatting.colorScale.WG',
      colors: [COLORS.WHITE, COLORS.GREEN],
      swatch: `linear-gradient(to right, ${COLORS.WHITE}, ${COLORS.GREEN})`,
    },
    RW: {
      key: 'RW',
      name: 'formatting.colorScale.RW',
      colors: [COLORS.RED, COLORS.WHITE],
      swatch: `linear-gradient(to right, ${COLORS.RED}, ${COLORS.WHITE})`,
    },
    WR: {
      key: 'WR',
      name: 'formatting.colorScale.WR',
      colors: [COLORS.WHITE, COLORS.RED],
      swatch: `linear-gradient(to right, ${COLORS.WHITE}, ${COLORS.RED})`,
    },
  },
};

export const HANDLE_NULL_AS = {
  MISSING: 'missing',
  ZERO: 'zero',
};
export const _private = {
  NullIfNaN,
};
export const Labels = {
  DISCOVERY_INSIGHTS: 'discoveryToolbar.insightsPanel',
  COMMENTS: 'discussion.title',
};

export const IDM_ROLES = {
  ADMINISTRATOR: 'ADMINISTRATOR',
  REGULAR_USER: 'REGULAR_USER',
};

export const MAX_ALIAS_LENGTH = 32;

export enum DATASET_STATUS {
  PREPARING = 'Preparing',
  INGESTING = 'Ingesting',
  ERROR = 'Error',
}

export enum PERIOD_TYPE {
  CALENDAR_WEEK = 'CALENDAR_WEEK',
  CALENDAR_MONTH = 'CALENDAR_MONTH',
  MONTH = 'MONTH',
  CALENDAR_QUARTER = 'CALENDAR_QUARTER',
  QUARTER = 'QUARTER',
  CALENDAR_YEAR = 'CALENDAR_YEAR',
  YEAR = 'YEAR',
}

export const USE_FISCAL_REPORTING = 'useFiscalCalendar';
export const FISCAL_CALENDAR_START_DATE = 'fiscalCalendarStartDate';
export const FISCAL_CALENDAR_YEAR_TYPE = 'fiscalCalendarYearType';
export const DEFAULT_MODULES_TO_LOAD = 'Users, Teams';

export const SUGAR_MODULES = {
  FORECASTS: 'FORECASTS',
};

export const Types = {
  ANY: 'ANY',
  BOOLEAN: 'BOOLEAN',
  NUMBER: 'NUMBER',
  TIMESTAMP: 'TIMESTAMP',
  STRING: 'STRING',
  CALC: 'CALC',
  TIME_CALC: 'TIME_CALC',
  PRIOR_PERIOD_CALC: 'PRIOR_PERIOD_CALC',
  STRING_CALC: 'STRING_CALC',
  DATE: 'DATE',
  NUMERIC: 'NUMERIC',
};

export type FieldTypes = keyof typeof Types;

export const FIELD_ORDINAL_SUFFIX = ':: ordinal';

export const ChartSpecIds = {
  ENTRANCE: 'ENTRANCE',
  CHANGE: 'CHANGE',
  EXIT: 'EXIT',
  ROWS: 'ROWS',
  COLUMNS: 'COLUMNS',
  VALUES: 'VALUES',
  EXTRA_VALUES: 'EXTRA_VALUES',
  SLICER: 'SLICER',
  LINES: 'LINES',
  XAXIS: 'XAXIS',
  STACK: 'STACK',
};

export const OPEN_VIZ_PATTERN_SUFFIX = ':openDiscoveryId';
export const ROUTER_DIRS = {
  ROOT: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  REPORT_LINK: '/reportLink',
  NEW_VIZ: '/newviz',
  ACCOUNT: '/account',
  DATASETS: '/datasets',
  LIBRARY: '/library',
  ADMIN: '/administration',
  OPEN_VIZ_PATTERN: `/open/${OPEN_VIZ_PATTERN_SUFFIX}`,
  OPEN_VIZ: (vizId = '') =>
    ROUTER_DIRS.OPEN_VIZ_PATTERN.replace(OPEN_VIZ_PATTERN_SUFFIX, vizId),
};

export type ShelfName = keyof typeof ChartSpecIds;
