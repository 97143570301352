import StackPlot from '../plots/StackPlot';
import { Viz } from '../../VizUtil';
import StackChartUtils from '../StackChartUtils';
import { BarUtils } from '../../../common/d3/Bars';
import Stacks from '../../../common/d3/Stacks';
import _ from 'lodash';
import { METRIC_ORIENTATION } from '../../../common/d3/Axis';
import Util from '../../../common/Util';
import palette from '../../../common/d3/ColorPalette';
import { DefaultFontName } from '../../../components/ui/fonts';
import { max as d3Max, min as d3Min, scaleBand, scaleLinear } from 'd3';

class StackBarPlot extends StackPlot {
  constructor(parameters) {
    super(parameters);
    this.maxChars = this.isMobile ? 12 : 24;
    // determine how wide text will be with our max number of characters (not exact since we don't use a fixed width font, but close enough)
    this.maxAxisLabelWidth =
      Util.calcTextWidth(
        _.truncate('the quick brown fox jumped over the moon', {
          length: this.maxChars,
        }),
        `10px ${DefaultFontName}`,
      ) + 10;
    this.useNiceScale = true;
    this.customFormatToggles = parameters.customFormatToggles || [];
  }

  // eslint-disable-next-line lodash/prefer-constant
  getType() {
    return 'stack_bar';
  }

  getYAxisLabel() {
    if (!_.isEmpty(this.layout.XAXIS)) {
      return Viz.getAxisLabel(this.layout.XAXIS);
    }
  }

  getXAxisLabel() {
    return Viz.getAxisLabel(this.layout[this.valuesName]);
  }

  // eslint-disable-next-line lodash/prefer-constant
  shouldRotateAxisLabels() {
    return false;
  }

  getXScale() {
    return this.xScale;
  }

  getYScale() {
    return this.scales.groupScaleDefault;
  }

  getBarPadding(numberOfBars) {
    return BarUtils.getBarPadding(numberOfBars);
  }

  getScaleBuffer() {
    return this.isMobile ? 0.25 : 0.1;
  }

  createScales(width, height) {
    const { data, chartPadding } = this;

    const scaleData = _.isFunction(this.getVisibleData)
      ? this.getVisibleData()
      : data;

    if (_.isUndefined(height)) {
      height = this.height;
    }
    let padProps = this.getBarPadding(scaleData.length);
    if (!_.isEmpty(chartPadding)) {
      padProps = chartPadding;
    }
    const { barPadding, barPaddingOuter } = padProps;

    const group = scaleBand()
      .domain(scaleData.map(d => StackChartUtils.getX(d)))
      .range([0, height])
      .paddingInner(barPadding)
      .paddingOuter(barPaddingOuter);

    const y = scaleBand()
      .domain([])
      .range([group.bandwidth(), 0]);

    let bounds = scaleData.reduce(
      (accum, current) => {
        const min = d3Min(current.VALUES, v => v.bottom);
        const max = d3Max(current.VALUES, v => v.top);
        accum[0] = Math.min(accum[0], min);
        accum[1] = Math.max(accum[1], max);
        return accum;
      },
      [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER],
    );

    const isPercentage = this.showAsPercentage();
    if (isPercentage) {
      bounds = [d3Min(bounds) < 0 ? -1 : 0, d3Max(bounds) <= 0 ? 0 : 1];
    }

    // add some buffer to the domain
    const bufferVal = this.getScaleBuffer();
    bounds[0] += bounds[0] * bufferVal;
    bounds[1] += bounds[1] * bufferVal;

    const x = scaleLinear()
      .range([0, width])
      .domain(bounds);

    return {
      groupScaleDefault: group,
      xScaleDefault: this.useNiceScale ? x.nice() : x,
      yScaleDefault: y,
    };
  }

  getPreferredWidth() {
    let w = this.width;
    if (this.getYAxisWidth() > this.defaultYAxisWidth) {
      w = this.width - (this.getYAxisWidth() - this.defaultYAxisWidth);
    }
    return w - 10;
  }

  getPreferredHeight() {
    let adjustedChartHeight = this.height;
    let adjustedYScale = this.scales.yScaleDefault;
    while (
      this.layout[this.valuesName].length > 0 &&
      adjustedYScale.bandwidth() < 18
    ) {
      const currentHeight = adjustedYScale.bandwidth();
      adjustedChartHeight = (18 / currentHeight) * adjustedChartHeight;
      adjustedYScale = this.createScales(this.width, adjustedChartHeight)
        .yScaleDefault;
    }
    return adjustedChartHeight;
  }

  getMetricOrientation() {
    return METRIC_ORIENTATION.HORIZONTAL;
  }

  getYAxisWidth() {
    const labelInfo = Util.calcLabelInfo(
      this.scales.groupScaleDefault.domain().map(d => d),
      `10px ${DefaultFontName}`,
    );
    return (
      this.defaultYAxisWidth +
      Math.min(this.maxAxisLabelWidth, labelInfo.maxWidth) -
      16
    );
  }

  setWidth(width) {
    this.width = width;
    this.scales = this.createScales(this.width);
    this.xScale = this.scales.xScaleDefault;
    this.yScale = this.scales.groupScaleDefault;
  }

  _getColorPalette() {
    return palette;
  }

  showAsPercentage() {
    const percentageToggle = this.customFormatToggles
      ? this.customFormatToggles.find(t => t.key === 'asPercentage')
      : null;
    const isPercentage = _.isNil(percentageToggle)
      ? false
      : percentageToggle.on;

    return isPercentage;
  }

  getComponent(props) {
    return (
      <Stacks
        ref={stacks => (this.stacks = stacks)}
        isHorizontal={true}
        isMobile={this.isMobile}
        {...props}
        xScale={this.xScale}
        yScale={this.yScale}
        groupScale={this.scales.groupScaleDefault}
        getX={d => StackChartUtils.getY(d)}
        getY={d => StackChartUtils.getX(d)}
        getStack={d => StackChartUtils.getStack(d)}
        height={this.height}
        width={this.width}
        data={this.data}
        chartPadding={this.presetPadding}
        showLabelsInsideBar={false}
        globalMouseTooltipEnabled={false}
        vizId={this.vizId}
        getStackColor={(dataItem, defaultColor) =>
          this.getStackColor(dataItem, defaultColor)
        }
        colorPalette={this._getColorPalette()}
        onHover={(data, x, y) => this.hoverFunction(data, this.getType(), x, y)}
        customFormatToggles={this.customFormatToggles}
        enableReportLink={this.enableReportLink}
        nullHandling={this.nullHandling}
        focusedData={this.focusedData}
        customFormatProps={this.customFormatProps}
      />
    );
  }
}

export default StackBarPlot;
