import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScrollingNav } from '../../components/scrolling-nav';
import { VizComponent as Viz } from '../viz';
import Discover from '../../common/redux/actions/DiscoverActions';
import _, { isNil } from 'lodash';
import { useHistory } from '../../common/utilities/with-router';
import { getIsReadOnlyUser } from '../../common/redux/selectors/AccountSelectors';
import { messages } from '../../i18n';
import { AddIcon, CloseIcon } from '../../icons/icons';
import { IndicatorBubble } from '../../icons/sugar-icon/sugar-icon.util';
import {
  TabStyled,
  CloseDiscovery,
  DiscoveryDirtyIndicator,
  AddDiscovery,
} from './discover-tabs.styles';
import { IRevision } from '../interfaces';
import { MainSectionHeader } from '../../components/main-section-header';
import { TruncatedTooltip } from '../../components/ui/truncated-tooltip';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { VizQueries } from '../../common/graphql';
import { OPEN_VIZ_PATTERN_SUFFIX, ROUTER_DIRS } from '../../common';

// renders full Viz control panel. should rename
export const DiscoverTabs = () => {
  const {
    openDiscoveries,
    openDiscoveriesTabList,
    isMobile,
    isUserReadOnly,
  } = useSelector((state: any) => {
    const firstOpen = _.keys(state.discover.openDiscoveries)[0];
    const isDisplayDiscovery = _.get(
      state,
      'discover.displayDiscovery',
      firstOpen,
    );
    return {
      openDiscoveries: state.discover.openDiscoveries,
      openDiscoveriesTabList: state.discover.openDiscoveriesTabList,
      displayDiscovery: isDisplayDiscovery || firstOpen,
      isMobile: state.main.isMobile,
      isUserReadOnly: getIsReadOnlyUser(state),
    };
  });
  const {
    [OPEN_VIZ_PATTERN_SUFFIX.replace(':', '')]: discoveryId,
  } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const navigate = useNavigate();

  const isOpen = !_.isNil(_.get(openDiscoveries, discoveryId));

  const openVisualization = useCallback(
    discovery => {
      dispatch(Discover.openVisualization(discovery));
    },
    [dispatch],
  );

  const [getViz, { loading: vizLoading }] = useLazyQuery(
    VizQueries.GetVisualization,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (!(isNil(discoveryId) || isOpen || vizLoading)) {
      getViz({
        variables: { id: discoveryId },
        onCompleted: ({ visualization }) => {
          if (visualization) {
            openVisualization(visualization);
          } else {
            navigate(ROUTER_DIRS.ROOT);
          }
        },
      });
    }
  }, [discoveryId, getViz, isOpen, openVisualization, vizLoading, navigate]);

  const setDisplayDiscovery = id => {
    dispatch(Discover.setDisplayDiscovery(id));
  };
  const closeDiscovery = (id, force = false) => {
    dispatch(Discover.closeDiscovery(id, { force }));
  };
  const showNewViz = () => {
    history.push(ROUTER_DIRS.NEW_VIZ);
  };

  // maintaining order with open discoveries
  const existingDiscoveryIds = _.filter(
    openDiscoveriesTabList,
    discId => !!openDiscoveries[discId],
  );
  const discoveryNavItems = _.map(existingDiscoveryIds, discoveryId => {
    const discovery = openDiscoveries[discoveryId];
    const { id, name, dirty, canUpdate, viz, updatedOn } = discovery.present;
    const lastRevision: IRevision = _.head(viz?.revisions);
    const isNewViz =
      _.startsWith(name, 'Untitled') &&
      _.startsWith(id, 'newViz-') &&
      _.isEmpty(viz?.revisions);
    const hasUnversionedSaves =
      _.isEmpty(lastRevision) || !_.isEqual(updatedOn, lastRevision.updatedOn);
    return (
      <TabStyled
        key={id}
        label={
          <>
            {canUpdate && dirty && (
              <DiscoveryDirtyIndicator
                title={messages.viz.unsavedChangesAndVersion}
              >
                <IndicatorBubble size={8} />
              </DiscoveryDirtyIndicator>
            )}
            {!_.isNil(viz) &&
              !isNewViz &&
              canUpdate &&
              !dirty &&
              hasUnversionedSaves && (
                <DiscoveryDirtyIndicator title={messages.viz.unsavedVersion}>
                  <IndicatorBubble size={8} empty={true} />
                </DiscoveryDirtyIndicator>
              )}
            <TruncatedTooltip
              arrow
              placement='bottom'
              title={name}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                },
              }}
            />
            <CloseDiscovery
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                closeDiscovery(id, isUserReadOnly);
              }}
            >
              <CloseIcon size={10} />
            </CloseDiscovery>
          </>
        }
        value={id}
      />
    );
  });

  return (
    <div className='discover-open-panel'>
      <div className='discover-tab-panel'>
        <MainSectionHeader headerLabel={messages.nav.reports} />
        <ScrollingNav
          activeKey={discoveryId}
          navItems={discoveryNavItems}
          handleSelect={id => setDisplayDiscovery(id)}
          addItem={
            !isMobile &&
            !isUserReadOnly && (
              <AddDiscovery
                className={'sticky sticky-right'}
                key={'add-discovery'}
                title={messages.addReportTooltip}
                onClick={() => showNewViz()}
              >
                <AddIcon hover />
              </AddDiscovery>
            )
          }
        />
        {isOpen && <Viz vizId={discoveryId} />}
      </div>
    </div>
  );
};
