import { withTheme } from '@emotion/react';
import { ComponentClass, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { compose, shouldUpdate } from 'react-recompose';
import _ from 'lodash';
import Main from '../../common/redux/actions/MainActions';
import Login from '../../common/redux/actions/LoginActions';
import { withRouter } from '../../common/utilities/with-router';
import {
  ACCOUNT_SELECTORS,
  isAdmin,
  getIsReadOnlyUser,
} from '../../common/redux/selectors/AccountSelectors';
import ConfirmLogoutPrompt from '../../components/ConfimLogoutPrompt';
import { messages } from '../../i18n';
import Util from '../../common/Util';
import { AccountIcon, DiscoverIcon, FeedIcon, LibraryIcon } from '../../icons';
import { getIsDarkModeEnabled } from '../../common/redux/selectors/main-selector-hooks';
import {
  HeaderBar,
  IconContainer,
  ModernProfileDropdownRoot,
} from './header.styles';
import { PinnedVizQuery } from '../../common/graphql/util';
import { IHeaderProps } from './interfaces';
import { FeatureToggle } from '../../common/utilities/feature-flag/feature-toggle';
import { SugarProfileDropdown } from '../../components/sugar-profile-dropdown';
import { usePinnedDiscoveries } from './header.hook';

const HeaderComponent = ({
  loadPinnedDiscoveries, // control for pinned visualizations
  pinnedDiscoveries,
  openDiscoveries,
  location,
  history,
  isAdmin,
  isMobile,
  isAdvancedMode,
  feedAvailable,
  feed,
  isReadOnly,
  theme = {},
  currentUser,
  appUrl = '',
}: IHeaderProps) => {
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const { colors: { PrimaryColor = '' } = {} } = theme || ({} as any);
  const feedEnabled = (feedAvailable || !_.isEmpty(feed)) && !isReadOnly;

  usePinnedDiscoveries({
    loadPinnedDiscoveries,
    pinnedDiscoveries,
    openDiscoveries,
  });

  const locationPathname = location?.pathname;

  const menuItemSelected = useCallback(
    eventKey => {
      if (eventKey === 'logout') {
        setShowLogoutDialog(true);
      } else if (eventKey === 'account') {
        eventKey = 'account';
      }
      if (!(eventKey === 'open' && locationPathname?.startsWith('/open'))) {
        history.push(`/${eventKey}`);
      }
    },
    [setShowLogoutDialog, history, locationPathname],
  );

  const path = history?.location?.pathname ?? '';
  const pathMatch = /\/([^/]*)\/*/?.exec(path);
  const activeTab = pathMatch ? pathMatch[1] : '';

  const isActiveTab = key => {
    return activeTab === key;
  };

  const getIconColor = isActiveRoute =>
    isActiveRoute ? PrimaryColor : undefined;

  const currentUserTenant = currentUser?.tenant;

  const modernImgSrc = Util.assetUrl({
    appUrl,
    path: 'discover_logo_modern.svg',
  });
  const ModernProfileDropdown = () => (
    <ModernProfileDropdownRoot>
      {isAdvancedMode && (
        <>
          <span className={'tenant-name'}>{currentUserTenant}</span>
          <FeatureToggle />
        </>
      )}
      <SugarProfileDropdown
        isAdmin={isAdmin}
        setShowLogoutDialog={setShowLogoutDialog}
        menuItemSelected={menuItemSelected}
      />
    </ModernProfileDropdownRoot>
  );

  const MobileNav = () => (
    <div
      id='mainHeader'
      style={{ flex: 'auto' }}
      className={feedEnabled && isActiveTab('') ? ' feedActive' : ''}
    >
      <div className='MobileHeader'>
        {feedEnabled && (
          <IconContainer className={'Icon'}>
            <FeedIcon
              onClick={() => menuItemSelected('')}
              hover
              color={getIconColor(isActiveTab(''))}
            />
            <span>{messages.nav.feed}</span>
          </IconContainer>
        )}
        <IconContainer className={'Icon'}>
          <DiscoverIcon
            onClick={() => menuItemSelected('open')}
            hover
            color={getIconColor(isActiveTab('open'))}
          />
          <span>{messages.nonTranslated.discover}</span>
        </IconContainer>
        <IconContainer className={'Icon'}>
          <LibraryIcon
            onClick={() => menuItemSelected('library')}
            hover
            color={getIconColor(isActiveTab('library'))}
          />
          <span>{messages.nav.library}</span>
        </IconContainer>
        <IconContainer className={'Icon'}>
          <AccountIcon
            onClick={() => menuItemSelected('account')}
            hover
            color={getIconColor(isActiveTab('account'))}
          />
          <span>{messages.nav.account}</span>
        </IconContainer>
      </div>
    </div>
  );

  return (
    <HeaderBar>
      {!isMobile ? (
        <>
          <img src={modernImgSrc} className='logo updatedLogo2' />
          <ModernProfileDropdown />
        </>
      ) : (
        <MobileNav />
      )}

      {showLogoutDialog && (
        <ConfirmLogoutPrompt onHide={() => setShowLogoutDialog(false)} />
      )}
    </HeaderBar>
  );
};

const mapStateToProps = function(state) {
  const {
    main: {
      activeTab,
      activeSubmenuTab,
      appUrl,
      isMobile,
      advanced: isAdvancedMode,
      activityFeedAvailable: feedAvailable,
    },
    discover: { openDiscoveries },
    account: { currentUser },
  } = state;

  const isDarkModeEnabled = getIsDarkModeEnabled(state);

  return {
    activeTab,
    isDarkModeEnabled,
    activeSubmenuTab,
    openDiscoveries,
    currentUser,
    isAdmin: isAdmin(state.account),
    isReadOnly: getIsReadOnlyUser(state),
    isMobile,
    isAdvancedMode,
    pinnedDiscoveryIds: ACCOUNT_SELECTORS.getPinnedDiscoveries(state),
    feedAvailable,
    appUrl,
  };
};

const mapDispatchToProps = function(dispatch) {
  return {
    setActiveTab(id) {
      dispatch(Main.setActiveTab(id));
    },
    setActiveSubmenuTab(activeTab, submenuTab) {
      dispatch(Main.setActiveTab(activeTab));
      dispatch(Main.setActiveSubmenuTab(activeTab, submenuTab));
    },
    logout() {
      Login.logout(dispatch);
    },
  };
};

export const Header = compose<IHeaderProps, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTheme,
  shouldUpdate((curr: any, next: any) => {
    return (
      !![
        'activeTab',
        'isDarkModeEnabled',
        'activeSubmenuTab',
        'currentUser',
        'isAdmin',
        'isAdvancedMode',
        'isMobile',
        'pinnedDiscoveryIds',
        'feedAvailable',
      ].find(x => !_.isEqual(curr[x], next[x])) ||
      !_.isEqual(
        Object.keys(curr.openDiscoveries),
        Object.keys(next.openDiscoveries),
      ) ||
      !_.isEqual(curr.location.pathname, next.location.pathname)
    );
  }),
  PinnedVizQuery,
)(HeaderComponent) as ComponentClass<Partial<IHeaderProps>, any>;
