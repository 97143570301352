import { Component } from 'react';
import Stacks, { OUTSIDE_LABEL_WIDTH, TOTALS_LABEL_POSITION } from './Stacks';
import StackChartUtils from '../../discovery/charts/StackChartUtils';
import { DATA_FORMATTER } from '../Constants';
import Util from '../Util';
import _, { clamp, get, includes, split } from 'lodash';
import { messages } from '../../i18n';
import { DefaultFontName } from '../../components/ui/fonts';
import { useDiscoverTheme } from '../emotion';
import { connect } from 'react-redux';
import { pointer, select } from 'd3';

const WIDTH_ADJUST = 38;
export const getStackOffset = (isAvgdaysinstage, isMobile) => {
  const paddingRight = isMobile ? 8 : 16;
  return isAvgdaysinstage ? WIDTH_ADJUST + paddingRight : 0;
};

const AsidePath = ({ roundedBox, size, height }) => {
  const {
    colors: { VizBarColorLight },
  } = useDiscoverTheme();
  return (
    <path
      className='aside'
      d={roundedBox}
      fill={VizBarColorLight}
      transform={`translate(0, ${(size - height) / 2})`}
    />
  );
};

class UnconnectedFunnel extends Component {
  static defaultProps = {
    i18nPrefs: {},
  };

  deltasContainer;
  deltaContainerClassName = 'funnel-delta-label-group';
  deltaLabelClassName = 'funnel-delta-label';

  constructor() {
    super();
    this.handleOnHover = this.handleOnHover.bind(this);
  }

  componentDidMount() {
    this.handleDeltaHover();
  }

  componentDidUpdate() {
    // this is not ideal
    this.handleDeltaHover();
  }

  handleStackHover(data, x, y) {
    if (_.isFunction(this.props.onHover)) {
      if (!_.isNil(data)) {
        const fieldName = data.IS_COLOR_STACK
          ? data.valueColName
          : data.stackName;
        const totalRecords = data.TOTAL_RECORDS;
        const numRecords = data[fieldName];

        const totalRecordsFormatted = DATA_FORMATTER.WHOLE_NUMBER.format(
          totalRecords,
          this.props.i18nPrefs,
        );

        const numRecordsFormatted = DATA_FORMATTER.WHOLE_NUMBER.format(
          numRecords,
          this.props.i18nPrefs,
        );

        const dividendRecordValue = data.IS_COLOR_STACK
          ? data.STACK_TOTAL_RECORDS
          : totalRecords;

        const conversionPercent = DATA_FORMATTER.WHOLE_PERCENT.format(
          dividendRecordValue === 0 ? 0 : numRecords / dividendRecordValue,
          this.props.i18nPrefs,
        );

        const tooltipInfoMessage = data.IS_COLOR_STACK
          ? messages.formatString(
              messages.funnel.progressionValueSegmentTooltip,
              numRecordsFormatted,
              DATA_FORMATTER.WHOLE_NUMBER.format(
                data.STACK_TOTAL_RECORDS,
                this.props.i18nPrefs,
              ),
              conversionPercent,
              <span style={{ fontWeight: 'bold' }}>{data.stackName}</span>,
              <span style={{ fontWeight: 'bold' }}>{data.NAME}</span>,
            )
          : messages.formatString(
              messages.funnel.progressionValueTooltip,
              numRecordsFormatted,
              totalRecordsFormatted,
              conversionPercent,
              <span style={{ fontWeight: 'bold' }}>{data.NAME}</span>,
            );

        const obj = { tooltipInfo: data, tooltipInfoMessage };
        this.props.onHover(obj, x, y);
      } else {
        this.props.onHover(null, x, y);
      }
    }
  }

  handleFullStackHover(data, x, y) {
    if (_.isFunction(this.props.onHover)) {
      const formatter = _.head(data?.VALUES)?.formatter;
      const valFormatter = !_.isNil(formatter)
        ? formatter
        : DATA_FORMATTER.WHOLE_NUMBER;
      const numRecords = valFormatter.format(
        data?.STAGE_RECORD_COUNT,
        this.props.i18nPrefs,
      );

      const avgDaysInStage = DATA_FORMATTER.WHOLE_NUMBER.format(
        _.floor(data?.AVG_DAYS_IN_STAGE),
        this.props.i18nPrefs,
      );

      const tooltipInfoMessage = messages.formatString(
        messages.funnel.avgDaysInStageTooltip,
        numRecords,
        <span style={{ fontWeight: 'bold' }}>{data?.NAME}</span>,
        avgDaysInStage,
      );
      const obj = { tooltipInfo: data, tooltipInfoMessage };
      if (!_.isNil(data)) {
        this.props.onHover(obj, x, y);
      } else {
        this.props.onHover(null, x, y);
      }
    }
  }

  handleOnHover(event, d) {
    const elementClasses = split(
      get(event.target, 'className.baseVal', event.target.className),
      ' ',
    );
    if (_.isFunction(this.props.onHover)) {
      event.stopPropagation();
      // get a tooltip for one of the slices of the stack and remove the specifics
      const [xRelToContainer, yRelToContainer] = pointer(
        event,
        this.deltasContainer,
      );

      const selectionValue = DATA_FORMATTER.WHOLE_NUMBER.format(
        d.selectionValue,
        this.props.i18nPrefs,
      );

      const displayStageValue = includes(elementClasses, 'delta-percentage')
        ? d.initialValue
        : d.previousSelectionValue;

      const formattedStageValue = DATA_FORMATTER.WHOLE_NUMBER.format(
        displayStageValue,
        this.props.i18nPrefs,
      );
      const tooltipInfoMessage = messages.formatString(
        messages.funnel.funnelValueDeltaTooltip,
        selectionValue,
        formattedStageValue,
        <span style={{ fontWeight: 'bold' }}>{d.previousStage}</span>,
      );
      const obj = { tooltipInfo: d, tooltipInfoMessage };
      if (!_.isNil(obj)) {
        this.props.onHover(obj, xRelToContainer + 10, yRelToContainer + 10);
      }
    }
  }

  handleDeltaHover() {
    // removes first 'zero' delta. The zero step is not shown
    const deltas = _.tail(this.getDeltaData());

    select(this.deltasContainer)
      .selectAll(`.${this.deltaLabelClassName}`)
      .data(deltas)
      .on('mousemove', (event, data) => this.handleOnHover(event, data))
      .on('mouseout', event => {
        if (_.isFunction(this.props.onHover)) {
          event.stopPropagation();
          if (_.isFunction(this.props.onHover)) {
            const [xRelToContainer, yRelToContainer] = pointer(
              event,
              this.deltasContainer,
            );

            const [x, y] = pointer(event.target);
            const bounds = event.target.getBoundingClientRect();
            if (x > 0 && x < bounds.width && y > 0 && y < bounds.height) {
              // Were still over ignore
            } else {
              this.props.onHover(null, xRelToContainer, yRelToContainer);
            }
          }
        }
      });
  }

  getDeltaData() {
    const { data, focusedData } = this.props;
    const { NAME: initialStage } = _.head(data);

    return data.reduce((diffs, d, idx) => {
      const selectedStacks = d.VALUES.filter(v => {
        // no need to filter out any stack items if there is no focused data
        if (_.isEmpty(focusedData)) {
          return true;
        }
        // only return the stack items that are matches for the focused data
        return !_.isNil(focusedData.find(fd => fd.stackName === v.stackName));
      });
      const v = StackChartUtils.getStackTotals(d.VALUES).positive;
      const selectionValue = StackChartUtils.getStackTotals(selectedStacks)
        .positive;
      if (idx > 0 && idx < data.length) {
        const initialValue = _.head(diffs)?.value;
        const p = diffs[idx - 1].value;
        const prevSelectionValue = diffs[idx - 1].selectionValue;
        diffs.push({
          value: v,
          delta: v / p,
          previousValue: p,
          selectionValue,
          selectionDelta: selectionValue / prevSelectionValue,
          previousSelectionValue: prevSelectionValue,
          previousStage: d.NAME,
          initialValue,
          initialStage,
          totalDelta: v / initialValue,
        });
      } else if (idx === 0) {
        diffs.push({
          value: v,
          delta: 0,
          previousValue: 0,
          selectionValue,
          selectionDelta: 0,
          previousSelectionValue: 0,
          previousStage: '',
        });
      }
      return diffs;
    }, []);
  }

  renderDeltaLabels() {
    const { data, groupScale, focusedData } = this.props;
    const labelsOutside = _.head(data)?.VALUES?.length > 1;
    const deltas = this.getDeltaData();

    return deltas
      .filter((d, idx) => idx > 0)
      .map((d, idx) => {
        const yPos =
          2 * (groupScale.bandwidth() * (idx + 1)) -
          groupScale.bandwidth() / 2 +
          5;
        let textOffset = 0;
        if (this.props.isMobile) {
          // we adjust the font size for mobile, don't push the label down as far
          textOffset = -2;
        }
        const { isAvgdaysinstage, isMobile } = this.props;
        const overallPercent = DATA_FORMATTER.WHOLE_PERCENT.formatSmall(
          d.delta,
          this.props?.i18nPrefs,
        );
        const selectionPercent = DATA_FORMATTER.WHOLE_PERCENT.formatSmall(
          d.selectionDelta,
          this.props?.i18nPrefs,
        );
        const totalDeltaPercent = `(${DATA_FORMATTER.WHOLE_PERCENT.formatSmall(
          d.totalDelta,
          this.props?.i18nPrefs,
        )})`;

        // we are in selection mode if there is focused data and the number of selections are not equal to the total stack item of the first stack
        const isSelectionMode =
          !_.isEmpty(focusedData) &&
          focusedData.length !== _.head(data)?.VALUES?.length;
        const labelToMeasure = !isSelectionMode
          ? overallPercent
          : `${selectionPercent} ${messages.funnel.vs} ${overallPercent} ${messages.funnel.overall}`;

        let labelWidth = Util.calcTextWidth(
          labelToMeasure,
          `${isMobile ? '12px' : '14px'} ${DefaultFontName}`,
        );
        if (isSelectionMode) {
          labelWidth += 12; // padding around `vs`
        }
        const stackOffset = getStackOffset(isAvgdaysinstage, isMobile);

        let w = this.props.width - stackOffset;
        if (this.props.showLabels && !isMobile) {
          w =
            this.props.width -
            (labelsOutside ? OUTSIDE_LABEL_WIDTH : 0) -
            stackOffset;
        }

        const xPos = this.props.isCenter
          ? w / 2 - labelWidth / 2 + stackOffset
          : stackOffset + 16;

        const {
          isConversionfrominitialstage: showInitialConversionDeltas,
        } = this.props;
        return (
          <g
            className={this.deltaLabelClassName}
            transform={`translate(${xPos}, ${yPos})`}
            key={`funnel-delta-${idx}`}
          >
            {d.delta > 1 && <UpArrow />}
            {d.delta <= 1 && <DownArrow />}
            {isSelectionMode && (
              <text
                className={'funnel-delta'}
                transform={`translate(4, ${textOffset})`}
              >
                <tspan className={'selection-percent'}>
                  {selectionPercent}
                </tspan>
                <tspan className={'light'} dx={6}>
                  {' '}
                  {messages.funnel.vs}{' '}
                </tspan>
                <tspan className={'overall-percent'} dx={6}>
                  {overallPercent} {messages.funnel.overall}
                </tspan>
                {showInitialConversionDeltas && (
                  <tspan className={'delta-percentage'} dx={6}>
                    {totalDeltaPercent}
                  </tspan>
                )}
              </text>
            )}
            {!isSelectionMode && (
              <text
                className={'funnel-delta'}
                transform={`translate(4, ${textOffset})`}
              >
                <tspan className={'overall-percent'}>{overallPercent}</tspan>
                {showInitialConversionDeltas && (
                  <tspan className={'delta-percentage'} dx={6}>
                    {totalDeltaPercent}
                  </tspan>
                )}
              </text>
            )}
          </g>
        );
      });
  }

  renderAsideData() {
    const { data, groupScale, isAvgdaysinstage } = this.props;
    const size = groupScale.bandwidth();
    return data.map((d, idx) => {
      if (_.isNil(d.AVG_DAYS_IN_STAGE) || d.AVG_DAYS_IN_STAGE === '-') {
        return null;
      }
      const yPos = size * 2 * idx;
      const label = parseInt(d.AVG_DAYS_IN_STAGE);
      const xPos = 0;
      const margin = 15;
      const height = clamp(size - margin * 2, 18, 32); // max:32 min:18
      const textOffset = size / 2;
      const width = isAvgdaysinstage ? WIDTH_ADJUST : 0;
      const cornerRadius = 2;
      const roundedBox = roundedRect(
        0,
        0,
        width,
        height,
        cornerRadius,
        1,
        1,
        1,
        1,
      );
      return (
        <g
          transform={`translate(${xPos}, ${yPos})`}
          key={`funnel-delta-${idx}`}
        >
          <AsidePath roundedBox={roundedBox} size={size} height={height} />
          <text
            className={'funnel-delta'}
            style={{ pointerEvents: 'none' }}
            alignmentBaseline='middle'
            textAnchor='middle'
            transform={`translate(${width / 2 - 1}, ${textOffset + 2})`}
          >
            {label}d
          </text>
        </g>
      );
    });
  }

  render() {
    const {
      colorPalette,
      customFormatToggles,
      data,
      enableReportLink,
      focusedData,
      getStack,
      getStackColor,
      getX,
      getY,
      groupScale,
      height,
      isCenter,
      isHorizontal,
      isMobile,
      onHover,
      showLabels,
      vizId,
      width,
      xScale,
      yScale,
      isAvgdaysinstage,
      className,
      focus,
      customFormatProps,
    } = this.props;
    const offset = getStackOffset(isAvgdaysinstage, isMobile);
    const stackCount = _.head(data)?.VALUES?.length ?? 0;

    return (
      <g className={'funnel captureMouseEvents'} transform={'translate(0, 0)'}>
        <Stacks
          className={className}
          colorPalette={colorPalette}
          customFormatToggles={customFormatToggles}
          data={data}
          enableReportLink={enableReportLink}
          focus={focus}
          focusedData={focusedData}
          getStack={getStack}
          getStackColor={getStackColor}
          getX={getX}
          getY={getY}
          groupScale={groupScale}
          height={height}
          isCenter={isCenter}
          isHorizontal={isHorizontal}
          isMobile={isMobile}
          onHover={onHover ?? _.noop}
          ref={el => {
            this.stacks = el;
          }}
          showLabels={showLabels}
          stackXOffset={offset}
          totalsLabelPosition={
            stackCount > 1
              ? TOTALS_LABEL_POSITION.OUTSIDE
              : TOTALS_LABEL_POSITION.INSIDE
          }
          vizId={vizId}
          width={width}
          xScale={xScale}
          yScale={yScale}
          handleStackEvents={(_data, x, y) =>
            this.handleStackHover(_data, x, y)
          }
          handleFullStackEvents={(_data, x, y) =>
            this.handleFullStackHover(_data, x, y)
          }
          customFormatProps={customFormatProps}
        />
        <g className={'funnel-aside-group'}>
          {isAvgdaysinstage && this.renderAsideData()}
        </g>
        <g
          ref={el => {
            this.deltasContainer = el;
          }}
          className={this.deltaContainerClassName}
        >
          {this.renderDeltaLabels()}
        </g>
      </g>
    );
  }
}

const UpArrow = () => {
  return (
    <g
      className={'funnel-arrow up'}
      transform={'translate(-4, -10) rotate(180 3 5)'}
    >
      <path d='M4,6 L6,6 L3,10 L0,6 L2,6 L2,0 L4,0 L4,6 Z' />
    </g>
  );
};

const DownArrow = () => {
  return (
    <g className={'funnel-arrow down'} transform={'translate(-4, -10)'}>
      <path d='M4,6 L6,6 L3,10 L0,6 L2,6 L2,0 L4,0 L4,6 Z' />
    </g>
  );
};
export default connect(state => {
  const { i18nPrefs = {} } = state?.account?.currentUser;
  return {
    i18nPrefs,
  };
}, _.constant({}))(UnconnectedFunnel);

function roundedRect(x, y, w, h, r, tl, tr, bl, br) {
  let retval;
  retval = `M${x + r},${y}`;
  retval += `h${w - 2 * r}`;
  if (tr) {
    retval += `a${r},${r} 0 0 1 ${r},${r}`;
  } else {
    retval += `h${r}`;
    retval += `v${r}`;
  }
  retval += `v${h - 2 * r}`;
  if (br) {
    retval += `a${r},${r} 0 0 1 ${-r},${r}`;
  } else {
    retval += `v${r}`;
    retval += `h${-r}`;
  }
  retval += `h${2 * r - w}`;
  if (bl) {
    retval += `a${r},${r} 0 0 1 ${-r},${-r}`;
  } else {
    retval += `h${-r}`;
    retval += `v${-r}`;
  }
  retval += `v${2 * r - h}`;
  if (tl) {
    retval += `a${r},${r} 0 0 1 ${r},${-r}`;
  } else {
    retval += `v${-r}`;
    retval += `h${r}`;
  }
  retval += 'z';
  return retval;
}
