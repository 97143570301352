import { useSelector, useDispatch } from 'react-redux';
import { Copyright } from '../../UIUtilityWidgets';
import { getFrontendUrl, PRIVACY_POLICY_LINK } from '../../../Constants';
import { messages } from '../../../../i18n';
import Util from '../../../Util';
import { URLs } from '../../../Urls';
import { ButtonContainer, PrimaryButton } from '../../../../ui';
import { CopyrightText, StyledModalPortal } from './logout-dialog.styles';
import classnames from 'classnames';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { init } from '../../../../common/redux';
import { get } from 'lodash';
import { client } from '../../../ApolloClient';

const returnToUrlParamKey = 'return_to_url';

export const LogoutDialog = () => {
  const {
    returnToUrl = get(
      URLs.getQueryParams(),
      returnToUrlParamKey,
      getFrontendUrl(),
    ),
    isDashletMode,
    idmLogoutUrl,
    appUrl,
    isLoggedIn,
  } = useSelector(
    ({
      dashlet: { isDashletMode },
      login: { idmLogoutUrl, returnToUrl, isLoggedIn },
      main: { appUrl },
    }: any) => ({
      returnToUrl,
      isDashletMode,
      idmLogoutUrl,
      appUrl,
      isLoggedIn,
    }),
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoImgSrc = Util.assetUrl({
    appUrl,
    path: 'discover_logo.svg',
  });

  const returnBack = useCallback(() => {
    const returnUrl = new URL(decodeURIComponent(returnToUrl));
    const frontendUrl = new URL(getFrontendUrl());
    if (frontendUrl.hostname === returnUrl.hostname) {
      navigate(returnUrl.pathname);

      // trigger auth
      init();
    }
  }, [navigate, returnToUrl]);

  // redirects to IDM for session logout
  useEffect(() => {
    try {
      const idmLogoutUrlObj = new URL(idmLogoutUrl);
      const redirectUriObj = new URL(appUrl);
      redirectUriObj.pathname = 'logout';
      redirectUriObj.searchParams.append(returnToUrlParamKey, returnToUrl);
      const redirectUri = redirectUriObj.toString();
      idmLogoutUrlObj.searchParams.append('redirect_uri', redirectUri);
      const fixedUrl = idmLogoutUrlObj.toString();
      window.location.assign(fixedUrl);

      // Clear state, even though browser redirects
      dispatch({ type: 'RESET_STATE' });
      client.clearStore();
      localStorage.clear();
    } catch (err) {
      console.log('Failed to parse idmLogoutUrl', err);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoggedIn && !isDashletMode) {
    // wait while we redirect
    return;
  }

  return (
    <>
      <StyledModalPortal
        className={classnames({
          dashlet: isDashletMode,
        })}
        contentClassName={'logout-dialog'}
        title={''}
        buttonGroup={
          <ButtonContainer
            style={{
              marginBottom: '0.8rem',
              alignSelf: 'flex-end',
              marginRight: '1rem',
            }}
          >
            <PrimaryButton className={'logout-button'} onClick={returnBack}>
              {messages.loginDialog.returnToLogin}
            </PrimaryButton>
          </ButtonContainer>
        }
        zIndex={1051}
      >
        <div className={'scrollable'}>
          <div className='login-body'>
            <div className='login-logo'>
              <img src={logoImgSrc} className='invertable center-block' />
            </div>
            <div className='login-header text-center'>
              {messages.logoutDialog.loggedOut}
            </div>
          </div>
        </div>
      </StyledModalPortal>
      {!isDashletMode && (
        <CopyrightText className={'tosText'}>
          <Copyright />
          <span className='vertical-bar' />
          <a href={PRIVACY_POLICY_LINK} target={'_blank'} rel='noreferrer'>
            {messages.privacyPolicy}
          </a>
        </CopyrightText>
      )}
    </>
  );
};
