import React from 'react';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useHasValueChanged } from '../../../common/utilities/state-helpers.hook';
import { constant, isFunction } from 'lodash';
import { PopperProps } from '@mui/material/Popper/Popper';
import { PopperPlacementType } from '@mui/material/Popper/BasePopper.types';
import Popper from '@mui/material/Popper';
import * as uuid from 'uuid';
import { usePortalRef } from '../../../common/utilities/portal';

export const usePopperProps = ({ onToggle }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperId, setPopperId] = useState('');
  const [renderedPlacement, setRenderedPlacement] = useState<
    PopperPlacementType
  >();

  useEffect(() => setPopperId(`popper-${uuid.v4()}`), []);

  const isOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const hasOpenChanged = useHasValueChanged({
    value: isOpen,
    defaultValue: false,
  });
  useEffect(() => {
    if (hasOpenChanged) {
      isFunction(onToggle) && onToggle(isOpen);

      if (!isOpen) {
        setRenderedPlacement(constant(undefined));
      }
    }
  }, [hasOpenChanged, isOpen, onToggle]);

  const handleToggle = useCallback(
    event => {
      event.preventDefault();
      const _anchorEl =
        event?.currentTarget?.closest(`#${popperId}`) ?? event?.currentTarget;
      setAnchorEl(anchorEl ? null : _anchorEl);
    },
    [anchorEl, popperId],
  );

  const handleClickAway = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const PopperComponent = useMemo(() => {
    return (props: PopperProps) => (
      <Popper
        id={popperId}
        popperOptions={{
          onFirstUpdate: state => setRenderedPlacement(() => state?.placement),
        }}
        {...props}
      />
    );
  }, [popperId]);

  return {
    id: popperId,
    PopperComponent,
    anchorEl,
    handleToggle,
    handleClickAway,
    isOpen,
    renderedPlacement,
  };
};

export const useDashletClickAway = ({ anchorEl, handleClickAway }) => {
  const portalRef = usePortalRef();
  const container = portalRef.getPortalContainer();

  const handleAwayEvent = useCallback(
    (event: any) => {
      if (!(event?.target && anchorEl?.parentElement)) {
        return;
      }

      if (
        !anchorEl.parentElement.contains(event?.target) &&
        isFunction(handleClickAway)
      ) {
        handleClickAway(event);
      }
    },
    [anchorEl, handleClickAway],
  );

  useEffect(() => {
    if (isFunction(container?.addEventListener)) {
      container.addEventListener('click', handleAwayEvent);
      container.addEventListener('touchend', handleAwayEvent);
    }

    return () => {
      if (isFunction(container?.removeEventListener)) {
        container.removeEventListener('click', handleAwayEvent);
        container.removeEventListener('touchend', handleAwayEvent);
      }
    };
  }, [container, handleAwayEvent]);
};
