import { branch, compose, pure, renderComponent } from 'react-recompose';
import { connect } from 'react-redux';
import Discover from '../../common/redux/actions/DiscoverActions';
import { DATA_FORMATTER } from '../../common/Constants';
import { withRouter } from '../../common/utilities/with-router';
import _ from 'lodash';
import { messages } from '../../i18n';
import { useDiscoverThemeColors } from '../../common/emotion';

const baseProps = (
  Comp,
  currentValueName = 'currentValue',
  previousValueName = 'previousValue',
) => props => {
  const { SelectRowPropColor, ValLineStroke } = useDiscoverThemeColors();
  const { monitorEvent, openVisualization } = props;
  const payload = JSON.parse(monitorEvent.payload);
  const up = payload[previousValueName] - payload[currentValueName] < 0;
  const pct = `${Math.round(Math.abs(payload.percentChange) * 100)}%`;
  const date = monitorEvent.endTimestamp;

  const getAttribute = monitorPayload => {
    let attrName = null;
    let attrValue = null;

    if (monitorPayload?.attributeName) {
      attrName = _.upperFirst(monitorPayload.attributeName);
      attrValue = monitorPayload.attributeValue;
    } else if (monitorPayload?.group) {
      const attr = _.head(_.toPairs(monitorPayload?.group));
      attrName = _.upperFirst(attr[0]);
      attrValue = attr[1];
    }

    return {
      attrName,
      attrValue,
    };
  };

  let { attrName, attrValue } = getAttribute(payload);

  // ignore "ALL" segment
  if (attrName === 'ALL') {
    attrName = null;
    attrValue = null;
  }

  let period = messages.unknown.toLowerCase();

  switch (payload.previousPeriod) {
    case 'WEEK':
      period = messages.insightFeed.weekOverWeek;
      break;
    case 'MONTH':
      period = messages.insightFeed.monthOverMonth;
      break;
  }

  const makeFinite = num => (_.isNumber(num) && _.isFinite(num) ? num : 0);
  payload.currentValue = makeFinite(payload.currentValue);
  payload.previousValue = makeFinite(payload.previousValue);

  const barData = [
    {
      name: 'previous',
      value: payload.previousValue,
      className: 'previous',
      color: SelectRowPropColor,
      formatters: [DATA_FORMATTER.WHOLE_NUMBER],
    },
    {
      name: 'current',
      value: makeFinite(payload.currentValue),
      className: 'current',
      color: ValLineStroke,
      formatters: [DATA_FORMATTER.WHOLE_NUMBER],
    },
  ];

  const name = _.get(
    monitorEvent,
    'monitor.name',
    messages.insightFeed.unknownMonitor,
  );

  if (!name) {
    return null;
  }
  return (
    <Comp
      {...props}
      {...{
        payload,
        up,
        period,
        pct,
        date,
        attrName,
        attrValue,
        name,
        barData,
        openVisualization,
      }}
    />
  );
};

const CommonComponentHoc = compose(
  pure,
  withRouter,
  branch(
    ({ monitorEvent }) => !monitorEvent.payload,
    renderComponent(props => {
      console.error(
        'Unable to display custom feed item as the payload is empty',
      );
      return (
        <div className='error'>
          {messages.formatString(
            messages.insightFeed.monitorEventMissingPayloadError,
            props.monitorEvent.id,
          )}
        </div>
      );
    }),
  ),
  baseProps,
  connect(
    state => {
      const { i18nPrefs = {} } = state?.account?.currentUser;
      return {
        i18nPrefs,
      };
    },
    dispatch => {
      return {
        openVisualization: discovery => {
          dispatch(Discover.openVisualization(discovery));
        },
      };
    },
  ),
);

export { CommonComponentHoc, baseProps };
