import { useMemo, FunctionComponent } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export type LegacyHistoryHook = {
  push(location: string, state?: any): void;
};

// providing legacy code react-router v5 useHistory API
export const useHistory = (): LegacyHistoryHook => {
  const navigate = useNavigate();
  const location = useLocation();

  return useMemo(
    () => ({
      push: (location, state) =>
        navigate(location, state ? { state } : undefined),
      location,
    }),
    [navigate, location],
  );
};

export const useWithRouterProps = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const history = useHistory();

  return {
    location,
    navigate,
    params,
    history,
  };
};

export function withRouter(Component: FunctionComponent) {
  function ComponentWithRouterProp(props) {
    const { location, navigate, params, history } = useWithRouterProps();

    return (
      <Component
        {...props}
        location={location}
        navigate={navigate}
        params={params}
        history={history}
      />
    );
  }

  return ComponentWithRouterProp;
}
