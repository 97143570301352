import { Navigate } from 'react-router-dom';
import _ from 'lodash';
import InListFilter from '../discovery/filter/exports/InListFilter';
import { graphql } from '@apollo/client/react/hoc';
import VizQueries from '../common/graphql/VizGql';
import { withProps, compose } from 'react-recompose';
import { Viz as VizUtils } from '../discovery/VizUtil';
import { CorbotoLoading } from '../common/loaders';
import { getRelativeDateFilterForMonitorEvent } from '../monitors';
import { VizRedirect } from '../discovery/viz-redirect';
import { ROUTER_DIRS } from '../common';

const GamRedirect = ({ supportedTypes }) => {
  const redirectFunc = ({
    monitorEvent,
    openVisualization,
    linkedContent,
    visualization,
    loading,
  }) => {
    if (loading) {
      return <CorbotoLoading />;
    }

    let payload;
    try {
      payload = JSON.parse(monitorEvent.payload);
    } catch (e) {
      console.error('Error parsing monitor event', e);
      return <Navigate to={ROUTER_DIRS.ROOT} />;
    }

    if (visualization) {
      let filters = {};
      if (!_.isEmpty(payload.group)) {
        for (const [attributeProp, attributeValue] of Object.entries(
          payload.group,
        )) {
          const segmentField = visualization
            ? _.find(
                visualization.dataset.attributes,
                a => _.lowerCase(a.name) === _.lowerCase(attributeProp),
              )
            : null;
          if (!_.isNil(segmentField)) {
            const segmentFilter = InListFilter(segmentField, attributeValue);
            filters = {
              [segmentField.name]: segmentFilter,
            };
          }
        }
      }

      let relativeFilter;
      try {
        relativeFilter = getRelativeDateFilterForMonitorEvent(
          monitorEvent,
          visualization,
        );
      } catch (e) {
        console.log('Error getting relative Date filter for event', e);
      }
      Object.assign(filters, relativeFilter);

      openVisualization(linkedContent);
      return (
        <VizRedirect
          vizId={visualization.id}
          state={{
            filters,
            monitorEventId: monitorEvent.id,

            slicerSelections: VizUtils.getSlicerSelections(linkedContent),
            slicers: VizUtils.getSlicers(linkedContent),
          }}
        />
      );
    }
    return <Navigate to={ROUTER_DIRS.ROOT} />;
  };

  const vizQuery = graphql(VizQueries.GetVisualization, {
    options: props => ({
      variables: { id: props.linkedContent.id },
    }),
    props: store => {
      const { visualization } = store?.data ?? {};
      if (store.data && !store.data.loading && visualization) {
        const { dataset } =
          _.find(store.ownProps.visualizations, {
            id: store.data.visualization.id,
          }) ?? {};
        const _dataset = visualization.dataset ?? dataset;
        return {
          visualization: VizUtils.rehydrateViz({
            ...visualization,
            dataset: _dataset,
          }),
          loading: false,
        };
      } else {
        return { loading: true };
      }
    },
  });

  const func = compose(
    withProps(({ monitorEvent, visualizations }) => {
      let targetReport;
      try {
        ({
          labels: { targetReport },
        } = JSON.parse(monitorEvent.monitor.config));
      } catch (err) {
        targetReport = null;
      }
      const linkedContent =
        _.find(visualizations, { id: targetReport }) ?? null;
      return { linkedContent };
    }),
    vizQuery,
  )(redirectFunc);

  func.supports = monitorEvent =>
    supportedTypes.includes(monitorEvent.eventType);
  return func;
};

export default GamRedirect;
