import { Component } from 'react';
import * as ReactDOM from 'react-dom';
import _ from 'lodash';
import { compose, pure, withProps } from 'react-recompose';
import fastdom from 'fastdom';
import { generateWrappedLabels } from './AxisUtils';
import { withResizeDetector } from 'react-resize-detector';
import { axisTop, axisBottom, drag, select, axisLeft, axisRight } from 'd3';

class Axis extends Component {
  componentDidMount() {
    this.renderAxis();
    this.attachListeners();
  }

  // D3 scale change detection. See https://stackoverflow.com/questions/43778840/how-do-i-determine-if-two-d3-scales-are-equivalent-or-if-a-d3-scale-has-changed
  getScaleDiffSignature(scale) {
    return (
      (scale?.domain()?.toString() ?? '') + (scale?.range()?.toString() ?? '')
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.scrolling && !this.listenersAttached) {
      this.attachListeners();
    }

    const hasScaleChanged = !_.isEqual(
      this.getScaleDiffSignature(this.props.scale),
      this.getScaleDiffSignature(prevProps.scale),
    );

    const testProps = [
      'orient',
      'ticks',
      'querySort',
      'tickSizeInner',
      'tickSizeOuter',
      'tickValues',
      'tickTextWrap',
      'textOrientation',
      'wrapTextWidth',
      'tickFontSize',
      'wrap',
      'measuredWidth',
      'measuredHeight',
      'showAxisBaseline',
      'hideAxisTickLabels',
      'queryId',
      'scale',
    ];
    if (
      !_.isEqual(
        _.pick(this.props, testProps),
        _.pick(prevProps, testProps) || hasScaleChanged,
      )
    ) {
      this.removeListeners();
      this.renderAxis();
      this.attachListeners();
    }
  }

  getChartWidth() {
    if (_.isNumber(this.props.chartWidth) && !isNaN(this.props.chartWidth)) {
      this.chartWidth = this.props.chartWidth;
    }
    return this.chartWidth ?? 0;
  }

  removeListeners() {
    this.listenersAttached = false;
    const handle = select(ReactDOM.findDOMNode(this)).select('.scrollHandle');

    handle.on('mousedown.drag', null);
  }

  attachListeners() {
    if (this.props.scrolling) {
      this.listenersAttached = true;
      const onScroll = (scrollPctLeft, scrollPctTop) => {
        this.props.onScroll(scrollPctLeft, scrollPctTop);
      };

      const handle = select(ReactDOM.findDOMNode(this)).select('.scrollHandle');
      const renderedChartWidth = this.getChartWidth();
      const renderedChartHeight = this.props.chartHeight;
      const isOrient = options => this.isOrient(options);
      handle.call(
        drag()
          .on('start', () => {
            handle.classed('dragging', true);
          })
          .on('drag', event => {
            handle.attr('transform', function() {
              /*
            Note: For some reason, Chrome and Safari have different API to svg objects. transform.baseVal is an array
            in Chrome but an object with a getItem method in safari
          */
              const maxLeftVal =
                renderedChartWidth -
                handle.node().getBoundingClientRect().width -
                2;
              const maxTopVal =
                renderedChartHeight -
                handle.node().getBoundingClientRect().height -
                2;
              const baseVal = _.isArray(this.transform.baseVal)
                ? this.transform.baseVal[0]
                : this.transform.baseVal.getItem(0);
              const { e: currX, f: currY } = baseVal.matrix;
              let translate;

              if (isOrient(['bottom', 'top', 'grid'])) {
                const left = Math.max(
                  0,
                  Math.min(maxLeftVal, currX + event.dx),
                );
                const scrollPctLeft = left / maxLeftVal;
                translate = `translate(${left}, 0)`;
                onScroll(scrollPctLeft, 0);
              } else if (isOrient(['left', 'right', 'grid-horizontal'])) {
                const top = Math.max(0, Math.min(maxTopVal, currY + event.dy));
                const scrollPctTop = top / maxTopVal;
                translate = `translate(0, ${top})`;
                onScroll(0, scrollPctTop);
              }
              return translate;
            });
          })
          .on('end', () => {
            handle.classed('dragging', false);
          }),
      );
    }
  }

  isOrient(options) {
    if (_.isString(options)) {
      return this.props.orient === options;
    } else if (_.isArray(options)) {
      return _.includes(options, this.props.orient);
    }
  }

  wrap = (text, width) => {
    const wrappedLabels = generateWrappedLabels(text, width);
    text.each((l, i) => {
      const thisText = select(text.nodes()[i]);
      const lineHeight = 1.1; // ems
      const y = thisText.attr('y');
      const dy = parseFloat(thisText.attr('dy'));
      fastdom.mutate(() => {
        const thisLabel = wrappedLabels[i];
        if (thisLabel.length === 1) {
          thisText
            .text(null)
            .append('tspan')
            .attr('x', 0)
            .attr('y', y)
            .attr('dy', `${dy}em`)
            .text(thisLabel[0]);
        } else {
          thisText
            .text(null)
            .append('tspan')
            .attr('x', 0)
            .attr('y', y)
            .attr('dy', `${dy}em`)
            .text(thisLabel[0])
            .append('tspan')
            .attr('x', 0)
            .attr('y', y)
            .attr('dy', `${lineHeight + dy}em`)
            .text(thisLabel[1]);
        }
      });
    });
  };

  renderAxis() {
    let axis = null;
    let isXaxis = false;
    const {
      orient,
      scale,
      ticks,
      tickSizeInner,
      tickSizeOuter,
      tickFormat,
      tickValues,
      tickTextWrap,
      textOrientation,
      wrapTextWidth,
      tickFontSize,
      showAxisBaseline,
      hideAxisTickLabels,
    } = this.props;

    switch (orient) {
      case 'grid-horizontal':
        axis = axisBottom();
        break;
      case 'bottom':
        axis = axisBottom();
        isXaxis = true;
        break;
      case 'grid':
      case 'left':
        axis = axisLeft();
        break;
      case 'top':
        axis = axisTop();
        isXaxis = true;
        break;
      case 'right':
        axis = axisRight();
        break;
    }

    axis
      .scale(scale)
      .tickSizeInner(tickSizeInner)
      .tickSizeOuter(tickSizeOuter)
      .tickFormat(tickFormat);

    if (tickValues) {
      axis.tickValues(tickValues);
    } else {
      axis.ticks(ticks);
    }

    const ax = select(this.axis).call(axis);

    if (tickTextWrap && _.isFunction(tickTextWrap)) {
      ax.selectAll('.tick text').call(tickTextWrap);
    } else if (wrapTextWidth > 0 && isXaxis) {
      // wipe out any previously set vertical text attributes
      ax.selectAll('.tick text')
        .attr('transform', null)
        .attr('x', null)
        .attr('y', 8)
        .style('text-anchor', null);

      // if we are showing the baseline axis of the x-axis, then they are ordinal values, not metric so we should try to wrap them
      if (showAxisBaseline) {
        // wrap the text
        ax.selectAll('.tick text').call(this.wrap, wrapTextWidth);
      }
    } else if (textOrientation !== LABEL_ORIENTATION.HORIZONTAL && isXaxis) {
      const angle = textOrientation === LABEL_ORIENTATION.VERTICAL ? 270 : 325;
      const xOffset = textOrientation === LABEL_ORIENTATION.VERTICAL ? -8 : -6;
      const yOffset = textOrientation === LABEL_ORIENTATION.VERTICAL ? -6 : 6;
      ax.selectAll('.tick text')
        .attr('transform', `rotate(${angle})`)
        .attr('x', _.isNaN(xOffset) ? 0 : xOffset)
        .attr('y', yOffset)
        .style('text-anchor', 'end');

      // find the width of each vertical label
      let w = 1;
      if (_.isFunction(scale.step)) {
        w = scale.step();
      } else {
        // Continuous scales don't have a step function. We need to determine the width of each "point"
        w = scale.range()[1] / (ticks + 1);
      }
      let fontSize = tickFontSize;
      // find how many times bigger the font size is than the label width
      if (textOrientation === LABEL_ORIENTATION.ANGLED) {
        // if the text is angled, we need to account for more space for the labels
        fontSize = Math.sqrt(2 * (tickFontSize * tickFontSize));
      }
      const modFactor = Math.floor(fontSize / w);

      if (modFactor > 0) {
        // we need to drop some labels so they don't overlap
        ax.selectAll('.tick text').attr('class', (d, i) => {
          if (i % (modFactor + 1) !== 0) {
            return 'hidden';
          } else {
            return '';
          }
        });
      }
    } else if (isXaxis) {
      // this wipes out any previously set vertical text attributes
      ax.selectAll('.tick text')
        .attr('transform', null)
        .attr('x', null)
        .attr('y', 8)
        .style('text-anchor', null);
    }
    if (!showAxisBaseline) {
      ax.selectAll('.domain').classed('hidden', true);
    }
    if (hideAxisTickLabels) {
      ax.selectAll('.tick').classed('hidden', true);
    }
    if (orient === 'grid' || orient === 'grid-horizontal') {
      // Add a property to the zero-line so it can be displayed differently
      ax.selectAll('.tick').classed('zeroLine', d => {
        return d === 0;
      });
    }
  }

  render() {
    let classNames = '';
    switch (this.props.orient) {
      case 'top':
      case 'bottom':
        classNames += 'x axis xAxisTickFont xAxisBottom timeAxis';
        if (this.props.mutedLine) {
          // By default the X Axis shows a strong line at top. The "mutedLine" property adds a class so it can be displayed differently (regular weight)
          classNames += ' mutedLine';
        }
        break;
      case 'right':
      case 'left':
        classNames += 'yaxis axis yAxisTickFont';
        if (this.props.mutedLine) {
          classNames += ' mutedLine';
        }
        break;
      case 'grid-horizontal':
      case 'grid':
        classNames += 'yAxisGrid';
        break;
    }
    const styles = this.props.scrolling
      ? {
          clipPath: `url(#clip-${this.props.orient})`,
        }
      : {};

    const scrollLeft = _.isFinite(this.props.scrollLeft)
      ? this.props.scrollLeft
      : 0;
    const scrollTop = _.isFinite(this.props.scrollTop)
      ? this.props.scrollTop
      : 0;
    let xClipOffset = 0;
    let rotatedXClipOffset = 0;
    if (
      this.props.scrolling &&
      this.props.transform &&
      this.isOrient(['bottom', 'top', 'grid'])
    ) {
      // parse the x value off the transform. it is the width of the offset in the x direction. We do not want to not clip that portion of the axis labels
      const rx = /translate\((\d+),.*\)/;
      const match = this.props.transform.match(rx);
      if (match) {
        xClipOffset = parseInt(match[1]);
        const radians = (35 * Math.PI) / 180;
        rotatedXClipOffset = (1 / Math.sin(radians)) * xClipOffset;
      }
    }
    const clipWidth = this.isOrient(['bottom', 'top', 'grid'])
      ? this.getChartWidth()
      : this.getChartWidth() + (this.props.width ?? 0);
    return (
      <g
        className={classNames}
        transform={this.props.transform}
        onMouseOver={this.props.onMouseOver}
      >
        <g style={{ pointerEvents: 'all' }}>
          {this.isOrient(['top', 'bottom', 'grid']) && (
            <rect
              style={{ visibility: 'hidden', fill: 'transparent' }}
              width={this.props.chartWidth ?? 0}
              height={this.props.height ?? 0}
            />
          )}
          {this.isOrient(['left', 'right', 'grid-horizontal']) && (
            <rect
              style={{ visibility: 'hidden', fill: 'transparent' }}
              width={this.props.width ?? 0}
              height={this.props.chartHeight ?? 0}
              x={this.props.width ? -this.props.width : 0}
            />
          )}
        </g>
        {this.props.children}
        {this.props.scrolling && (
          <clipPath id={`clip-${this.props.orient}`}>
            <rect
              width={clipWidth}
              height={this.props.chartHeight}
              x={this.props.width ? -this.props.width : 0}
            />
            {rotatedXClipOffset > 0 && (
              <rect
                width={rotatedXClipOffset}
                height={this.props.chartHeight}
                x={-rotatedXClipOffset}
                transform={'rotate(325)'}
              />
            )}
          </clipPath>
        )}
        <g style={styles}>
          {this.isOrient(['top', 'bottom', 'grid']) && (
            <g
              className={this.props.scrollable ? 'scrollable' : ''}
              ref={g => {
                this.axis = g;
              }}
              transform={`translate(${_.isNaN(scrollLeft) ? 0 : scrollLeft},0)`}
            />
          )}
          {this.isOrient(['left', 'right', 'grid-horizontal']) && (
            <g
              className={this.props.scrollable ? 'scrollable' : ''}
              ref={g => {
                this.axis = g;
              }}
              transform={`translate(0, ${scrollTop})`}
            />
          )}
        </g>

        {this.props.scrolling && this.isOrient(['bottom', 'top', 'grid']) && (
          <g
            className={'scrollContainer'}
            transform={`translate(0, ${this.props.height +
              (this.props.chartPadding - 11)})`}
          >
            <g>
              <rect
                className='scrollBarFrame'
                strokeWidth='1'
                x='0.5'
                y='0.5'
                width={this.getChartWidth()}
                height='11'
                rx='5.5'
              />
            </g>
            <g className='scrollHandle' transform={this.props.handleTransform}>
              <rect
                ref={rect => (this.scrollHandle = rect)}
                strokeWidth='1'
                x='1.5'
                y='1.5'
                width={this.props.handleWidth || 200}
                height={this.props.handleHeight || 9}
                rx='4.5'
              />
            </g>
          </g>
        )}

        {this.props.scrolling && this.isOrient(['top']) && (
          <g
            className={'scrollContainer'}
            transform={`translate(0, ${this.props.chartPadding})`}
          >
            <g>
              <rect
                className='scrollBarFrame'
                strokeWidth='1'
                x='0.5'
                y='0.5'
                width={this.getChartWidth()}
                height='11'
                rx='5.5'
              />
            </g>
            <g className='scrollHandle' transform='translate(0,0)'>
              <rect
                ref={rect => (this.scrollHandle = rect)}
                strokeWidth='1'
                x='1.5'
                y='1.5'
                width={200}
                height='9'
                rx='4.5'
              />
            </g>
          </g>
        )}

        {this.props.scrolling &&
          this.isOrient(['left', 'right', 'grid-horizontal']) && (
            <g
              className={'scrollContainer'}
              transform={`translate(${this.getChartWidth() +
                (this.props.chartPadding - 11)}, 0)`}
            >
              <g>
                <rect
                  className='scrollBarFrame'
                  strokeWidth='1'
                  x='0.5'
                  y='0.5'
                  height={this.props.chartHeight}
                  width='11'
                  ry='5.5'
                />
              </g>
              <g className='scrollHandle' transform='translate(0,0)'>
                <rect
                  ref={rect => (this.scrollHandle = rect)}
                  strokeWidth='1'
                  x='1.5'
                  y='1.5'
                  width='9'
                  height={100}
                  ry='4.5'
                />
              </g>
            </g>
          )}
      </g>
    );
  }
}

const LABEL_ORIENTATION = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  ANGLED: 'angled',
};
const METRIC_ORIENTATION = {
  VERTICAL: 'vertical', // values are plotted vertically
  HORIZONTAL: 'horizontal', // values are plotted horizontally
};

Axis.defaultProps = {
  tickSizeOuter: 0,
  tickSizeInner: 5,
  tickFormat: null,
  textOrientation: LABEL_ORIENTATION.HORIZONTAL,
  wrapTextWidth: -1,
  tickFontSize: 12,
  chartPadding: 20,
  showAxisBaseline: false,
  hideAxisTickLabels: false,
  onMouseOver: _.noop,
};

export default compose(
  withProps(({ width, height }) => ({ _width: width, _height: height })),
  withResizeDetector,
  pure,
  withProps(
    ({
      _width,
      _height,
      width,
      height,
      chartWidth = 0,
      chartHeight = 0,
      orient,
      scrollPct,
    }) => {
      const scrollHandleWidth = Math.max(
        100,
        (chartWidth / 1.2) * (chartWidth / (width + chartWidth)),
      );
      const scrollHandleHeight = Math.max(
        20,
        (chartHeight / 1.2) * (chartHeight / (height + chartHeight)),
      );
      const isHorizontal = _.includes(['top', 'bottom', 'grid'], orient);
      const isVertical = _.includes(
        ['left', 'right', 'grid-horizontal'],
        orient,
      );
      let handleTransform = 'translate(0, 0)';
      let handleHeight, handleWidth;
      if (isHorizontal) {
        const maxLeftVal = chartWidth - scrollHandleWidth - 2;
        const left = Math.max(
          0,
          _.isFinite(scrollPct * maxLeftVal) ? scrollPct * maxLeftVal : 0,
        );
        handleTransform = `translate(${left}, 0)`;
        handleWidth = scrollHandleWidth;
      } else if (isVertical) {
        const maxTopVal = chartHeight - scrollHandleHeight - 2;
        const top = Math.max(
          0,
          _.isFinite(scrollPct * maxTopVal) ? scrollPct * maxTopVal : 0,
        );
        handleTransform = `translate(0, ${top})`;
        handleHeight = scrollHandleHeight;
      }
      return {
        measuredWidth: width,
        measuredHeight: height,
        width: _width,
        height: _height,
        handleHeight,
        handleWidth,
        handleTransform,
      };
    },
  ),
)(Axis);

export { LABEL_ORIENTATION, METRIC_ORIENTATION };
